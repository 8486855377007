import * as React from 'react';
import Svg, { G, Path, Defs, Mask, ClipPath, Use } from "react-native-svg"


function SvgFila(props) {
  
  return (
      
    <Svg id="f_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 32 29" xmlSpace="preserve" {...props} >
      <style>
        {
          ".filas_fila__f1,.filas_fila__f2{fill-rule:evenodd;clip-rule:evenodd;fill:#1bb5ec}.filas_fila__f2{fill:#364f6b}"
        }
      </style>
      <g id="f__Artboard-Copy-21">
        <g id="f__Group">
          <g id="f-1">
            <path id="f1" fill="none" d="M0 33h32V0H0z" />
            <g id="filas_fila__Group-20" transform="translate(1 1.03)">
              <path
                id="f2"
                className="filas_fila__f1"
                d="M25 11.3c-3 0-5 2.8-5 5.8v1.4c0 .6.4 1 1 1h8c.6 0 1-.5 1-1v-1.4c0-3-2-5.8-5-5.8"
              />
              <path
                id="f4"
                className="filas_fila__f1"
                d="M10 18.5v-1.4c0-3-2-5.8-5-5.8s-5 2.8-5 5.8v1.4c0 .6.4 1 1 1h8c.6.1 1-.4 1-1"
              />
              <path
                id="f6"
                className="filas_fila__f1"
                d="M15 17.5c-3.4 0-6 3-6 6.4v1.8c0 .6.4 1 1 1h10c.6 0 1-.5 1-1v-1.8c0-3.5-2.7-6.4-6-6.4"
              />
              <path
                id="f8"
                className="filas_fila__f2"
                d="M28 17.5h-6v-.4c0-1.8 1.1-3.7 3-3.7s3 1.9 3 3.7v.4zm-3-6.2c-3 0-5 2.8-5 5.8v1.4c0 .6.4 1 1 1h8c.6 0 1-.5 1-1v-1.4c0-3-2-5.8-5-5.8z"
              />
              <path
                id="f10"
                className="filas_fila__f2"
                d="M23 3.1c0-.6.4-1 1-1h2c.6 0 1 .5 1 1v4.1c0 .6-.4 1-1 1h-2c-.6 0-1-.5-1-1V3.1zm1 7.2h2c1.7 0 3-1.4 3-3.1V3.1C29 1.4 27.7 0 26 0h-2c-1.7 0-3 1.4-3 3.1v4.1c0 1.7 1.3 3.1 3 3.1z"
              />
              <path
                id="f12"
                className="filas_fila__f2"
                d="M8 17.5H2v-.4c0-1.8 1.1-3.7 3-3.7s3 1.9 3 3.7v.4zm2 1v-1.4c0-3-2-5.8-5-5.8s-5 2.8-5 5.8v1.4c0 .6.4 1 1 1h8c.6.1 1-.4 1-1z"
              />
              <path
                id="f14"
                className="filas_fila__f2"
                d="M3 3.1c0-.6.4-1 1-1h2c.6 0 1 .5 1 1v4.1c0 .6-.4 1-1 1H4c-.6 0-1-.5-1-1V3.1zm1 7.2h2c1.7 0 3-1.4 3-3.1V3.1C9 1.4 7.7 0 6 0H4C2.3 0 1 1.4 1 3.1v4.1c0 1.7 1.3 3.1 3 3.1z"
              />
              <path
                id="f16"
                className="filas_fila__f2"
                d="M19 24.7h-8v-.8c0-2.3 1.7-4.4 4-4.4s4 2 4 4.4v.8zm-4-7.2c-3.4 0-6 3-6 6.4v1.8c0 .6.4 1 1 1h10c.6 0 1-.5 1-1v-1.8c0-3.5-2.7-6.4-6-6.4z"
              />
              <path
                id="f18"
                className="filas_fila__f2"
                d="M12 6.2c0-1.1.9-2.1 2-2.1h2c1.1 0 2 .9 2 2.1v5.2c0 1.1-.9 2.1-2 2.1h-2c-1.1 0-2-.9-2-2.1V6.2zm2 9.3h2c2.2 0 4-1.8 4-4.1V6.2c0-2.3-1.8-4.1-4-4.1h-2c-2.2 0-4 1.8-4 4.1v5.2c0 2.2 1.8 4.1 4 4.1z"
              />
            </g>
          </g>
        </g>
      </g>
    </Svg>
    
  );
}


function SvgRelojArena(props) {
  
  return (
      
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <Path fill="none" d="M0 33h32V0H0z" />
      <G fillRule="evenodd" clipRule="evenodd">
        <Path
          d="M6.4 23.73c-.9 2-1.4 4.3-1.4 6.6v.6c0 .6.4 1 1 1h20c.6 0 1-.5 1-1v-.6c0-2.4-.5-4.6-1.4-6.6H6.4z"
          fill="#1bb5ec"
        />
        <Path
          d="M7 29.83c.1-1.8.5-3.6 1.2-5.2h15.6c.7 1.6 1.1 3.3 1.2 5.2H7zm9-12.3c2.8.9 5.1 2.7 6.7 5.1H9.3c1.6-2.3 3.9-4.1 6.7-5.1zM7 3.13h18c-.2 5.7-3.7 10.5-9 12.3-5.3-1.8-8.8-6.7-9-12.3zm21 26.7h-1c-.2-5.8-3.4-10.9-8.3-13.4 4.9-2.5 8.1-7.6 8.3-13.4h1c.6 0 1-.5 1-1s-.4-1-1-1H4c-.6 0-1 .5-1 1s.4 1 1 1h1c.2 5.8 3.4 10.9 8.3 13.4-4.9 2.6-8.1 7.7-8.3 13.4H4c-.6 0-1 .5-1 1s.4 1 1 1h24c.6 0 1-.5 1-1s-.4-1-1-1z"
          fill="#364f6b"
        />
      </G>
    </Svg>
    
  );
}


function SvgCalendarioAzul(props) {
  
  return (
    
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 20 20"
      {...props}
    >
      <Defs>
        <Path
          id="filas_calendarioAzul__a"
          d="M17.5 1.9h-1.2v-.6c0-.3-.3-.6-.6-.6h-2.5c-.3 0-.6.3-.6.6v.6h-5v-.6c0-.3-.3-.6-.6-.6H4.4c-.3 0-.6.3-.6.6v.6H2.5c-1 0-1.9.8-1.9 1.9v13.8c0 1 .8 1.9 1.9 1.9h15c1 0 1.9-.8 1.9-1.9V3.8c0-1.1-.8-1.9-1.9-1.9zm-3.7 1.2H15V1.9h-1.2v1.2zM5 3.1h1.2V1.9H5v1.2zm-3.1.7c0-.3.3-.6.6-.6h1.2v.6c0 .3.3.6.6.6h2.5c.3 0 .6-.3.6-.6v-.7h5v.6c0 .3.3.6.6.6h2.5c.3 0 .6-.3.6-.6v-.6h1.2c.3 0 .6.3.6.6v1.9h-16V3.8zm16.2 13.7c0 .3-.3.6-.6.6h-15c-.3 0-.6-.3-.6-.6V6.9h16.2v10.6z"
        />
      </Defs>
      <ClipPath id="filas_calendarioAzul__b">
        <Use xlinkHref="#filas_calendarioAzul__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#filas_calendarioAzul__b)">
        <Defs>
          <Path id="filas_calendarioAzul__c" d="M0 0h20v20H0z" />
        </Defs>
        <ClipPath id="filas_calendarioAzul__d">
          <Use xlinkHref="#filas_calendarioAzul__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#filas_calendarioAzul__d)"
          fill="#005d8f"
          d="M-4.4-4.4h28.8v28.8H-4.4z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="filas_calendarioAzul__e"
            d="M6.9 13.1h2.5v2.5c0 .3.3.6.6.6s.6-.3.6-.6v-2.5h2.5c.3 0 .6-.3.6-.6s-.3-.6-.6-.6h-2.5V9.4c0-.3-.3-.6-.6-.6s-.6.3-.6.6v2.5H6.9c-.3 0-.6.3-.6.6s.3.6.6.6"
          />
        </Defs>
        <ClipPath id="filas_calendarioAzul__f">
          <Use xlinkHref="#filas_calendarioAzul__e" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#filas_calendarioAzul__f)">
          <Defs>
            <Path id="filas_calendarioAzul__g" d="M0 0h20v20H0z" />
          </Defs>
          <ClipPath id="filas_calendarioAzul__h">
            <Use xlinkHref="#filas_calendarioAzul__g" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#filas_calendarioAzul__h)"
            fill="#005d8f"
            d="M1.3 3.8h17.5v17.5H1.3z"
          />
        </G>
      </G>
    </Svg>
    
  );
}


function SvgHora(props) {
  
  return (
      
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 15 20"
      {...props}
    >
      <Defs>
        <Path
          id="filas_hora__a"
          d="M10.7 4.2l-.8-3.1c0-.3-.3-.5-.6-.5H4.6c-.3 0-.5.2-.6.5l-.8 3.1C1.4 5.5.1 7.6.1 10s1.3 4.5 3.1 5.8l.8 3.1c.1.3.3.5.6.5h4.7c.3 0 .5-.2.6-.5l.8-3.1c1.9-1.2 3.1-3.4 3.1-5.8s-1.2-4.5-3.1-5.8zM5.1 1.9h3.7l.4 1.6c-.7-.2-1.4-.4-2.2-.4s-1.6.2-2.3.4l.4-1.6zm3.7 16.2H5.1l-.4-1.6c.7.2 1.5.4 2.3.4s1.6-.1 2.3-.4l-.5 1.6zM7 15.6c-3.1 0-5.6-2.5-5.6-5.6 0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6c0 3.1-2.5 5.6-5.6 5.6z"
        />
      </Defs>
      <ClipPath id="filas_hora__b">
        <Use xlinkHref="#filas_hora__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#filas_hora__b)">
        <Defs>
          <Path id="filas_hora__c" d="M0 0h15v20H0z" />
        </Defs>
        <ClipPath id="filas_hora__d">
          <Use xlinkHref="#filas_hora__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#filas_hora__d)"
          fill="#007db7"
          d="M-4.9-4.4h23.8v28.8H-4.9z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="filas_hora__e"
            d="M8.2 9.4h-.6V7.5c0-.3-.3-.6-.6-.6s-.6.3-.6.6V10c0 .3.3.6.6.6h1.2c.3 0 .6-.3.6-.6s-.2-.6-.6-.6"
          />
        </Defs>
        <ClipPath id="filas_hora__f">
          <Use xlinkHref="#filas_hora__e" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#filas_hora__f)">
          <Defs>
            <Path id="filas_hora__g" d="M0 0h15v20H0z" />
          </Defs>
          <ClipPath id="filas_hora__h">
            <Use xlinkHref="#filas_hora__g" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#filas_hora__h)"
            fill="#007db7"
            d="M1.3 1.9h12.5v13.8H1.3z"
          />
        </G>
      </G>
    </Svg>
    
  );
}


function SvgCaja(props) {
  
  return (
      
    <Svg
      id="filas_caja__Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 69 68"
      xmlSpace="preserve"
      enableBackground="new 0 0 69 68"
      {...props}
    >
      <G id="filas_caja__Artboard-Copy-7">
        <G id="filas_caja__Page-1" transform="translate(-1 -2)">
          <Path id="filas_caja__p1" fill="none" d="M-1 72h72V0H-1z" />
          <G id="filas_caja__Group-12">
            <Path
              id="filas_caja__p2"
              className="filas_caja__stp1"
              d="M11.4 20.2H5.8c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2H8V27c0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2v-2.4c2.6-.5 4.5-2.8 4.5-5.5 0-3.1-2.5-5.6-5.6-5.6H9.1c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1h5.6c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-2.2V4.5c0-1.2-1-2.2-2.2-2.2C9 2.2 8 3.3 8 4.5v2.4c-2.6.5-4.5 2.8-4.5 5.5C3.5 15.5 6 18 9.1 18h2.2c.6 0 1.1.5 1.1 1.1s-.4 1.1-1 1.1"
            />
            <Path
              id="filas_caja__p4"
              className="filas_caja__stp1"
              d="M48.5 54H14.8c-1.2 0-2.2-1-2.2-2.2V41.4l2.9 2.9c.9.9 2.3.9 3.2 0 .9-.9.9-2.3 0-3.2l-6.8-6.8c-.9-.9-2.3-.9-3.2 0l-6.8 6.8c-.9.9-.9 2.4 0 3.2.9.9 2.3.9 3.2 0L8 41.4v10.3c0 3.7 3 6.8 6.8 6.8h33.8c1.2 0 2.2-1 2.2-2.2S49.7 54 48.5 54"
            />
            <Path
              id="filas_caja__p6"
              className="filas_caja__stp1"
              d="M58.6 51.8h5.6c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2H62V45c0-1.2-1-2.2-2.2-2.2-1.2 0-2.2 1-2.2 2.2v2.4c-2.6.5-4.5 2.8-4.5 5.5 0 3.1 2.5 5.6 5.6 5.6h2.2c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1h-5.6c-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2h2.2v2.2c0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2v-2.4c2.6-.5 4.5-2.8 4.5-5.5 0-3.1-2.5-5.6-5.6-5.6h-2.2c-.6 0-1.1-.5-1.1-1.1s.5-.9 1.1-.9"
            />
            <Path
              id="filas_caja__p8"
              className="filas_caja__stp1"
              d="M68.1 27.7c-.9-.9-2.3-.9-3.2 0L62 30.6V20.2c0-3.7-3-6.8-6.8-6.8H21.5c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h33.8c1.2 0 2.2 1 2.2 2.2v10.3l-2.9-2.9c-.9-.9-2.3-.9-3.2 0-.9.9-.9 2.3 0 3.2l6.8 6.8c.9.9 2.3.9 3.2 0l6.8-6.8c.8-.6.8-2.1-.1-2.9"
            />
            <Defs></Defs>
            <Mask
              maskUnits="userSpaceOnUse"
              x={21.5}
              y={22.5}
              width={27}
              height={27}
              id="filas_caja__pmask-2_2_"
            >
              <G filter="url(#filas_caja__Adobe_OpacityMaskFilter)">
                <Path
                  id="filas_caja__ppath-1_2_"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#fff"
                  d="M-1 72h72V0H-1z"
                />
              </G>
            </Mask>
            <Path
              id="filas_caja__p10"
              mask="url(#filas_caja__mask-2_2_)"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#1bb5ec"
              d="M21.5 49.5h27v-27h-27z"
            />
          </G>
        </G>
      </G>
    </Svg>
    
  );
}


function SvgPersonas(props) {
  
  return (
      
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 72" {...props}>
      <G fillRule="evenodd" clipRule="evenodd">
        <Path
          d="M67.7 72H2.3C1 72 0 71 0 69.7c0-19.3 15.7-35 35-35s35 15.7 35 35c0 1.3-1 2.3-2.3 2.3"
          fill="#1bb5ec"
        />
        <Path
          d="M4.8 67.3c1.2-15.6 14.3-28 30.2-28s29 12.4 30.2 28H4.8zM21 20.7c0-7.7 6.3-14 14-14s14 6.3 14 14-6.3 14-14 14-14-6.3-14-14zm24.3 15.5c5-3.3 8.4-9.1 8.4-15.6C53.7 10.4 45.3 2 35 2s-18.7 8.4-18.7 18.7c0 6.5 3.3 12.2 8.4 15.6C10.4 40.6 0 54 0 69.7 0 71 1 72 2.3 72h65.3c1.3 0 2.3-1 2.3-2.3C70 54 59.6 40.6 45.3 36.2z"
          fill="#364f6b"
        />
      </G>
    </Svg>
    
  );
}


export {SvgFila, SvgRelojArena, SvgCalendarioAzul, SvgHora, SvgCaja, SvgPersonas}