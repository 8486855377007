import { getReservasByUser, cancelReservation } from '../../config/config'
import axios from 'axios';



class misReservasFunctions {

    async obtenerReservas(applicantId, captchaToken) {

        return await axios.post(getReservasByUser,
            {
                applicantId: applicantId,
                status: "esperando",
                token: captchaToken,
            })
    }

    async cancelarReserva(idReserva, dateToReservation, captchaToken) {
        return await axios.post(cancelReservation,
                {
                    idReserva: idReserva,
                    dateToReservation: dateToReservation,
                    token: captchaToken
                })
    }
}

export default new misReservasFunctions();