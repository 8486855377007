import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import modalStyle from "../modalStyle";
import { cancelReservation, recaptcha3Key } from "../../../config/config";
import store from "../../../redux/store";
import FontAwesome from "react-native-vector-icons/FontAwesome5";
import encryptConfig from "../../Utils/encryptConfig";
export default function ConfirmarCancelarReservaComponent(props) {
  return (
    <View style={modalStyle.modalContent}>
      <Text style={modalStyle.padBotom}>Cancelar</Text>
      <Text>¿Desea cancelar su agendamiento?</Text>
      <View
        style={[modalStyle.modalFooter, { justifyContent: "space-between" }]}
      >
        <TouchableOpacity onPress={() => props.navigation.goBack()}>
          <Text style={modalStyle.botonCancelarVolver}>
            <FontAwesome
              name="chevron-left"
              style={{ fontSize: 16, marginRight: 8 }}
            />{" "}
            Cancelar y volver
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => cancelarReserva()}
          style={{
            backgroundColor: "#E81E2B",
            borderRadius: "4px",
            paddingHorizontal: 8,
          }}
        >
          <Text
            style={{
              fontFamily: "Ubuntu-Regular",
              color: "white",
              backgroundColor: "#e81e2b",
              paddingVertical: 7,
              paddingHorizontal: 20,
              borderRadius: 5,
              alignItems: "center",
              fontSize: 16,
            }}
          >
            Confirmar
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  async function cancelarReserva() {
    let tokenIntegrity = encryptConfig.cancelarReservaEncrypt(
      {
        idReserva: props.idReserva,
        applicantId: props.userData.rut,
        codigo: props.userData.codigo,
      },
      "cancelar"
    );

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptcha3Key, { action: "validate" })
        .then(async (token) => {
          let response = await fetch(cancelReservation, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Integrity: tokenIntegrity,
            },
            body: JSON.stringify({
              idReserva: props.idReserva,
              applicantId: props.userData.rut,
              codigo: props.userData.codigo,
              token: token,
            }),
          });

          response.json().then((json) => {
            if (json.reserva) {
              if (json.reserva.status === "esperando") {
                store.dispatch({
                  type: "CANCEL_RESERVA",
                  payload: json.reserva,
                });
              }
            } else {
              alert("Error al reservar, datos incorrectos");
            }
          });

          props.onGoBack();
          props.navigation.navigate("SeguimientoReservas");
        });
    });
  }
}
