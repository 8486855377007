import { Feather } from '@expo/vector-icons';
import React, { Component } from 'react';
import { Text, View } from 'react-native';
import { ActivityIndicator, Colors } from 'react-native-paper';
import { connect, shallowEqual } from 'react-redux';
import { recaptcha3Key } from '../../config/config';
import sucursalStyle from '../Sucursal/sucursal.style';
import filasFunctions from './filas.functions';
import FilaLayoutSinTurno from './filas.layout';

class FilasComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            filas: null,
            turnos: null,
            filasStatus: false,
            branchState: this.props.branchState,
            width: this.props.width,
        };
    }

    async componentDidMount() {
        let responseFilas = await filasFunctions.obtenerFilas(this.props.branch.name);
        const jsonFilas = await responseFilas.json();

        if (responseFilas.ok) {
             if (this.props.user) {
            //     let responseTurnos = await filasFunctions.obtenerTurnos(this.props.user.applicantId);
            //     const jsonTurnos = await responseTurnos.json();

            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(recaptcha3Key, { action: "validate" })
                    .then(async (token) => {
                        let responseReservas = await filasFunctions.obtenerReservas(this.props.user.rut, token)
                        const jsonReservas = await responseReservas.json();
                        if(!jsonReservas.error) {
                            
                        
                        for (var i = 0; i < jsonFilas.queues.length; i++) {
                            //         jsonFilas.queues[i].solicitarNum = 0;
                            //         for (var j = 0; j < jsonTurnos.numbers.length; j++) {
                            //             if (jsonTurnos.numbers[j].queue == jsonFilas.queues[i]._id) {
                            //                 jsonFilas.queues[i].numeroPedido = jsonTurnos.numbers[j].number;
                            //                 jsonFilas.queues[i].solicitarNum = 1;
                            //                 jsonFilas.queues[i].lastNumberTaken = jsonTurnos.numbers[j].number;
                            //             }
                            //         }
                                    for (let reserva of jsonReservas.reservas) {
                                        if (reserva.queueId == jsonFilas.queues[i]._id) {
                                            jsonFilas.queues[i].hourReservation = reserva.hourReservation;
                                            jsonFilas.queues[i].dateToReservation = reserva.dateToReservation;
                                            jsonFilas.queues[i].idReserva = reserva._id;
                                            jsonFilas.queues[i].correlative = reserva.correlative
                                            jsonFilas.queues[i].solicitarNum = 2;
                                        }
                                    }
                                }
                            } 
                        });
                    });
                        
            //     this.setState({ turnos: jsonTurnos.numbers, reservas: jsonReservas.reservas });
            }
            this.setState({ filas: jsonFilas.queues, isReady: true })
        }
        this.setState({
            filasStatus: responseFilas.ok, isReady: true
        })
    }

    async componentDidUpdate(prevProps) {
        if (shallowEqual(prevProps.msg, this.props.msg)) {
        } else {
            if (!this.props.msg.recoverNum) {
                const filasAux = this.state.filas;
                for (var i = 0; i < this.state.filas.length; i++) {
                    if (this.state.filas[i]._id == this.props.msg._id || this.state.filas[i]._id == this.props.msg.queue) {
                        filasAux[i].currentAttendingNumber = this.props.msg.currentAttendingNumber;

                        if (!this.props.msg.newNumber) {
                            filasAux[i].timeToWaiting = this.props.msg.timeToWaiting;
                        }

                        filasAux[i].module = this.props.msg.module

                        if (filasAux[i].solicitarNum === 1) {
                            if ((filasAux[i].currentAttendingNumber == filasAux[i].numeroPedido)) {

                                let payload = {
                                    reserva: false,
                                    isVisible: true,
                                    fila: filasAux[i].name,
                                    module: filasAux[i].module,
                                    numeroPedido: filasAux[i].description + filasAux[i].numeroPedido
                                }
                                filasAux[i].solicitarNum = 0;
                                filasAux[i].numeroPedido = 0;
                                this.props.navigation.navigate('ModalComponent', { type: 'miTurno', payload })
                                // this.props.showModalMiTurno(payload);
                            }
                        } else {
                            if (this.props.msg.lastNumberTaken) {
                                filasAux[i].lastNumberTaken = this.props.msg.lastNumberTaken;
                            } else {
                                filasAux[i].lastNumberTaken = this.props.msg.number;
                            }
                        }

                        if (this.props.msg.reserva
                            // && this.props.msg.applicantId === this.props.user.applicantId
                        ) {
                            if (filasAux[i].idReserva === this.props.msg.reservaId) {
                                let payload = {
                                    reserva: true,
                                    isVisible: true,
                                    fila: filasAux[i].name,
                                    module: filasAux[i].module,
                                    numeroPedido: this.props.msg.reservationNumber,
                                    correlative: this.props.msg.correlative,
                                    description: this.props.msg.description
                                }
                                filasAux[i].solicitarNum = 0;
                                filasAux[i].numeroPedido = 0;
                                this.props.navigation.navigate('ModalComponent', { type: 'miTurno', payload })
                                // this.props.showModalMiTurno(payload);
                            }
                        }
                    }
                }
                this.setState({
                    filas: filasAux
                })
            }
        }

        if (shallowEqual(prevProps.newNumber, this.props.newNumber)) {
        } else {
            const filasAux2 = this.state.filas;
            for (var j = 0; j < this.state.filas.length; j++) {
                if (this.state.filas[j]._id == this.props.newNumber.queue) {
                    filasAux2[j].solicitarNum = 1;
                    filasAux2[j].numeroPedido = this.props.newNumber.number;
                    filasAux2[j].lastNumberTaken = this.props.newNumber.number;
                    filasAux2[j].applicantEmail = this.props.newNumber.applicantEmail
                    filasAux2[j].applicantPhone = this.props.newNumber.applicantPhone
                    filasAux2[j].applicantName = this.props.newNumber.applicantName
                    filasAux2[j].applicantId = this.props.newNumber.applicantId
                }
            }
            this.setState({
                filas: filasAux2
            })
        }

        if (shallowEqual(prevProps.reserva, this.props.reserva)) {
        } else {
            if (this.props.reserva.detalles && this.props.reserva.cancelar === false) {
                let filasAux3 = this.state.filas;
                for (var j = 0; j < this.state.filas.length; j++) {
                    if (this.state.filas[j]._id == this.props.reserva.detalles.queueId) {
                        filasAux3[j].hourReservation = this.props.reserva.detalles.hourReservation;
                        filasAux3[j].dateToReservation = this.props.reserva.detalles.dateToReservation;
                        filasAux3[j].idReserva = this.props.reserva.detalles._id;
                        filasAux3[j].solicitarNum = 2;
                        filasAux3[j].correlative = this.props.reserva.detalles.correlative;
                        // filasAux3[j].correlative = this.props.reserva.detalles.correlative;
                        // branchId: props.branch._id,
                        // hourReservation: props.hora,
                        // dateToReservation: props.dateToReservation,
                        // applicantId: props.userData.rut,
                        // queueId: props.queueId,
                        // queueName: props.queueName,
                        // branchName: props.branch.name,
                        filasAux3[j].applicantPhone = this.props.reserva.detalles.applicantPhone,
                            filasAux3[j].applicantName = this.props.reserva.detalles.applicantName,
                            filasAux3[j].applicantEmail = this.props.reserva.detalles.applicantEmail,
                            filasAux3[j].codigo = this.props.reserva.detalles.codigo
                    }
                }
                this.setState({
                    filas: filasAux3
                })
            } else {
                this.setState({
                    isReady: false
                })
                let responseFila = await filasFunctions.obtenerFila(this.props.reserva.detalles.queueId);
                let jsonFila = await responseFila.json();
                let filasAux4 = this.state.filas;

                for (var j = 0; j < filasAux4.length; j++) {
                    if (this.state.filas[j]._id == jsonFila.queues[0]._id) {
                        filasAux4[j].solicitarNum = 0;
                        filasAux4[j].hourReservation = null;
                        filasAux4[j].dateToReservation = null;
                        filasAux4[j].idReserva = null;
                        filasAux4[j].correlative = 0;
                        filasAux4[j].currentAttendingNumber = jsonFila.queues[0].currentAttendingNumber
                        filasAux4[j].lastNumberTaken = jsonFila.queues[0].lastNumberTaken
                        filasAux4[j].timeToWaiting = jsonFila.queues[0].timeToWaiting
                        filasAux4[j].applicantPhone = null
                        filasAux4[j].applicantName = null
                        filasAux4[j].applicantEmail = null
                        filasAux4[j].codigo = null
                    }
                }

                this.setState({
                    filas: filasAux4,
                    isReady: true
                })
            }
        }
    }


    render() {
        if (this.state.isReady) {
            if (this.state.filasStatus) {
                return (
                    <>
                        {
                            this.state.filas.map(fila =>
                                fila.reserva &&
                                <FilaLayoutSinTurno key={fila._id}
                                    branch={this.props.branch}
                                    fila={fila} 
                                    width={this.props.width}
                                    navigation={this.props.navigation}
                                    branchState={this.state.branchState}
                                    user={this.props.user}
                                    withGroupName={this.state.filas.withGroupName}
                                    noGps={this.props.noGps}
                                    showModalInicio={(evt) => this.props.showModalInicio(evt)}
                                />
                            )
                        }
                    </>
                )
            } else {
                return (
                    <Text style={sucursalStyle.noFilas}>
                        <Feather name="clock" size={25} color="grey" />No se encontraron filas</Text>
                )
            }
        } else {
            return (<View><ActivityIndicator animating={true} color={Colors.green600} ></ActivityIndicator></View>)
        }
    }

}
const mapStateToProps = state => {
    return {
        newNumber: state.number.number,
        reserva: state.reserva.reserva
    }
}

export default connect(mapStateToProps)(FilasComponent)

