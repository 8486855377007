import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import modalStyle from "../modalStyle";

export default function ErrorVerifyComponent(props) {
  return (
    <View style={{ alignItems: "center", marginBottom: 10 }}>
      <Text
        style={[modalStyle.padBotom, { textAlign: "center", fontSize: 24 }]}
      >
        Datos de verificación incorrectos
      </Text>
      <View footer style={modalStyle.selfCenter}>
        <TouchableOpacity
          onPress={() => props.navigation.goBack()}
          style={{
            backgroundColor: "#E81E2B",
            borderRadius: "4px",
            paddingHorizontal: 16,
          }}
        >
          <Text
            style={{
              fontFamily: "Ubuntu-Regular",
              color: "white",
              backgroundColor: "#e81e2b",
              paddingVertical: 7,
              paddingHorizontal: 20,
              borderRadius: 5,
              alignItems: "center",
              fontSize: 16,
            }}
          >
            Ok
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
