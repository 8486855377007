import { getTramites } from '../../config/config';

class tramitesFunctions {

    async obtenerTurnos(branchId) {

        let response = await fetch(getTramites + branchId, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });

        return response
    }
}

export default new tramitesFunctions();