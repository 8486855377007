import axios from 'axios';
import Moment from 'moment';
import { verifyAvailableHour } from '../../config/config';

async function verifyAvailableHourFunction(hourReservation, dateToReservation, queueId) {
    return await axios.post(verifyAvailableHour,
        {
            hourReservation: hourReservation,
            dateToReservation: Moment(dateToReservation).format('DD-MM-YYYY'),
            queueId: queueId
        })
}

export default verifyAvailableHourFunction