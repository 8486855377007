
const initialState = {
    number: []
}

function numberReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_NUMBER':
            return {
                ...state,
                number: action.payload
            }
        default:
            return state
    }
}

export default numberReducer