import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, ScrollView, Linking } from 'react-native';
import { Button, Input, Text } from 'react-native-elements';
import FontAwesome from 'react-native-vector-icons/FontAwesome5';
import MediaQuery from "react-native-web-responsive";
import encryptConfig from '../../Utils/encryptConfig';
import modalStyle from '../modalStyle';
import formularioFunctions from './formulario.functions';
import store from '../../../redux/store'
import { clearUser, setUser } from '../../../redux/index';
import { recaptcha3Key } from '../../../config/config';

export default function FormularioComponent(props) {

  const [nombre, setNombre] = useState('');
  const [nameError, setNameError] = useState('');
  const [apellido, setApellido] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [rut, setRut] = useState('');
  const [rutError, setRutError] = useState('');
  const [correo, setCorreo] = useState('');
  const [correoError, setCorreoError] = useState('');
  const [telefono, setTelefono] = useState('');
  const [telefonoError, setTelefonoError] = useState('');
  const [datosError, setDatosError] = useState('');
  const [codigo, setCodigo] = useState('');
  useEffect(() => {
    props.navigation.setOptions({
      title: 'Agenda tu hora',
    });
  }, [ props.navigation]);

  function codigoValidation() {}

  function nameValidation() {
    var validation = formularioFunctions.validateNombre(nombre);
    setNombre(nombre.trim().replace(/\s\s+/g, ' '));
    setNameError(validation);
  }

  /* function lastNameValidation() {
    var validation = formularioFunctions.validateLength(apellido);
    setApellido(apellido);
    setLastNameError(validation.error);
  } */

  function rutValidation() {
    var validation = formularioFunctions.formatAndValidate(rut);
    setRut(validation.rut);
    setRutError(validation.errorRut);
  }

  function mailValidation() {
    var validation = formularioFunctions.mailFormat(correo);
    setCorreo(validation.correo);
    setCorreoError(validation.errorCorreo);
  }
  function phoneValidation() {
    var validation = formularioFunctions.telefonoFormat(telefono);
    setTelefono(validation.telefono);
    setTelefonoError(validation.errorTelefono);
  }

  async function verificaReservas(rutUsuario) {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptcha3Key, { action: "validate" })
        .then(async (token) => {
          localStorage.setItem("token", token);
          let responseReservas = await formularioFunctions.reservasPorRut(
            rutUsuario,
            token
          );
          const jsonReservas = await responseReservas.json();
          if (!jsonReservas.error) {
            var cantidadReservas = 0;
    
            for (let reserva of jsonReservas.reservas) {
              cantidadReservas = cantidadReservas + 1;
              //console.log(reserva.comuna + " - " + cantidadReservas);
            }
            if (cantidadReservas > 0) {
              props.navigation.navigate("ModalComponent", {
                type: "reservasActivas",
                tipoFuncion: "reservasActivas",
              });
            } else {
              props.navigation.goBack();
            }
          } else {
            props.navigation.goBack();
          }
        });
    });
  }

  function navigateReservas() {
    props.navigation.navigate('SeguimientoReservas');
  }

  function redireccionarPedir() {
    let validate = formularioFunctions.validateDatos(
      correo,
      telefono,
      nombre,
      rut
    );

    if (
      !validate &&
      !telefonoError &&
      !nameError &&
      !rutError &&
      !correoError
    ) {
      let userData = {
        correo: correo,
        telefono: encryptConfig.rsaEncrypt(telefono),
        nombre: nombre,
        apellido: apellido,
        rut: encryptConfig.rsaEncrypt(rut.replace('.', '').replace('.', '')),
      };

      if (props.type === 'numero') {
        props.userData(userData);
      }
      if (props.type === 'confReserva') {
        props.userData(userData);
      }
      if (props.type === 'datosIniciales') {
        store.dispatch({type: 'LOGGED_IN', payload: userData});
        verificaReservas(userData.rut);
      }
    } else {
      setDatosError(validate);
      store.dispatch({type:'SIGN_OUT'});
    }
  }
  function redireccionarReprogramar() {
    let validate = formularioFunctions.validateDatosReprogramar(codigo, props.userDataSecond.rut);
    if (!validate && !rutError) {
      props.openCode(true);
      if (props.type === 'numero') {
        formularioFunctions
          .verificarNumero(
            codigo,
            props.userDataSecond.rut
            /*encryptConfig.rutEncrypt(rut.replace('.', '').replace('.', ''))*/
          )
          .then((response) => {
            let numero = response.data.number;
            let userData = {
              codigo: numero.codigo,
              rut: numero.applicantId,
              correo: numero.applicantEmail,
              telefono: numero.applicantPhone,
              nombre: numero.applicantName,
              apellido: numero.applicantLastName,
            };
            props.userData(userData);
          })
          .catch((error) => {
            props.errorVerify(true);
          });
      } else if (props.type === 'confReserva') {
        formularioFunctions
          .verificarReserva(
            codigo,
            props.userDataSecond.rut
            /*encryptConfig.rutEncrypt(rut.replace('.', '').replace('.', ''))*/
          )
          .then((response) => {
            let reserva = response.data.response;
            let userData = {
              codigo: reserva.codigo,
              rut: reserva.applicantId,
              correo: reserva.applicantEmail,
              telefono: reserva.applicantPhone,
              nombre: reserva.applicantName,
              apellido: reserva.applicantLastName,
            };
            props.userData(userData);
          })
          .catch((error) => {
            props.errorVerify(true);
          });
      }
    } else {
      setDatosError(validate);
    }
  }

  function redireccionarSeguimiento() {
    if (props.type === 'reservasActivas') {
      props.navigation.navigate('SeguimientoReservas');
    }else{

    //let validate = formularioFunctions.validateDatosReprogramar(codigo, rut);
    let userData = {
      rut: store.getState().user.rut,
    };
    //console.log("User data", userData)
    if (!validate && !rutError) {
      props.userData(userData);
    } else {
      setDatosError(validate);
    }
  }
}

  function redireccionarCancelar() {
    let validate = formularioFunctions.validateDatosReprogramar(codigo);
    let userData = {
      rut: props.userDataSecond.rut,
      codigo: codigo,
    };
    
    if (!validate) {
      props.openCode(true);
      props.userData(userData);
    } else {
      setDatosError(validate);
    }
  }

  return (
    <View style={modalStyle.itemCenter}>
      {props.tipoFuncion === 'pedir' ? (
        <View>
        <MediaQuery minWidth={1224}>
          <View>
            <Text style={modalStyle.tituloModal}>
              Ingresa tus datos para{' '}
              {props.type === 'numero' ? 'pedir un número' : 'agendar una visita'}
            </Text>
            <Text style={modalStyle.subtituloModal}>
              Recuerda que todos los datos son obligatorios.
            </Text>
          </View>
          <View style={modalStyle.separadorSearch} />
          <View style={{ flex: 1, width: '100%' }}>
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <View style={{width: "50%"}}>
                  <Text style={modalStyle.labelInput}>Nombre y apellido</Text>
                  <Input
                    placeholder='Nombre completo'
                    onChangeText={(value) => setNombre(value)}
                    value={nombre}
                    onBlur={() => {
                      nameValidation();
                    }}
                    maxLength={200}
                    inputStyle={modalStyle.fuenteInput}
                    errorStyle={[
                      { color: '#e81e2b' },
                      modalStyle.ticketTextoMediano,
                    ]}
                    errorMessage={nameError}
                  />
                </View>
                <View style={{width: "50%"}}>
                  <Text style={modalStyle.labelInput}>RUT sin puntos ni guión</Text>
                  <Input
                    placeholder='Ej: 12345678K'
                    onChangeText={(value) => setRut(value)}
                    value={rut}
                    
                    maxLength={rut.includes('.')? 12: rut.includes('-')? 10: 9}
                    inputStyle={modalStyle.fuenteInput}
                    onBlur={() => {
                      rutValidation();
                    }}
                    errorStyle={[
                      { color: '#e81e2b' },
                      modalStyle.ticketTextoMediano,
                    ]}
                    errorMessage={rutError}
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <View style={{width: "50%"}}>
                  <Text style={modalStyle.labelInput}>Correo electrónico</Text>
                  <Input
                    placeholder='Email'
                    onBlur={() => {
                      mailValidation();
                    }}
                    onChangeText={(value) => setCorreo(value)}
                    value={correo}
                    maxLength={254}
                    inputStyle={modalStyle.fuenteInput}
                    errorStyle={[
                      { color: '#e81e2b' },
                      modalStyle.ticketTextoMediano,
                    ]}
                    errorMessage={correoError}
                  />
                </View>
                <View style={{width: "50%"}}>
                  <Text style={modalStyle.labelInput}>Celular</Text>
                  <Input
                    placeholder='Ej: 9XXXXXXXX'
                    onChangeText={(value) => setTelefono(value)}
                    value={telefono}
                    onBlur={() => {
                      phoneValidation();
                    }}
                    maxLength={12}
                    inputStyle={modalStyle.fuenteInput}
                    errorStyle={[
                      modalStyle.ticketTextoMediano,
                      { color: '#e81e2b', fontSize: 13 },
                    ]}
                    errorMessage={telefonoError}
                  />
                </View>
              </View>
              <View>
                <Text style={modalStyle.datosError}>{datosError}</Text>
              </View>
            </View>
          </View>
        </MediaQuery>

        <MediaQuery maxWidth={1223}>
          <View>
            <Text style={[modalStyle.tituloModal, {padding: 0, marginTop: 0, marginBottom: 5, fontSize: 22}]}>
              Ingresa tus datos para{' '}
              {props.type === 'numero' ? 'pedir un número' : 'agendar una visita'}
            </Text>
            <Text style={modalStyle.subtituloModal}>
              Recuerda que todos los datos son obligatorios.
            </Text>
          </View>
          <View style={modalStyle.separadorSearch} />
          <View style={{ flex: 1, width: '100%' }}>
            <ScrollView contentContainerStyle={{height: 200}}>
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <View style={{ padding: 0, width: "100%" }}>
                  <Text style={modalStyle.labelInput}>Nombre y apellido</Text>
                  <Input
                    placeholder='Nombre completo'
                    onChangeText={(value) => setNombre(value)}
                    value={nombre}
                    onBlur={() => {
                      nameValidation();
                    }}
                    maxLength={200}
                    inputStyle={[modalStyle.fuenteInput, {height: 30}]}
                    errorStyle={[
                      { color: '#e81e2b' },
                      modalStyle.ticketTextoMediano,
                    ]}
                    errorMessage={nameError}
                  />
                </View>
                <View style={{ padding: 0, width: "100%" }}>
                  <Text style={modalStyle.labelInput}>RUT sin puntos ni guión</Text>
                  <Input
                    placeholder='Ej: 12345678K'
                    onChangeText={(value) => setRut(value)}
                    value={rut}
                    
                    maxLength={rut.includes('.')? 12: rut.includes('-')? 10: 9}
                    inputStyle={[modalStyle.fuenteInput, {height: 30}]}
                    onBlur={() => {
                      rutValidation();
                    }}
                    errorStyle={[
                      { color: '#e81e2b' },
                      modalStyle.ticketTextoMediano,
                    ]}
                    errorMessage={rutError}
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ padding: 0, width: "100%" }}>
                  <Text style={modalStyle.labelInput}>Correo electrónico</Text>
                  <Input
                    placeholder='Email'
                    onBlur={() => {
                      mailValidation();
                    }}
                    onChangeText={(value) => setCorreo(value)}
                    value={correo}
                    maxLength={254}
                    inputStyle={[modalStyle.fuenteInput, {height: 30}]}
                    errorStyle={[
                      { color: '#e81e2b' },
                      modalStyle.ticketTextoMediano,
                    ]}
                    errorMessage={correoError}
                  />
                </View>
                <View style={{ padding: 0, width: "100%" }}>
                  <Text style={modalStyle.labelInput}>Celular</Text>
                  <Input
                    placeholder='Ej: 9XXXXXXXX'
                    onChangeText={(value) => setTelefono(value)}
                    value={telefono}
                    onBlur={() => {
                      phoneValidation();
                    }}
                    maxLength={12}
                    inputStyle={[modalStyle.fuenteInput, {height: 30}]}
                    errorStyle={[
                      modalStyle.ticketTextoMediano,
                      { color: '#e81e2b', fontSize: 13 },
                    ]}
                    errorMessage={telefonoError}
                  />
                </View>
              </View>
              <View>
                <Text style={modalStyle.datosError}>{datosError}</Text>
              </View>
            </ScrollView>
          </View>
        </MediaQuery>
        </View>
      ) : props.tipoFuncion === 'reprogramar' ||
        props.tipoFuncion === 'cancelar' ? (
        <View>
          <Text style={[modalStyle.ticketSucursal, { margin: 10 }]}>
            Debes ingresar tus datos para{' '}
            {props.tipoFuncion === 'reprogramar' ? 'agendar' : 'cancelar'}
          </Text>
          <View style={{ flex: 1 }}>
            {/* <View>
              <Input
                placeholder='Rut'
                onChangeText={(value) => setRut(value)}
                value={rut}
                inputStyle={modalStyle.fuenteInput}
                maxLength={9}
                onBlur={() => {
                  rutValidation();
                }}
                errorStyle={[
                  { color: '#e81e2b' },
                  modalStyle.ticketTextoMediano,
                ]}
                errorMessage={rutError}
              />
            </View> */}
            <View>
              <Input
                placeholder='Codigo'
                onChangeText={(value) => setCodigo(value)}
                value={codigo}
                inputStyle={modalStyle.fuenteInput}
                maxLength={6}
                onBlur={() => {
                  codigoValidation();
                }}
                errorStyle={[
                  { color: '#e81e2b' },
                  modalStyle.ticketTextoMediano,
                ]}
              />
            </View>
            <View>
              <Text>{datosError}</Text>
            </View>
          </View>
        </View>
      ) : props.tipoFuncion === 'seguimiento' ? (
        <View style={[{ padding: 20 }, modalStyle.cuadroBlanco]}>
          <Text style={modalStyle.ticketSucursal}>
            {props.type === 'reservas' ? 'Ingresa a tus horas agendadas' : 'Ingresa a tus números'}
          </Text>
          <View style={{ flex: 1 }}>
            <View style={{ marginTop: 20 }}>
              <Input
                placeholder='Rut'
                onChangeText={(value) => setRut(value)}
                value={rut}
                inputStyle={modalStyle.fuenteInput}
                maxLength={9}
                onBlur={() => {
                  rutValidation();
                }}
                errorStyle={[
                  { color: '#e81e2b' },
                  modalStyle.ticketTextoMediano,
                ]}
                errorMessage={rutError}
              />
            </View>
            <View>
              <Text>{datosError}</Text>
            </View>
          </View>
        </View>
      ) : props.tipoFuncion === 'reservasActivas' ? (
        <View>
          <Text style={[modalStyle.tituloModal, {fontSize: 18, marginTop: 0, marginBottom: 0}]}>
            Agendamientos activos
          </Text>
          <View style={modalStyle.separadorSearch} />
          <View style={{ flex: 1 }}>
            <View style={{ marginVertical: 20 }}>
              <Text style={modalStyle.ticketTextoMediano}>
                Tiene agendamientos pendientes de atención, ¿desea visualizarlos?
              </Text>
            </View>
          </View>
        </View>
      ) : null}
      <View style={modalStyle.separadorSearch} />
      <MediaQuery minWidth={1224}>
        <View style={modalStyle.containerAgendarVolver}>
          <TouchableOpacity
            style={modalStyle.botonCancelarVolver}
            onPress={() => {props.type === 'datosIniciales' ? Linking.openURL("https://www.coopeuch.cl") : props.navigation.goBack()}}
          >
            <Text style={modalStyle.botonCancelarVolver}>
              <FontAwesome
                name='chevron-left'
                style={{ fontSize: 16, marginRight: 8 }}
              />{' '}
              {props.tipoFuncion === 'reservasActivas' ? 'Cancelar' : 'Cancelar y volver'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={modalStyle.botonAgendar}
            onPress={() =>
              props.tipoFuncion === 'pedir'
                ? redireccionarPedir()
                : props.tipoFuncion === 'reprogramar'
                ? redireccionarReprogramar()
                : props.tipoFuncion === 'cancelar'
                ? redireccionarCancelar()
                : redireccionarSeguimiento()
            }
          >
            <Text style={modalStyle.botonAgendar}>
              {props.tipoFuncion === 'pedir' ||
              props.tipoFuncion === 'reprogramar' || props.tipoFuncion === 'cancelar'
                ? 'Continuar'
                : props.tipoFuncion === 'seguimiento'
                ? 'Consultar'
                : props.type === 'numero'
                ? 'Solicitar hora'
                : props.tipoFuncion === 'reservasActivas'
                ? 'Ver Agendamientos'
                : 'Agendar'}
            </Text>
          </TouchableOpacity>
        </View>
      </MediaQuery>

      <MediaQuery maxWidth={1223}>
        <View style={[modalStyle.containerAgendarVolver, {justifyContent: 'space-between', margin: 0, padding: 0}]}>
          <TouchableOpacity
            style={[modalStyle.botonCancelarVolver, {paddingHorizontal: 3, paddingVertical: 5, fontSize: 14}]}
            onPress={() => {props.type === 'datosIniciales' ? Linking.openURL("https://www.coopeuch.cl") : props.navigation.goBack()}}
          >
            <Text
              style={[modalStyle.botonCancelarVolver, {paddingRight: 0, fontSize: 14}]}
            >
              <FontAwesome
                name='chevron-left'
                style={{ fontSize: 16, marginRight: 8 }}
              />{' '}
              {props.tipoFuncion === 'reservasActivas' ? 'Cancelar' : 'Cancelar y volver'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[modalStyle.botonAgendar, {paddingHorizontal: 3, paddingVertical: 5, fontSize: 14}]}
            onPress={() =>
              props.tipoFuncion === 'pedir'
                ? redireccionarPedir()
                : props.tipoFuncion === 'reprogramar'
                ? redireccionarReprogramar()
                : props.tipoFuncion === 'cancelar'
                ? redireccionarCancelar()
                : redireccionarSeguimiento()
            }
          >
            <Text
              style={[modalStyle.botonAgendar, {fontSize: 14}]}
            >
              {props.tipoFuncion === 'pedir' ||
              props.tipoFuncion === 'reprogramar' || props.tipoFuncion === 'cancelar'
                ? 'Continuar'
                : props.tipoFuncion === 'seguimiento'
                ? 'Consultar'
                : props.type === 'numero'
                ? 'Solicitar hora'
                : props.tipoFuncion === 'reservasActivas'
                ? 'Ver Agendamientos'
                : 'Agendar'}
            </Text>
          </TouchableOpacity>
        </View>
      </MediaQuery>

      {/*<View style={modalStyle.selfCenter, { flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button bordered success onPress={() => props.navigation.goBack()}
                    title={<Text style={modalStyle.textoRojo}><FontAwesome name="chevron-left" style={{ fontSize: 16, marginRight: 8 }} /> Cancelar y volver</Text>}
                    buttonStyle={[modalStyle.matextoRojorgHori]}
                    titleStyle={[modalStyle.textoRojo]}
                />
                <Button onPress={() => props.tipoFuncion === 'pedir' ? redireccionarPedir() : props.tipoFuncion === 'reprogramar' ? redireccionarReprogramar() : redireccionarSeguimiento()}
                    title="Continuar"
                    buttonStyle={[modalStyle.margHori, { backgroundColor: '#e81e2b' }]}
                    titleStyle={modalStyle.textoBlanco}
                />
          </View>*/}
    </View>
  );
}
