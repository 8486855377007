import React, { useState } from "react";
import { View, TouchableHighlight, Text } from "react-native";
import { Button } from "react-native-elements";
import modalStyle from "../Modal/modalStyle";
import MediaQuery from "react-native-web-responsive";
import scheduleValidation from "./scheduleValidation";
import { SvgCalendarioAzul, SvgCalendarioBlanco } from "../svg/svgComponent";

function ReservarButtonComponent(props) {
  const [branchState, setBranchState] = useState(true);
  const [selected, setSelected] = useState(false);

  return (
    <View>
      <MediaQuery minWidth={1224}>
        {/*<Button
          disabled={!branchState}
          buttonStyle={modalStyle.buttonReservar}
          title={props.title}
          icon={<SvgCalendarioAzul width={20} style={{ marginRight: 8 }} />}
          titleStyle={modalStyle.textoCeleste}
          onPress={() => show()}
        />*/}
        <TouchableHighlight
          //style={props.type == 'reprogramar' ? modalStyle.buttonReagendar : modalStyle.buttonReservar}
          style={modalStyle.buttonReservar}
          onPress={() => show()}
          underlayColor={"#007db7"}
          onShowUnderlay={() => setSelected(true)}
          onHideUnderlay={() => setSelected(false)}
        >
          <Text
            style={
              selected
                ? {
                    color: "white",
                    marginHorizontal: 1,
                    fontSize: 14,
                    alignSelf: "center",
                    fontFamily: "Ubuntu-Medium",
                  }
                : {
                    color: "#007db7",
                    marginHorizontal: 1,
                    fontSize: 14,
                    alignSelf: "center",
                    fontFamily: "Ubuntu-Medium",
                  }
            }
          >
            {!selected ? (
              <SvgCalendarioAzul
                width={20}
                height={20}
                style={{ marginRight: 8 }}
              />
            ) : (
              <SvgCalendarioBlanco
                width={20}
                height={20}
                style={{ marginRight: 8 }}
              />
            )}{" "}
            {props.title}
          </Text>
        </TouchableHighlight>
      </MediaQuery>

      <MediaQuery maxWidth={1223}>
        {/*<Button
          disabled={!branchState}
          buttonStyle={[modalStyle.buttonReservar, {paddingRight: 15}]}
          title={props.title}
          icon={<SvgCalendarioAzul width={20} style={{ marginRight: 8 }} />}
          titleStyle={modalStyle.textoCeleste}
          onPress={() => show()}
        />*/}

        <TouchableHighlight
          //style={props.type == 'reprogramar' ? [modalStyle.buttonReagendar, {paddingLeft: 15, paddingRight: 15, paddingTop: 12}] : [modalStyle.buttonReservar, {paddingLeft: 15, paddingRight: 15, paddingTop: 12}]}
          style={[modalStyle.buttonReservar, {paddingLeft: 12, paddingRight: 12, paddingTop: 12}]}
          onPress={() => show()}
          underlayColor={"#007db7"}
          onShowUnderlay={() => setSelected(true)}
          onHideUnderlay={() => setSelected(false)}
        >
          <Text
            style={
              selected
                ? {
                    color: "white",
                    marginHorizontal: 1,
                    fontSize: 13,
                    alignSelf: "center",
                    fontFamily: "Ubuntu-Medium",
                  }
                : {
                    color: "#007db7",
                    marginHorizontal: 1,
                    fontSize: 13,
                    alignSelf: "center",
                    fontFamily: "Ubuntu-Medium",
                  }
            }
          >
            {!selected ? (
              <SvgCalendarioAzul
                width={18}
                height={18}
                style={{ marginRight: 8 }}
              />
            ) : (
              <SvgCalendarioBlanco
                width={18}
                height={18}
                style={{ marginRight: 8 }}
              />
            )}{" "}
            {props.title}
          </Text>
        </TouchableHighlight>
      </MediaQuery>
    </View>
  );

  async function show() {
    let state = await scheduleValidation(props.branchId);
    if (state) {
      props.navigation.navigate("Reservar", {
        branchId: props.branchId,
        queueId: props.queueId,
        tipoFuncion: props.type,
        branchName: props.branchName,
        queueName: props.queueName,
        description: props.description,
        openCode: props.openCode,
        type: "reserva",
        idReserva: props.idReserva,
        site: props.site,
        branch: props.branch,
        maxDays: props.queueMaxDays,
        user: props.user,
        noGps: props.noGps,
        // screen: props.screen
        groupName: props.groupName,
      });
    } else {
      setBranchState(state);
      props.navigation.navigate("ModalComponent", { type: "closedBranch", user: user });
    }
  }
}

function ReservarButtonComponentRed(props) {
  const [branchState, setBranchState] = useState(true);

  return (
    <Button
      disabled={!branchState}
      buttonStyle={branchState ? modalStyle.botonReagendar : {}}
      title={props.title}
      titleStyle={branchState ? modalStyle.botonReagendar : {}}
      onPress={() => show()}
    />
  );

  async function show() {
    let state = await scheduleValidation(props.branchId);
    if (state) {
      props.navigation.navigate("Reservar", {
        user: props.user,
        branchId: props.branchId,
        queueId: props.queueId,
        tipoFuncion: props.type,
        branchName: props.branchName,
        queueName: props.queueName,
        openCode: props.openCode,
        description: props.description,
        type: "reserva",
        idReserva: props.idReserva,
        site: props.site,
        branch: props.branch,
        noGps: props.noGps,
        onGoBack: () => props.onGoBack(),
        // screen: props.screen
      });
    } else {
      setBranchState(state);
      props.navigation.navigate("ModalComponent", { type: "closedBranch", user: user });
    }
  }
}

export { ReservarButtonComponent, ReservarButtonComponentRed };
