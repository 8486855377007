import axios from "axios";
import { generarReserva, reprogramarReserva } from "../../../config/config";
import encryptConfig from "../../Utils/encryptConfig";

class reservaWebFunctions {
  async reservarNumero(props, token) {
    let tokenIntegrity = encryptConfig.cancelarReservaEncrypt(
      {
        branchId: props.branchId,
        hourReservation: props.hora,
        dateToReservation: props.dateToReservation,
        applicantId: props.userData.rut,
        queueId: props.queueId,
        queueName: props.queueName,
        branchName: props.branchName,
        applicantName: props.userData.nombre,
        applicantLastName: props.userData.apellido,
        applicantEmail: props.userData.correo,
        applicantPhone: props.userData.telefono,
        reservationType: props.reservationType,
        description: props.description,
        comuna: props.comuna,
        address: props.address,
      },
      "reservar"
    );

    return await axios.post(
      generarReserva,
      {
        branchId: props.branchId,
        hourReservation: props.hora,
        dateToReservation: props.dateToReservation,
        applicantId: props.userData.rut,
        queueId: props.queueId,
        queueName: props.queueName,
        branchName: props.branchName,
        applicantName: props.userData.nombre,
        applicantLastName: props.userData.apellido,
        applicantEmail: props.userData.correo,
        applicantPhone: props.userData.telefono,
        reservationType: props.reservationType,
        description: props.description,
        comuna: props.comuna,
        address: props.address,
        token: token,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Integrity: tokenIntegrity,
        },
      }
    );
  }

  async reprogramarReservas(props, token) {
    let tokenIntegrity = encryptConfig.cancelarReservaEncrypt(
      {
        idReserva: props.idReserva,
        hourReservation: props.hora,
        dateToReservation: props.dateToReservation,
        branchId: props.branchId,
        queueId: props.queueId,
        queueName: props.queueName,
        branchName: props.branchName,
        applicantId: props.userData.rut,
        applicantName: props.userData.nombre,
        applicantLastName: props.userData.apellido,
        applicantEmail: props.userData.correo,
        applicantPhone: props.userData.telefono,
        codigo: props.userData.codigo,
        reservationType: props.reservationType,
        description: props.description,
        comuna: props.comuna,
        address: props.address
      },
      "reprogramar"
    );
    
    return await axios.post(
      reprogramarReserva,
      {
        idReserva: props.idReserva,
        hourReservation: props.hora,
        dateToReservation: props.dateToReservation,
        branchId: props.branchId,
        queueId: props.queueId,
        queueName: props.queueName,
        branchName: props.branchName,
        applicantId: props.userData.rut,
        applicantName: props.userData.nombre,
        applicantLastName: props.userData.apellido,
        applicantEmail: props.userData.correo,
        applicantPhone: props.userData.telefono,
        codigo: props.userData.codigo,
        reservationType: props.reservationType,
        description: props.description,
        comuna: props.comuna,
        address: props.address,
        token: token,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Integrity: tokenIntegrity,
        },
      }
    );
  }
}

export default new reservaWebFunctions();
