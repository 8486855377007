import { AntDesign, Entypo, Feather } from '@expo/vector-icons';
import React, { Component } from 'react';
import { View } from 'react-native';
import { Card, Text } from 'react-native-elements';
import { RadioButton } from 'react-native-paper';
import sucursalStyle from '../Sucursal/sucursal.style';
import {CancelarReservaComponent, CancelarReservaComponentBlue} from '../Utils/cancelarReserva.component';
import {PedirNumeroComponent} from '../Utils/pedirNumero.component';
import {ReservarButtonComponent} from '../Utils/reservarButton.component';
import {SvgFila, SvgRelojArena, SvgCalendarioAzul, SvgHora, SvgCaja, SvgPersonas} from './filas.svgs';
import { isSafari } from 'react-device-detect';


class FilaLayoutTurno extends Component {

    constructor(props) {
        super(props);
        this.state = {
            branchState: this.props.branchState,
            queueId: this.props.fila._id,
            branch: this.props.branch,
            fila: this.props.fila,
            user: this.props.user,
            withGroupName: this.props.withGroupName
        };
    }

    render() {

        if (this.props.fila.solicitarNum == 1) {
            return (
                <Card containerStyle={{ borderRadius: 5, flexDirection: 'column', margin: 5, marginBottom: 15, paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0, width: this.props.width }} >
                    <Card.Title style={{ width: '100%', flex: 1, fontWeight: 'normal', paddingLeft: 20, marginBottom: 0, marginLeft: 0, marginRight: 0, paddingTop: 5, textAlign: 'left'}}>
                      <View style={{flexDirection: 'row', textAlign: 'left'}}>
                        {this.props.fila.name.toUpperCase() == "CAJA" ? <SvgCaja width={22} height={isSafari && 22} /> : <SvgPersonas width={20} height={isSafari && 20} />}
                        <Text style={sucursalStyle.cabeceraCard}> Atención en </Text><Text style={[sucursalStyle.cabeceraCardSucursal, {textTransform: 'capitalize'}]}>{this.props.fila.groupName}</Text>
                      </View>
                    </Card.Title>
                    
                    <View style={sucursalStyle.separadorSearch}/>
                      <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, paddingLeft: 15, paddingRight: 15, minHeight: 133, alignSelf: 'center'}}>
                          <View style={[{maxWidth: 110}, sucursalStyle.colCard]}>
                              <Text style={[sucursalStyle.negrita, {fontSize: 30, marginBottom: 20}]} >{this.props.fila.description}{this.props.fila.currentAttendingNumber}</Text>
                              <Text style={sucursalStyle.negrita}>Atendiendo</Text>
                          </View >
                          <View style={[{maxWidth: 110}, sucursalStyle.colCard]}>
                              <SvgRelojArena width={28} style={{margin: 10}} />
                              <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center'}}>
                                <Text style={sucursalStyle.negrita}>{this.props.fila.timeToWaiting * (this.props.fila.lastNumberTaken - this.props.fila.currentAttendingNumber)} minutos</Text>
                                <Text style={sucursalStyle.simpleText}> de espera aprox.</Text>
                              </View>
                          </View>
                          <View style={[{maxWidth: 110}, sucursalStyle.colCard]}>
                              <Text style={[sucursalStyle.negrita, {fontSize: 30, marginBottom: 20}]} >{this.props.fila.description}{this.props.fila.numeroPedido}</Text>
                              <Text style={sucursalStyle.negrita}>Tu número</Text>
                          </View>
                      </View>
                      <View style={sucursalStyle.separadorSearch}/>
                      <View style={sucursalStyle.centrarSelf}>
                      {/*<PedirNumeroComponent type='reprogramar'
                            fila={this.state.fila} branch={this.props.branch}
                            navigation={this.props.navigation} title={'Reprogramar número'}
                            idQueue={this.props.fila._id} description={this.props.fila.description}
                            branchState={this.state.branchState} branchId={this.props.branch._id}
            />*/}
                    </View>
                </Card>

            )
        }
        else if (this.props.fila.solicitarNum == 2) {
            return (
                <Card containerStyle={{ borderRadius: 5, flexDirection: 'column', marginRight: '9px', marginLeft: 0, marginTop: 0, marginBottom: '9px', padding: 0, width: this.props.width , height: '304px' }} >
                    <Card.Title style={{ width: '100%', flex: 1, fontWeight: 'normal', paddingLeft: '17px', marginBottom: 0, marginLeft: 0, marginRight: 0, paddingTop: '15.27px', paddingBottom: '11.73px', textAlign: 'left'}}>
                      <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                        {this.props.fila.name.toUpperCase() == "CAJA" ? <SvgCaja width={26} height={isSafari && 26} /> : <SvgPersonas width={24} height={isSafari && 24} />}
                        <Text style={sucursalStyle.cabeceraCard}>  Atención en </Text><Text style={[sucursalStyle.cabeceraCardSucursal, {textTransform: 'capitalize'}]}>{this.props.fila.groupName}</Text>
                      </View>
                    </Card.Title>
                    
                    <View style={sucursalStyle.separadorSearch}/>
                      <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, alignSelf: 'center', paddingVertical: '12px'}}>
                          <View style={sucursalStyle.colCard}>
                              <SvgCalendarioAzul width={32} height={33} style={{marginBottom: '7.19px'}} />
                              <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center'}}>
                                <Text style={sucursalStyle.simpleText}>El día </Text>
                                <Text style={sucursalStyle.negrita}>{this.props.fila.dateToReservation}</Text>
                              </View>
                          </View >
                          <View style={sucursalStyle.colCard}>
                              <SvgRelojArena width={32} height={33} style={{marginBottom: '7.19px'}} />
                              <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center'}}>
                                <Text style={sucursalStyle.simpleText}>A las </Text>
                                <Text style={sucursalStyle.negrita}>{this.props.fila.hourReservation}</Text>
                              </View>
                          </View>
                          <View style={sucursalStyle.colCard}>
                              <SvgHora width={32} height={33} style={{marginBottom: '7.19px'}} />
                              <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center'}}>
                                <Text style={[sucursalStyle.negrita, {fontSize: 16}]} >R{this.props.fila.description}{this.props.fila.hourReservation.replace(':', '')}
                                    {this.props.fila.reservationType === 'grupal'? `-${this.props.fila.correlative}` : null}</Text>
                                <Text style={sucursalStyle.negrita}>Tu turno</Text>  
                              </View>
                          </View>
                      </View>
                      
                      <View style={sucursalStyle.separadorSearch}/>
                      <View style={[sucursalStyle.centrarSelf, {borderTopWidth: 0, borderBottomWidth: 0, borderRadius: 0,}]}>
                        <CancelarReservaComponentBlue title={'Cancelar Reserva'}
                            idReserva={this.props.fila.idReserva}
                            type={'cancelar'}
                            openCode={true}
                            navigation={this.props.navigation}
                            onGoBack={() => {}}
                        />
                        <Text>{' '}</Text>
                        <ReservarButtonComponent
                            type={'reprogramar'}
                            user={this.props.user}
                            branchState={this.state.branchState}
                            branchId={this.props.branch._id}
                            branchName={this.props.branch.name}
                            title={'Reagendar'}
                            openCode={true}
                            queueId={this.props.fila._id}
                            queueName={this.props.fila.name}
                            navigation={this.props.navigation}
                            description={this.props.fila.description}
                            queueMaxDays={this.props.fila.diasReserva}
                            idReserva={this.props.fila.idReserva}
                            branch={this.props.branch}
                            noGps={this.props.noGps}
                        />
                      </View>
                </Card>
            )
        }
        else {
            return (
                <Card containerStyle={{ borderRadius: 5, flexDirection: 'column', marginRight: '9px', marginLeft: 0, marginTop: 0, marginBottom: '9px', padding: 0, width: this.props.width , height: '134px' }} >
                {/*<Card containerStyle={{ borderRadius: 5, flexDirection: 'column', marginRight: '9px', marginLeft: 0, marginTop: 0, marginBottom: '9px', padding: 0, width: this.props.width, height: '304px' }} >*/}
                  <Card.Title style={{ width: '100%', flex: 1, fontWeight: 'normal', paddingLeft: '17px', marginBottom: 0, marginLeft: 0, marginRight: 0, paddingTop: '15.27px', paddingBottom: '11.73px', textAlign: 'left'}}>
                    <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                      {this.props.fila.name.toUpperCase() == "CAJA" ? <SvgCaja width={26} height={isSafari && 26} /> : <SvgPersonas width={24} height={isSafari && 24} />}
                      <Text style={sucursalStyle.cabeceraCard}>  Atención en </Text><Text style={[sucursalStyle.cabeceraCardSucursal, {textTransform: 'capitalize'}]}>{this.props.fila.groupName ? this.props.fila.groupName : this.props.fila.name}</Text>
                    </View>
                  </Card.Title>
                    
                    {/*<View style={sucursalStyle.separadorSearch}/>
                      <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, alignSelf: 'center', paddingVertical: '12px'}}>
                          <View style={sucursalStyle.colCard}>
                              <SvgFila width={32} height={33} style={{marginBottom: '7.19px'}} />
                              <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center'}}>
                                <Text style={sucursalStyle.negrita}>{this.props.fila.lastNumberTaken - this.props.fila.currentAttendingNumber} personas <Text style={sucursalStyle.simpleText}>en </Text></Text>
                                <Text style={sucursalStyle.simpleText}>la fila</Text>
                              </View>
                          </View >
                          <View style={sucursalStyle.colCard}>
                              <SvgRelojArena width={32} height={33} style={{marginBottom: '7.19px'}} />
                              <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center'}}>
                                <Text style={sucursalStyle.negrita}>{this.props.fila.timeToWaiting * (this.props.fila.lastNumberTaken - this.props.fila.currentAttendingNumber)} minutos <Text style={sucursalStyle.simpleText}>de</Text></Text>
                                <Text style={sucursalStyle.simpleText}>espera aprox.</Text>
                              </View>
                          </View>
                          <View style={sucursalStyle.colCard}>
                              <SvgHora width={32} height={33} style={{marginBottom: '7.19px'}} />
                              <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center'}}>
                                <Text style={sucursalStyle.negrita}>{this.props.fila.timeToWaiting} minutos <Text style={sucursalStyle.simpleText}>de</Text></Text>
                                <Text style={sucursalStyle.simpleText}>atención</Text>
                              </View>
                          </View>
                      </View>*/}
                      <View style={sucursalStyle.separadorSearch}/>
                      <View style={sucursalStyle.centrarSelf}>
                        {/*<PedirNumeroComponent
                            type={'pedir'}
                            branch={this.props.branch}
                            navigation={this.props.navigation}
                            branchId={this.props.branch._id}
                            title={'Pedir número'}
                            idQueue={this.props.fila._id}
                            description={this.props.fila.description}
                            branchState={this.state.branchState}
                            style={{maxWidth: '200px'}}
                        />*/}
                        {this.props.fila.reserva ?
                            <ReservarButtonComponent
                                type={'pedir'}
                                user={this.props.user}
                                groupName={this.props.fila.groupName}
                                branchState={this.state.branchState}
                                branchId={this.props.branch._id}
                                branchName={this.props.branch.name}
                                title={'Agendar hora'}
                                openCode={false}
                                queueId={this.props.fila._id}
                                queueName={this.props.fila.name}
                                navigation={this.props.navigation}
                                description={this.props.fila.description}
                                branch={this.props.branch}
                                style={{width: '100%'}}
                                queueMaxDays={this.props.fila.diasReserva}
                                noGps={this.props.noGps}
                            />
                            :
                            null
                        }
                    </View>
                </Card>

            )
        }
    }
}

export default FilaLayoutTurno
