import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import modalStyle from "../modalStyle";

export default function MiTurnoWebComponent(props) {
  return (
    <View style={(modalStyle.centerSelfCol, { alignItems: "center" })}>
      <Text style={[modalStyle.padBotom, { fontSize: 27 }]}>¡Es tu turno!</Text>
      <Text style={[modalStyle.tuTurno2, { fontSize: 27 }]}>
        {props.payload.reserva
          ? props.payload.description + props.payload.numeroPedido
          : props.payload.numeroPedido}
      </Text>

      <View>
        <View style={modalStyle.itemCenter}>
          <Text style={{ fontSize: 24 }}>{props.payload.fila}</Text>
          <Text style={{ fontSize: 27, alignSelf: "center" }}>
            {props.payload.module}
          </Text>
        </View>
      </View>
      <View footer style={modalStyle.selfCenter}>
        <TouchableOpacity
          onPress={() => props.navigation.goBack()}
          style={{
            backgroundColor: "#E81E2B",
            borderRadius: "4px",
            paddingHorizontal: 16,
          }}
        >
          <Text
            style={{
              fontFamily: "Ubuntu-Regular",
              color: "white",
              backgroundColor: "#e81e2b",
              paddingVertical: 7,
              paddingHorizontal: 20,
              borderRadius: 5,
              alignItems: "center",
              fontSize: 16,
            }}
          >
            Ok
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
