import * as React from 'react';
import Svg, { G, Path, Defs, Mask, ClipPath, Use } from "react-native-svg"


function SvgCaja(props) {
  
  return (
      
    <Svg
      id="sucursal_caja__Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 69 68"
      xmlSpace="preserve"
      enableBackground="new 0 0 69 68"
      {...props}
    >
      <G id="sucursal_caja__Artboard-Copy-7">
        <G id="sucursal_caja__Page-1" transform="translate(-1 -2)">
          <Path id="sucursal_caja__p1" fill="none" d="M-1 72h72V0H-1z" />
          <G id="sucursal_caja__Group-12">
            <Path
              id="sucursal_caja__p2"
              className="sucursal_caja__stp1"
              d="M11.4 20.2H5.8c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2H8V27c0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2v-2.4c2.6-.5 4.5-2.8 4.5-5.5 0-3.1-2.5-5.6-5.6-5.6H9.1c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1h5.6c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-2.2V4.5c0-1.2-1-2.2-2.2-2.2C9 2.2 8 3.3 8 4.5v2.4c-2.6.5-4.5 2.8-4.5 5.5C3.5 15.5 6 18 9.1 18h2.2c.6 0 1.1.5 1.1 1.1s-.4 1.1-1 1.1"
            />
            <Path
              id="sucursal_caja__p4"
              className="sucursal_caja__stp1"
              d="M48.5 54H14.8c-1.2 0-2.2-1-2.2-2.2V41.4l2.9 2.9c.9.9 2.3.9 3.2 0 .9-.9.9-2.3 0-3.2l-6.8-6.8c-.9-.9-2.3-.9-3.2 0l-6.8 6.8c-.9.9-.9 2.4 0 3.2.9.9 2.3.9 3.2 0L8 41.4v10.3c0 3.7 3 6.8 6.8 6.8h33.8c1.2 0 2.2-1 2.2-2.2S49.7 54 48.5 54"
            />
            <Path
              id="sucursal_caja__p6"
              className="sucursal_caja__stp1"
              d="M58.6 51.8h5.6c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2H62V45c0-1.2-1-2.2-2.2-2.2-1.2 0-2.2 1-2.2 2.2v2.4c-2.6.5-4.5 2.8-4.5 5.5 0 3.1 2.5 5.6 5.6 5.6h2.2c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1h-5.6c-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2h2.2v2.2c0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2v-2.4c2.6-.5 4.5-2.8 4.5-5.5 0-3.1-2.5-5.6-5.6-5.6h-2.2c-.6 0-1.1-.5-1.1-1.1s.5-.9 1.1-.9"
            />
            <Path
              id="sucursal_caja__p8"
              className="sucursal_caja__stp1"
              d="M68.1 27.7c-.9-.9-2.3-.9-3.2 0L62 30.6V20.2c0-3.7-3-6.8-6.8-6.8H21.5c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h33.8c1.2 0 2.2 1 2.2 2.2v10.3l-2.9-2.9c-.9-.9-2.3-.9-3.2 0-.9.9-.9 2.3 0 3.2l6.8 6.8c.9.9 2.3.9 3.2 0l6.8-6.8c.8-.6.8-2.1-.1-2.9"
            />
            <Defs></Defs>
            <Mask
              maskUnits="userSpaceOnUse"
              x={21.5}
              y={22.5}
              width={27}
              height={27}
              id="sucursal_caja__pmask-2_2_"
            >
              <G filter="url(#sucursal_caja__Adobe_OpacityMaskFilter)">
                <Path
                  id="sucursal_caja__ppath-1_2_"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#fff"
                  d="M-1 72h72V0H-1z"
                />
              </G>
            </Mask>
            <Path
              id="sucursal_caja__p10"
              mask="url(#sucursal_caja__mask-2_2_)"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#1bb5ec"
              d="M21.5 49.5h27v-27h-27z"
            />
          </G>
        </G>
      </G>
    </Svg>
    
  );
}


function SvgDia(props) {
  
  return (
    
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 20 20"
      {...props}
    >
      <Defs>
        <Path
          id="sucursal_dia__a"
          d="M17.5 1.8h-1.2v-.6c0-.3-.3-.6-.6-.6h-2.5c-.3 0-.6.3-.6.6v.6h-5v-.6c0-.3-.3-.6-.6-.6H4.4c-.4-.1-.6.2-.6.6v.6H2.5c-1 0-1.9.8-1.9 1.9v13.8c0 1 .8 1.9 1.9 1.9h15c1 0 1.9-.8 1.9-1.9V3.7c0-1.1-.9-1.9-1.9-1.9zM13.8 3H15V1.8h-1.2V3zM5 3h1.2V1.8H5V3zm-3.1.7c0-.4.3-.7.6-.7h1.2v.6c.1.4.3.7.7.7h2.5c.3 0 .6-.3.6-.6V3h5v.6c0 .3.3.6.6.6h2.5c.3 0 .6-.3.6-.6V3h1.2c.3 0 .6.3.6.6v1.9H1.9V3.7zm16.2 13.7c0 .3-.3.6-.6.6h-15c-.3 0-.6-.3-.6-.6V6.8h16.2v10.6z"
        />
      </Defs>
      <ClipPath id="sucursal_dia__b">
        <Use xlinkHref="#sucursal_dia__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#sucursal_dia__b)">
        <Defs>
          <Path id="sucursal_dia__c" d="M0-.1h20v20H0z" />
        </Defs>
        <ClipPath id="sucursal_dia__d">
          <Use xlinkHref="#sucursal_dia__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#sucursal_dia__d)"
          fill="#007db7"
          d="M-4.4-4.5h28.8v28.8H-4.4z"
        />
      </G>
    </Svg>
    
  );
}


function SvgDistancia(props) {
  
  return (
      
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      {...props}
    >
      <Defs>
        <Path
          id="sucursal_distancia__a"
          d="M3.4 8.4c.1-.1 2.1-2.2 2.1-5.3C5.5 1.7 4.4.5 3 .5S.5 1.7.5 3.1c0 3.1 2.1 5.2 2.1 5.3.2.1.6.1.8 0M3 1.5c.8 0 1.5.7 1.5 1.6 0 1.9-1 3.4-1.5 4.1-.5-.7-1.5-2.2-1.5-4.1 0-.9.7-1.6 1.5-1.6"
        />
      </Defs>
      <ClipPath id="sucursal_distancia__b">
        <Use xlinkHref="#sucursal_distancia__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#sucursal_distancia__b)">
        <Defs>
          <Path id="sucursal_distancia__c" d="M0 0h16v16H0z" />
        </Defs>
        <ClipPath id="sucursal_distancia__d">
          <Use xlinkHref="#sucursal_distancia__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#sucursal_distancia__d)"
          fill="#007db7"
          d="M-4.5-4.5h15v18h-15z"
        />
      </G>
      <Defs>
        <Path
          id="sucursal_distancia__e"
          d="M3.5 3c0-.3-.2-.5-.5-.5s-.5.2-.5.5.2.5.5.5.5-.2.5-.5"
        />
      </Defs>
      <ClipPath id="sucursal_distancia__f">
        <Use xlinkHref="#sucursal_distancia__e" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#sucursal_distancia__f)">
        <Defs>
          <Path id="sucursal_distancia__g" d="M0 0h16v16H0z" />
        </Defs>
        <ClipPath id="sucursal_distancia__h">
          <Use xlinkHref="#sucursal_distancia__g" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#sucursal_distancia__h)"
          fill="#007db7"
          d="M-2.5-2.5h11v11h-11z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="sucursal_distancia__i"
            d="M15.5 6.1c0-1.4-1.1-2.6-2.5-2.6s-2.5 1.2-2.5 2.6c0 3.1 2.1 5.2 2.1 5.3 1 .9 2.9-3 2.9-5.3M13 4.5c.8 0 1.5.7 1.5 1.6 0 1.9-1 3.4-1.5 4.1-.5-.7-1.5-2.2-1.5-4.1 0-.9.7-1.6 1.5-1.6"
          />
        </Defs>
        <ClipPath id="sucursal_distancia__j">
          <Use xlinkHref="#sucursal_distancia__i" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#sucursal_distancia__j)">
          <Defs>
            <Path id="sucursal_distancia__k" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="sucursal_distancia__l">
            <Use xlinkHref="#sucursal_distancia__k" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#sucursal_distancia__l)"
            fill="#007db7"
            d="M5.5-1.5h15v18h-15z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="sucursal_distancia__m"
            d="M13.5 6c0-.3-.2-.5-.5-.5s-.5.2-.5.5.2.5.5.5.5-.2.5-.5"
          />
        </Defs>
        <ClipPath id="sucursal_distancia__n">
          <Use xlinkHref="#sucursal_distancia__m" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#sucursal_distancia__n)">
          <Defs>
            <Path id="sucursal_distancia__o" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="sucursal_distancia__p">
            <Use xlinkHref="#sucursal_distancia__o" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#sucursal_distancia__p)"
            fill="#007db7"
            d="M7.5.5h11v11h-11z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="sucursal_distancia__q"
            d="M13 12.5c-.6 0-1.1.3-1.3.8l-7.2-2.7C4.3 10 3.7 9.5 3 9.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c.6 0 1.1-.3 1.3-.8l7.2 2.7c.2.7.7 1.1 1.4 1.1.8 0 1.5-.7 1.5-1.5s-.6-1.5-1.4-1.5m-10-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5m10 3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5"
          />
        </Defs>
        <ClipPath id="sucursal_distancia__r">
          <Use xlinkHref="#sucursal_distancia__q" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#sucursal_distancia__r)">
          <Defs>
            <Path id="sucursal_distancia__s" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="sucursal_distancia__t">
            <Use xlinkHref="#sucursal_distancia__s" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#sucursal_distancia__t)"
            fill="#007db7"
            d="M-3.5 4.5h23v16h-23z"
          />
        </G>
      </G>
    </Svg>
    
  );
}


function SvgMapaBlue(props) {
  
  return (
    
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      {...props}
    >
      <Defs>
        <Path
          id="sucursal_mapablue__a"
          d="M15.5 14.4l-2-8c-.1-.3-.3-.4-.6-.4-.3.1-.4.3-.4.6l.6 2.5-2.6 1.8L4.7 7H9c.3 0 .5-.2.5-.5S9.3 6 9 6H3c-.2 0-.4.1-.5.4l-2 8c-.1.3.2.6.5.6h14c.3 0 .6-.3.5-.6zM1.6 14l1.7-6.7 10 6.7H1.6zm9.8-2.5l2-1.3.8 3.2-2.8-1.9z"
        />
      </Defs>
      <ClipPath id="sucursal_mapablue__b">
        <Use xlinkHref="#sucursal_mapablue__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#sucursal_mapablue__b)">
        <Defs>
          <Path id="sucursal_mapablue__c" d="M0 0h16v16H0z" />
        </Defs>
        <ClipPath id="sucursal_mapablue__d">
          <Use xlinkHref="#sucursal_mapablue__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#sucursal_mapablue__d)"
          fill="#007db7"
          d="M-4.5 1h25v19h-25z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="sucursal_mapablue__e"
            d="M10.5 4.9v2.6c0 .3.2.5.5.5s.5-.2.5-.5V4.9c.9-.2 1.5-1 1.5-1.9 0-1.1-.9-2-2-2s-2 .9-2 2c0 .9.6 1.7 1.5 1.9M11 2c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1"
          />
        </Defs>
        <ClipPath id="sucursal_mapablue__f">
          <Use xlinkHref="#sucursal_mapablue__e" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#sucursal_mapablue__f)">
          <Defs>
            <Path id="sucursal_mapablue__g" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="sucursal_mapablue__h">
            <Use xlinkHref="#sucursal_mapablue__g" overflow="visible" />
          </ClipPath>
          <Path clipPath="url(#sucursal_mapablue__h)" fill="#007db7" d="M4-4h14v17H4z" />
        </G>
      </G>
    </Svg>
    
  );
}


function SvgPersonas(props) {
  
  return (
      
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 72" {...props}>
      <G fillRule="evenodd" clipRule="evenodd">
        <Path
          d="M67.7 72H2.3C1 72 0 71 0 69.7c0-19.3 15.7-35 35-35s35 15.7 35 35c0 1.3-1 2.3-2.3 2.3"
          fill="#1bb5ec"
        />
        <Path
          d="M4.8 67.3c1.2-15.6 14.3-28 30.2-28s29 12.4 30.2 28H4.8zM21 20.7c0-7.7 6.3-14 14-14s14 6.3 14 14-6.3 14-14 14-14-6.3-14-14zm24.3 15.5c5-3.3 8.4-9.1 8.4-15.6C53.7 10.4 45.3 2 35 2s-18.7 8.4-18.7 18.7c0 6.5 3.3 12.2 8.4 15.6C10.4 40.6 0 54 0 69.7 0 71 1 72 2.3 72h65.3c1.3 0 2.3-1 2.3-2.3C70 54 59.6 40.6 45.3 36.2z"
          fill="#364f6b"
        />
      </G>
    </Svg>
    
  );
}


function SvgReloj(props) {
  
  return (
      
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      {...props}
    >
      <Defs>
        <Path
          id="sucursal_reloj__a"
          d="M13.3 2.7C10.4-.2 5.6-.2 2.7 2.7c-2.9 2.9-2.9 7.7 0 10.6 2.9 2.9 7.7 2.9 10.6 0 2.9-2.9 2.9-7.7 0-10.6m-.7 9.9c-2.5 2.5-6.6 2.5-9.2 0-2.5-2.5-2.5-6.7 0-9.2C5.9.9 10 .9 12.6 3.4c2.5 2.5 2.5 6.7 0 9.2"
        />
      </Defs>
      <ClipPath id="sucursal_reloj__b">
        <Use xlinkHref="#sucursal_reloj__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#sucursal_reloj__b)">
        <Defs>
          <Path id="sucursal_reloj__c" d="M0 0h16v16H0z" />
        </Defs>
        <ClipPath id="sucursal_reloj__d">
          <Use xlinkHref="#sucursal_reloj__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#sucursal_reloj__d)"
          fill="#007db7"
          d="M-4.5-4.5h25v25h-25z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="sucursal_reloj__e"
            d="M8.5 7.8V3c0-.3-.2-.5-.5-.5s-.5.2-.5.5v5c0 .1.1.3.1.4l2 2c.2.2.5.2.7 0 .2-.2.2-.5 0-.7L8.5 7.8z"
          />
        </Defs>
        <ClipPath id="sucursal_reloj__f">
          <Use xlinkHref="#sucursal_reloj__e" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#sucursal_reloj__f)">
          <Defs>
            <Path id="sucursal_reloj__g" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="sucursal_reloj__h">
            <Use xlinkHref="#sucursal_reloj__g" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#sucursal_reloj__h)"
            fill="#007db7"
            d="M2.5-2.5h13v18h-13z"
          />
        </G>
      </G>
    </Svg>
    
  );
}


function SvgTelefono(props) {
  
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      {...props}
    >
      <Defs>
        <Path
          id="sucursal_telefono__a"
          d="M3.8 6.5c1.4 0 2.5-1.1 2.5-2.5 0-.6-.2-1.1-.5-1.5h4.5c-.4.4-.6.9-.6 1.5 0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5c0-1.6-1.5-2.5-2.2-2.5h-9c-.7 0-2.2.8-2.2 2.5 0 1.4 1.1 2.5 2.5 2.5M13.7 4c0 2-3 2-3 0 0-.8.7-1.5 1.5-1.5.9 0 1.5.7 1.5 1.5M3.8 2.5c.4 0 .8.2 1.1.4.2.3.4.7.4 1.1 0 2-3 2-3 0 0-.8.6-1.5 1.5-1.5"
        />
      </Defs>
      <ClipPath id="sucursal_telefono__b">
        <Use xlinkHref="#sucursal_telefono__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#sucursal_telefono__b)">
        <Defs>
          <Path id="sucursal_telefono__c" d="M0 0h16v16H0z" />
        </Defs>
        <ClipPath id="sucursal_telefono__d">
          <Use xlinkHref="#sucursal_telefono__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#sucursal_telefono__d)"
          fill="#007db7"
          d="M-3.7-3.5h23.5v15H-3.7z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="sucursal_telefono__e"
            d="M13.6 8.1l-3.3-.9-.9-1.5c-.1-.1-.2-.2-.4-.2H7c-.2 0-.3.1-.4.2l-.9 1.6-3.3.8C1.3 8.4.5 9.4.5 10.5V13c0 .8.7 1.5 1.5 1.5h12c.8 0 1.5-.7 1.5-1.5v-2.5c0-1.1-.8-2.1-1.9-2.4zm.9 4.9c0 .3-.2.5-.5.5H2c-.3 0-.5-.2-.5-.5v-2.5c0-.7.5-1.3 1.1-1.5l3.5-.9c.1 0 .2-.1.3-.2l.9-1.5h1.4L9.6 8c.1.1.2.2.3.2l3.5.9c.7.2 1.1.8 1.1 1.5V13z"
          />
        </Defs>
        <ClipPath id="sucursal_telefono__f">
          <Use xlinkHref="#sucursal_telefono__e" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#sucursal_telefono__f)">
          <Defs>
            <Path id="sucursal_telefono__g" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="sucursal_telefono__h">
            <Use xlinkHref="#sucursal_telefono__g" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#sucursal_telefono__h)"
            fill="#007db7"
            d="M-4.5.5h25v19h-25z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="sucursal_telefono__i"
            d="M8 8.5c-1.2 0-2.2 1-2.2 2.2S6.8 13 8 13s2.2-1 2.2-2.2-1-2.3-2.2-2.3M8 12c-.7 0-1.2-.6-1.2-1.2S7.3 9.5 8 9.5s1.2.6 1.2 1.2S8.7 12 8 12"
          />
        </Defs>
        <ClipPath id="sucursal_telefono__j">
          <Use xlinkHref="#sucursal_telefono__i" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#sucursal_telefono__j)">
          <Defs>
            <Path id="sucursal_telefono__k" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="sucursal_telefono__l">
            <Use xlinkHref="#sucursal_telefono__k" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#sucursal_telefono__l)"
            fill="#007db7"
            d="M.8 3.5h14.5V18H.8z"
          />
        </G>
      </G>
    </Svg>
    
  );
}


export {SvgCaja, SvgDia, SvgDistancia, SvgMapaBlue, SvgPersonas, SvgReloj, SvgTelefono}