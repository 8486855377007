import { getQueue } from '../../config/config'
import { getNumbers } from '../../config/config';

class misTurnosFunctions{


    async obtenerTurnos(applicantId){
        let responseTurnos = await fetch(getNumbers, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                applicant: applicantId
            })
        });
    
        return responseTurnos
    }
    
    async obtenerFila(queue){
        let response = await fetch(getQueue + queue, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
        return response
    }

    }
    
    export default new misTurnosFunctions();