import * as Crypto from "expo-crypto";
import { SECRET_KEY, SECRET_RUT, RSA_KEY } from "@env";

const NodeRSA = require("node-rsa");

class encryptConfig {
  async encriptar(thing, element) {
    var mensaje = element + "|" + thing;
    const encriptado = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA512,
      mensaje
    );

    return encriptado.toString();
  }

  rsaEncrypt(val) {
    const rsakey = new NodeRSA(RSA_KEY);
    return rsakey.encrypt(val, "base64");
  }

  rutEncrypt(rut) {
    var secret = new Date();

    var newRut = rut + "|" + secret;

    var key = SECRET_RUT;
    const Crypto = require("react-native-crypto-js");
    newRut = Crypto.AES.encrypt(newRut, key);
    return newRut.toString();
  }

  telefonoEncrypt(telefono) {
    var secret = new Date();

    var newTelefono = telefono + "|" + secret;

    var key = "phone";
    const Crypto = require("react-native-crypto-js");
    newTelefono = Crypto.AES.encrypt(newTelefono, key);
    return newTelefono.toString();
  }
  cancelarReservaEncrypt(data, tipo) {
    var newCancelarReserva = "";

    if (tipo === "cancelar") {
      newCancelarReserva =
        data.idReserva + "|" + data.applicantId + "|" + data.codigo;
    } else if (tipo === "reprogramar") {
      newCancelarReserva =
        data.idReserva +
        "|" +
        data.hourReservation +
        "|" +
        data.dateToReservation +
        "|" +
        data.branchId +
        "|" +
        data.queueId +
        "|" +
        data.queueName +
        "|" +
        data.branchName +
        "|" +
        data.applicantId +
        "|" +
        data.applicantName +
        "|" +
        data.applicantLastName +
        "|" +
        data.applicantEmail +
        "|" +
        data.applicantPhone +
        "|" +
        data.codigo +
        "|" +
        data.reservationType +
        "|" +
        data.description +
        "|" +
        data.comuna +
        "|" +
        data.address;
    } else {
      newCancelarReserva =
        data.branchId +
        "|" +
        data.hourReservation +
        "|" +
        data.dateToReservation +
        "|" +
        data.applicantId +
        "|" +
        data.queueId +
        "|" +
        data.queueName +
        "|" +
        data.branchName +
        "|" +
        data.applicantName +
        "|" +
        data.applicantLastName +
        "|" +
        data.applicantEmail +
        "|" +
        data.applicantPhone +
        "|" +
        data.reservationType +
        "|" +
        data.description +
        "|" +
        data.comuna +
        "|" +
        data.address;
    }
    newCancelarReserva = this.rsaEncrypt(newCancelarReserva);
    return newCancelarReserva;
  }
}

export default new encryptConfig();
