import { StyleSheet, Platform } from "react-native";
import * as Font from 'expo-font';


Font.loadAsync({
  'Ubuntu-Light': require('../../../assets/fonts/Ubuntu-Light.ttf'),
  'Ubuntu-Bold': require('../../../assets/fonts/Ubuntu-Bold.ttf'),
  'Ubuntu-Regular': require('../../../assets/fonts/Ubuntu-Regular.ttf'),
  'Ubuntu-Medium': require('../../../assets/fonts/Ubuntu-Medium.ttf'),
})


export default StyleSheet.create({
  card: {
  },
  card2: {
    alignSelf: 'center',
    alignItems: 'center',
    width:Platform.OS === 'web' ? '' : '100%',
    // height: 70,
  }
  ,
  cardHeader: { alignSelf: 'center', flexDirection: 'column' },
  branchName: {
    flex: 1,
    flexDirection: 'column',
    padding: 5,
  },
  branchAdress: {
    flex: 1,
    flexDirection: 'column',
  },
  queueName: {
    flex: 1,
    flexDirection: 'column',
    padding: 10,
  },
  cardBody: {
    flex: 1,
    flexDirection: 'column',
  },
  miTurno: {
    flex: 1, backgroundColor: '#333746', flexDirection: 'column', alignItems: 'center',
    borderRadius: 5, width: 100, height: 88
  },
  titulo:{ fontSize: 25, color: 'grey', alignSelf: 'center', textAlign:'center' },
  negrita:{ fontWeight: 'bold' },
  gris:{ color: 'grey' },
  itemsCenter:{ alignItems: 'center' },
  tuTurno:{
    backgroundColor: '#333746', padding: 10,
    flexDirection: 'column', alignItems: 'center',
    borderRadius: 5
  },
  letraAmarilla:{ color: '#f6f88c', fontWeight: 'bold' },
  colCard: { flex: 1, flexDirection: 'column', alignItems: 'center', marginHorizontal: 10 },
  centerSelf:{ alignSelf: 'center' },
  flexMarg:{ flexDirection: 'row', margin: 10 },
  atendiendo:{ fontSize: 30, fontWeight: 'bold' },
  noTurnos:{ fontSize: 25, color: 'grey', alignSelf: 'center', textAlign:'center' },
  tituloCabecera: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: 24
  },
  subtituloCabecera: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 16,
    marginTop: 15
  },
  textoSucursal: {
    fontFamily: 'Ubuntu-Medium',
    fontSize: 16
  },
  ticketSucursal: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 22
  },
  ticketTextoMediano: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 14
  },
  ticketNumero: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: 52,
  },
  ticketSeguimiento: {
    fontSize: 11,
    fontFamily: 'Ubuntu-Light'
  },
  ticketLittleTextHead: {
    fontSize: 12,
    fontFamily: 'Ubuntu-Medium'
  },
  ticketLittleText: {
    fontSize: 12,
    fontFamily: 'Ubuntu-Light'
  },
  ticketTextQueue: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: 22, 
    marginTop: 5
  },
  colCard: {  
    flex: 1, 
    width: 100,
    flexDirection: 'column', 
    flexWrap: 'wrap',
    alignItems: 'center',
    borderRadius: 5,
    borderWidth: 1,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 15,
    paddingBottom: 15,
    borderColor: '#f7f7f7',
    margin: 3,
    backgroundColor: '#FFF',
  },
  separadorSearch: {
    height: 1, 
    width: "100%", 
    backgroundColor: "#e1e8ee", 
    marginTop: 5, 
    marginBottom: 3,
  },
  botonAgendar: {
    fontFamily: 'Ubuntu-Regular',
    color: "white",
    backgroundColor: "#e81e2b", 
    paddingTop: 7, 
    paddingBottom: 7, 
    paddingLeft: 10, 
    paddingRight: 10, 
    borderRadius: 5,
    alignItems: "center",
    fontSize: 14
  },
  botonCancelarVolver: {
    fontFamily: 'Ubuntu-Medium',
    paddingTop: 7, 
    paddingBottom: 7, 
    paddingRight: 10, 
    borderRadius: 5,
    alignItems: "center",
    fontSize: 14,
    color: "#e81e2b"
  },
  barraTextoRojo: {
    fontSize: 15, 
    color: "#e81e2b",
    fontFamily: 'Ubuntu-Medium',
    marginLeft: 6
  },
  cuadroBlanco: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#e2e2e2",
    margin: 5
  },
  tituloMisTurnos: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: '24px',
    marginTop: '50px',
    marginLeft: '10px',
    marginBottom: '30px',
  },
  botonReagendar: {
    fontFamily: 'Ubuntu-Regular',
    color: "white",
    backgroundColor: "#e81e2b", 
    paddingVertical: 7,
    paddingHorizontal: 10,
    borderRadius: 5,
    alignItems: "center",
    fontSize: 16
  },
});