import { StyleSheet, Dimensions } from "react-native";
import * as Font from 'expo-font';

Font.loadAsync({
  'Ubuntu-Light': require('../../../assets/fonts/Ubuntu-Light.ttf'),
  'Ubuntu-Bold': require('../../../assets/fonts/Ubuntu-Bold.ttf'),
  'Ubuntu-Regular': require('../../../assets/fonts/Ubuntu-Regular.ttf'),
  'Ubuntu-Medium': require('../../../assets/fonts/Ubuntu-Medium.ttf'),
})

export default StyleSheet.create({

  centeredView: {
    flex: 1,
    backgroundColor: 'rgba(52, 52, 52, 0.3)',
    // flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width:'100%',
    // marginTop: 22
  },
  viewConfigModal: {
    borderRadius: 5,
    //width: 600,
    // marginTop: 22
  },
  modalView: {
    borderRadius: 5, shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.58,
    shadowRadius: 16.00,

    elevation: 24,
  },
  selfCenter: { alignSelf: 'center' },
  tuTurno: {
    backgroundColor: '#333746', padding: 10,
    flexDirection: 'column', alignItems: 'center',
    borderRadius: 5
  },
  itemCenter: { justifyContent: 'center', alignContent: 'center', width: "100%" },
  tituloAmarillo: { color: '#f6f88c', fontWeight: 'bold' },
  negrita: { fontWeight: 'bold' },
  centerSelfCol: {
    alignSelf: 'center', flexDirection: 'column'
  },
  padBotom: { paddingBottom: 12, fontWeight: 'bold' },
  padBotColGreen: { paddingBottom: 10, color: 'green', textAlign: 'center' },
  margHori: { marginHorizontal: 10, height: 35, width: 130, marginTop: 10},
  textCenter: { textAlign: 'center' },
  tituloModal: {
    marginTop: 16,
    fontFamily: 'Ubuntu-Bold', 
    fontSize: 24, 
    marginBottom: 8,
  },
  subtituloModal: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 14,
    marginBottom: 5
  },
  labelInput: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: 16,
    marginBottom: 10,
    paddingLeft: 10
  },
  tuTurno2: {
    backgroundColor: '#333746', padding: 10,
    flexDirection: 'column', alignSelf: 'center',
    borderRadius: 5, color: '#f6f88c', fontWeight: 'bold'
  },
  textoBlanco:{ 
    marginHorizontal: 1, 
    color: 'white', 
    fontSize: 14, 
    alignSelf: 'center', 
    fontFamily: 'Ubuntu-Medium' 
  },
  textoCeleste:{ 
    color: "#007db7",
    marginHorizontal: 1, 
    fontSize: 14, 
    alignSelf: 'center', 
    fontFamily: 'Ubuntu-Medium' 
  },
  modalFooter:{justifyContent:'space-around', alignSelf:'center', flexDirection:'row', flex:1, marginTop: 15},
  centeredModalView:{alignItems:'center'},
  buttonPedirNumero: {
    backgroundColor: '#007db7', 
    width: "100%",
    paddingTop: 13, 
    paddingBottom: 13, 
    paddingHorizontal: '41px',
    borderRadius: 30
  },
  cuadroBlanco: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#e2e2e2",
    margin: 5
  },
  buttonReservar: {
    borderColor: '#007db7',
    backgroundColor: '#FFF',
    //borderEndWidth: 1,
    borderWidth: 1,
    width: "100%", 
    paddingTop: 9, 
    paddingBottom: 14, 
    //paddingLeft: 10, 
    paddingRight: '41px', 
    //borderTopRightRadius: 30, 
    //borderBottomRightRadius: 30,
    //Lo de abajo se debe eliminar cuando se habiliten los turnos
    paddingLeft: '41px', 
    borderRadius: 30, 
    paddingLeft: '41px', 
  },
  buttonReagendar: {
    borderColor: '#007db7',
    backgroundColor: '#FFF',
    borderEndWidth: 1,
    width: "100%", 
    paddingTop: 9, 
    paddingBottom: 14, 
    paddingLeft: 10, 
    paddingRight: '41px', 
    borderTopRightRadius: 30, 
    borderBottomRightRadius: 30,
  },
  separadorSearch: {
    height: 1, 
    width: "100%", 
    backgroundColor: "#e1e8ee", 
    marginTop: 10, 
    marginBottom: 10,
  },
  containerAgendarVolver: { 
    width: "100%", 
    flexDirection: "row", 
    flexWrap: 'wrap',
    justifyContent: "space-between", 
    padding: 5, 
    marginBottom: 5
  },
  botonAgendar: {
    fontFamily: 'Ubuntu-Regular',
    color: "white",
    backgroundColor: "#e81e2b", 
    paddingVertical: 7,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    fontSize: 16
  },
  botonReagendar: {
    fontFamily: 'Ubuntu-Regular',
    color: "white",
    backgroundColor: "#e81e2b", 
    paddingVertical: 7,
    paddingHorizontal: 10,
    borderRadius: 5,
    alignItems: "center",
    fontSize: 16
  },
  botonCancelarVolver: {
    fontFamily: 'Ubuntu-Medium',
    paddingTop: 7, 
    paddingBottom: 7, 
    paddingRight: 20, 
    borderRadius: 5,
    alignItems: "center",
    fontSize: 16,
    color: "#e81e2b",
  },
  botonCancelar: {
    fontFamily: 'Ubuntu-Medium',
    paddingTop: 7, 
    paddingBottom: 7,  
    paddingLeft: 10, 
    paddingRight: 10, 
    borderRadius: 5,
    alignItems: "center",
    fontSize: 16,
    color: "#e81e2b",
    backgroundColor: '#FFF'
  },
  datosError: {
    color: '#e81e2b', 
    textAlign: 'center',
    fontFamily: 'Ubuntu-Regular',
    fontSize: 16,
  },
  fuenteInput: {
    fontFamily: 'Ubuntu-Light',
    width: "100%",
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 0, 
    height: 48,
    borderWidth: 1,
    borderRadius: 2,
  },
  negritaVerde: {
    fontFamily: 'Ubuntu-Bold',
    color: '#006b14',
    fontSize: 16,
  },
  textoVerde: {
    fontFamily: 'Ubuntu-Light',
    color: '#006b14',
    fontSize: 16,
  },
  textoSucursal: {
    fontFamily: 'Ubuntu-Medium',
    fontSize: 16
  },
  ticketSucursal: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 22
  },
  ticketTextoMediano: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 14
  },
  ticketNumero: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: 52,
  },
  ticketSeguimiento: {
    fontSize: 11,
    fontFamily: 'Ubuntu-Light'
  },
  ticketLittleTextHead: {
    fontSize: 12,
    fontFamily: 'Ubuntu-Medium'
  },
  ticketLittleText: {
    fontSize: 12,
    fontFamily: 'Ubuntu-Light'
  },
  ticketTextQueue: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: 22, 
    marginTop: 5
  },
  textoAzulBold: {
    color: '#007db7',
    fontFamily: 'Ubuntu-Bold',
  },
  textoAzul: {
    color: '#007db7',
    fontFamily: 'Ubuntu-Light'
  },
  disabledAgendar: {
    fontFamily: 'Ubuntu-Regular',
    color: "white",
    backgroundColor: "#aeaeae", 
    paddingVertical: 7,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    fontSize: 16
  },
  disabledCancelarVolver: {
    fontFamily: 'Ubuntu-Medium',
    paddingTop: 7, 
    paddingBottom: 7, 
    paddingRight: 20, 
    borderRadius: 5,
    alignItems: "center",
    fontSize: 16,
    color: "#aeaeae",
  },

})