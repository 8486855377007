import { AntDesign } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, ScrollView } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { ActivityIndicator } from 'react-native-paper';
import { useDispatch } from 'react-redux';
import FontAwesome from 'react-native-vector-icons/FontAwesome5';
import MediaQuery from "react-native-web-responsive";
import modalStyle from '../modalStyle';
import formularioFunctions from './newnumber.functions';
import { SvgTicket } from './newnumber.svgs';
import Moment from "moment";

export default function NewNumberComponent(props) {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const [newNumber, setNewNumber] = useState(null);

  useEffect(() => {
    formularioFunctions
      .pedirNumero(props.queueId, props.userData)
      .then((response) => {
        let numero = response.data;
        setNewNumber(numero);
        dispatch({ type: 'ADD_NUMBER', payload: numero });
        setIsReady(true);
      })
      .catch((error) => {
        alert('Error: No se pudo generar un nuevo número');
        props.navigation.goBack();
      });
  }, []);

  function seteaFechaHora(fechaHora){
    var reserva = Moment(fechaHora, ["DD-MM-YYYY HH:mm:ss"]).format("DD/MM/YYYY - HH:mm");
    
    return reserva + " hrs."
  }

  if (isReady) {
    return (
      <View>
        
        <MediaQuery minWidth={600}>
        <Text style={modalStyle.tituloModal}>Tu número de atención</Text>
        <View style={modalStyle.separadorSearch} />
        <ScrollView contentContainerStyle={{minHeight: 480}}>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            alignSelf: 'center',
            justifyContent: 'flex-start',
            backgroundColor: '#ccf1c1',
            borderRadius: 5,
            marginBottom: 10,
            padding: 15,
          }}
        >
          <Text style={modalStyle.negritaVerde}>
            <AntDesign
              name='checkcircle'
              size={16}
              style={{ marginBottom: 8 }}
              color='#006b14'
            />{' '}
            ¡Felicitaciones!{' '}
          </Text>
          <Text style={modalStyle.textoVerde}>
            {' '}
            Ya tienes un número en {newNumber.queueName}.
          </Text>
        </View>
        <View style={{ marginTop: 10, marginBottom: 10 }}>
          <SvgTicket style={{ alignSelf: 'center', position: 'fixed' }} width={330} />
            <View
              style={{
                top: 30,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text style={modalStyle.ticketSucursal}>
                  Sucursal {newNumber.queueBranch == 'Santiago Centro' ? 'Oficina Matriz 2' : newNumber.queueBranch}
                </Text>
                <Text style={modalStyle.ticketTextoMediano}>
                  {props.branchAddress}, {props.branchComuna}
                </Text>
                <Text style={{ fonSize: 14, marginTop: 40 }}>
                  Número de atención
                </Text>
                <Text style={modalStyle.ticketNumero}>
                  {newNumber.description}
                  {newNumber.number}
                </Text>
                <Text style={modalStyle.ticketSeguimiento}>
                  Cód. Seguimiento: {newNumber.codigo}
                </Text>
                <Text style={modalStyle.ticketTextQueue}>
                  {newNumber.queueName}
                </Text>
              </View>
              <View style={{ alignItems: 'center', marginTop: 20 }}>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ alignItems: 'center', marginRight: 25 }}>
                    <Text style={modalStyle.ticketLittleTextHead}>EMISIÓN</Text>
                    <Text style={modalStyle.ticketLittleText}>
                      {seteaFechaHora(newNumber.requestTime)}
                    </Text>
                  </View>
                  <View style={{ alignItems: 'center', marginLeft: 25 }}>
                    <Text style={modalStyle.ticketLittleTextHead}>ATENCIÓN</Text>
                    <Text style={modalStyle.ticketLittleText}>
                      En {newNumber.timeToWaiting * (newNumber.number - newNumber.currentAttendingNumber)} minutos aprox.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          </ScrollView>
        </MediaQuery>
        
        <MediaQuery maxWidth={599}>
        <Text style={[modalStyle.tituloModal, {fontSize: 20}]}>Tu número de atención</Text>
        <View style={modalStyle.separadorSearch} />
        <ScrollView contentContainerStyle={{maxHeight: 330}}>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            alignSelf: 'center',
            justifyContent: 'flex-start',
            backgroundColor: '#ccf1c1',
            borderRadius: 5,
            marginBottom: 10,
            padding: 15,
          }}
        >
          <Text style={modalStyle.negritaVerde}>
            <AntDesign
              name='checkcircle'
              size={16}
              style={{ marginBottom: 8 }}
              color='#006b14'
            />{' '}
            ¡Felicitaciones!{' '}
          </Text>
          <Text style={modalStyle.textoVerde}>
            {' '}
            Ya tienes un número en {newNumber.queueName}.
          </Text>
        </View>
        <View style={{ marginTop: 10, marginBottom: 10 }}>
          <SvgTicket style={{ alignSelf: 'center', position: 'fixed' }} width={270} />
            <View
              style={{
                top: 15,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text style={[modalStyle.ticketSucursal, {fontSize: 18}]}>
                  Sucursal {newNumber.queueBranch == 'Santiago Centro' ? 'Oficina Matriz 2' : newNumber.queueBranch }
                </Text>
                <Text style={[modalStyle.ticketTextoMediano, {fontSize: 12}]}>
                  {props.branchAddress}, {props.branchComuna}
                </Text>
                <Text style={{ fonSize: 14, marginTop: 40 }}>
                  Número de atención
                </Text>
                <Text style={modalStyle.ticketNumero}>
                  {newNumber.description}
                  {newNumber.number}
                </Text>
                <Text style={modalStyle.ticketSeguimiento}>
                  Cód. Seguimiento: {newNumber.codigo}
                </Text>
                <Text style={modalStyle.ticketTextQueue}>
                  {newNumber.queueName}
                </Text>
              </View>
              <View style={{ alignItems: 'center', marginTop: 20, width: '100%' }}>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ alignItems: 'center', marginRight: 5 }}>
                    <Text style={modalStyle.ticketLittleTextHead}>EMISIÓN</Text>
                    <Text style={modalStyle.ticketLittleText}>
                      {seteaFechaHora(newNumber.requestTime)}
                    </Text>
                  </View>
                  <View style={{ alignItems: 'center', marginLeft: 5 }}>
                    <Text style={modalStyle.ticketLittleTextHead}>ATENCIÓN</Text>
                    <Text style={modalStyle.ticketLittleText}>
                      En {newNumber.timeToWaiting * (newNumber.number - newNumber.currentAttendingNumber)} minutos aprox.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          </ScrollView>
        </MediaQuery>
        
        <View style={modalStyle.separadorSearch} />

        <View style={modalStyle.containerAgendarVolver}>
          <TouchableOpacity
            style={[modalStyle.botonCancelarVolver, {paddingRight: 0}]}
            onPress={() => props.navigation.goBack()}
          >
            <Text style={modalStyle.botonCancelarVolver}>
              <FontAwesome
                name='chevron-left'
                style={{ fontSize: 16, marginRight: 8 }}
              />{' '}
              Volver
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[modalStyle.botonAgendar, {paddingRight: 5, paddingLeft: 5}]}
            onPress={() => {
              props.onOk()
              props.navigation.navigate('SeguimientoTurnos')
            }}
          >
            <Text style={modalStyle.botonAgendar}>
              Ver mis turnos
            </Text>
          </TouchableOpacity>
        </View>
        {/*<Text style={[modalStyle.padBotom, { fontSize: 21 }]}>Solicitud de Número</Text>
        <Text style={modalStyle.padBotColGreen}><AntDesign name="checkcircleo" size={24} color="green" /> Has reservado exitosamente tu turno en</Text>
        <Text style={{ fontSize: 24, alignItems: 'center' }}>{newNumber.queueBranch}</Text>
        <Text style={[modalStyle.negrita, { fontSize: 27, alignItems: 'center' }]}>{newNumber.queueName}</Text>
        <View>
          <Text>Código Seguimiento:</Text>
          <Text style={[modalStyle.padBotom, { fontSize: 21 }]}>{newNumber.codigo}</Text>
        </View>
        <View style={{ alignItems: 'center' }}>
          <View style={modalStyle.tuTurno, { marginBottom: 10, backgroundColor: '#333746', borderRadius: 10, padding: 10 }}>
            <Text style={modalStyle.tituloAmarillo}>Tu Número</Text>
            <Text style={[modalStyle.tituloAmarillo, { fontSize: 27, alignSelf: 'center' }]}>{newNumber.description}{newNumber.number}</Text>
          </View>
        </View>
        <View footer style={modalStyle.selfCenter}>
          <Button success
            onPress={() => props.navigation.navigate('Sucursal')}
            title="Ok"
            titleStyle={modalStyle.textoBlanco}
            buttonStyle={{ backgroundColor: 'green', paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}
          />
    </View>*/}
      </View>
    );
  } else {
    return <ActivityIndicator />;
  }
}
