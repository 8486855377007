import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { Text, View, TouchableOpacity } from "react-native";
import FontAwesome from "react-native-vector-icons/FontAwesome5";
import Icon from "react-native-vector-icons/MaterialIcons";
import branchStateFunction from "../Utils/branchState.function";
import MapStyle from "./map.style";
import { ViewBase } from "react-native";
import mapStyle from "./map.style";
import { SvgDistancia, SvgReloj, SvgTelefono } from "./map.svgs";
import inicioFunctions from "../Inicio/inicio.functions";

const defaultMapOptions = {
  disableDefaultUI: true,
};

const Map = (props) => {
  const [selectedPark, setSelectedPark] = useState(null);
  const [branchState, setBranchState] = useState(false);

  useEffect(() => {
    if (props.selectedMarker) {
      setSelectedPark(props.selectedMarker);
    }
    if (selectedPark) {
      setBranchState(branchStateFunction(selectedPark.horarios,selectedPark.name));
    }
  }, [selectedPark, props.selectedMarker]);

  return (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={
        props.selectedMarker
          ? props.center
          : selectedPark
          ? { lat: selectedPark.lat, lng: selectedPark.lng }
          : props.center
      }
      clickableIcons={false}
      center={
        props.selectedMarker
          ? props.center
          : selectedPark
          ? { lat: selectedPark.lat, lng: selectedPark.lng }
          : props.center
      }
      defaultOptions={defaultMapOptions}
    >
      {props.dataSuc &&
        props.dataSuc.branches &&
        props.dataSuc.branches.map((branches) => {
          return (
            <Marker
              key={branches._id}
              position={{ lat: branches.lat, lng: branches.long }}
              draggable={false}
              icon={require("../../../assets/gpsCoopeuch.png")}
              onClick={() => {
                props.toggleSelected();
                setSelectedPark(branches);
              }}
            ></Marker>
          );
        })}
      {selectedPark &&
        (!props.isListVisible ? (
          <InfoWindow
            onCloseClick={() => {
              props.toggleSelected();
              setSelectedPark(null);
            }}
            position={{
              lat: selectedPark.lat + 0.0025,
              lng: selectedPark.long,
            }}
          >
            <View>
              <View style={MapStyle.cardHeader}>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={MapStyle.branchName}>
                    <Text style={MapStyle.textSucursalPrevio}>Sucursal </Text>
                    <Text style={MapStyle.textSucursal}>
                      {selectedPark.name == 'Santiago Centro' ? 'Oficina Matriz 2' : selectedPark.name}
                    </Text>

                    {branchState &&
                    !inicioFunctions.isTodayHoliday(selectedPark.feriados) ? (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: 8,
                        }}
                      >
                        <Text style={mapStyle.branchStateOpen}>Abierto</Text>
                      </View>
                    ) : (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: 8,
                        }}
                      >
                        <Text style={mapStyle.branchStateClose}>Cerrado</Text>
                      </View>
                    )}
                  </View>
                </View>
                <View style={MapStyle.branchAdress}>
                  <Text
                    style={[
                      MapStyle.textInformationCard,
                      { textTransform: "capitalize" },
                    ]}
                  >
                    {selectedPark.address}, {selectedPark.comuna}
                  </Text>
                </View>

                <View style={mapStyle.separadorInfoWindow} />

                {/* <View style={MapStyle.queueName}>
                  <SvgTelefono width={16} />
                  <Text style={MapStyle.textTagsCard}> Teléfono: </Text>
                  <Text style={MapStyle.textInformationCard}>
                    {' '}
                    {selectedPark.phone}
                  </Text>
                </View> */}
                {selectedPark.horarios && (
                  <View style={{ alignSelf: "flex-start" }}>
                    <View
                      style={{
                        display: "table-row",
                        flexWrap: "wrap",
                        width: "100%",
                        paddingRight: "10px",
                      }}
                    >
                      <SvgReloj width={16} height={16} />
                      <Text style={MapStyle.textTagsCard}>
                        {" "}
                        Horarios de Atenci&oacute;n:{" "}
                      </Text>
                      {selectedPark.horarios.map((horarios) => {
                        return horarios.inicioBreak !== "Sin Break" ? (
                          <Text
                            key={horarios._id}
                            style={MapStyle.textInformationCard}
                          >
                            {horarios.horarioFormato} de {horarios.horaApertura}{" "}
                            Hrs a {horarios.inicioBreak} Hrs y{" "}
                            {horarios.terminoBreak} Hrs a {horarios.horaCierre}{" "}
                            Hrs.{" "}
                          </Text>
                        ) : (
                          <Text
                            key={horarios._id}
                            style={MapStyle.textInformationCard}
                          >
                            {horarios.horarioFormato} de {horarios.horaApertura}{" "}
                            Hrs a {horarios.horaCierre} Hrs.
                          </Text>
                        );
                      })}
                    </View>
                  </View>
                )}
                {!props.locationError && (
                  <View
                    style={{
                      display: "table-row",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <SvgDistancia width={16} />
                    <Text style={MapStyle.textTagsCard}> Distancia: </Text>
                    <Text style={MapStyle.textInformationCard}>
                      {selectedPark.distance} Km.
                    </Text>
                  </View>
                )}
              </View>
              <View style={mapStyle.separadorInfoWindow} />
              <View
                style={
                  (MapStyle.cardBody,
                  { alignContent: "center", alignItems: "center" })
                }
              >
                <TouchableOpacity
                  style={MapStyle.botonAgendar}
                  onPress={() =>
                    props.navigation.navigate("Sucursal", {
                      branch: selectedPark,
                      user: props.userData,
                      noGps: props.locationError,
                    })
                  }
                >
                  <Text style={MapStyle.textoBotonAgendar}>Agendar Hora</Text>
                </TouchableOpacity>
              </View>
            </View>
          </InfoWindow>
        ) : (
          <InfoWindow
            onCloseClick={() => {
              props.toggleSelected();
              setSelectedPark(null);
            }}
            position={{
              lat: selectedPark.lat + 0.0025,
              lng: selectedPark.long,
            }}
          >
            <View>
              <View style={MapStyle.cardHeader}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <View style={MapStyle.branchName}>
                    <Text style={MapStyle.textSucursalPrevio}>Sucursal </Text>
                    <Text style={MapStyle.textSucursal}>
                      {selectedPark.name == 'Santiago Centro' ? 'Oficina Matriz 2' : selectedPark.name}
                    </Text>
                  </View>
                </View>
                <View style={MapStyle.branchAdress}>
                  <Text
                    style={[
                      MapStyle.textInformationCard,
                      { textTransform: "capitalize" },
                    ]}
                  >
                    {selectedPark.address}, {selectedPark.comuna}
                  </Text>
                </View>
              </View>
            </View>
          </InfoWindow>
        ))}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Map));
