import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import swiperHelperStyle from "./swiperHelper.style";
import MediaQuery from "react-native-web-responsive";

function Slice4(props) {
  return (
    <View style={swiperHelperStyle.slide3}>
      <View
        style={{
          flex: 1,
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-end",
          marginBottom: "6px",
        }}
      >
        <Image
          style={{ height: 150, width: 150, marginVertical: 20 }}
          source={require("../../../assets/slider3.png")}
        />
        <Text
          style={{
            color: "#383638",
            fontSize: 18,
            fontFamily: "Ubuntu-Bold",
            marginBottom: "4%",
            textAlign: "center",
          }}
        >
          Revisa tus agendamientos en nuestra plataforma web
        </Text>

        <Text
          style={{
            textAlign: "center",
            color: "#383638",
            fontSize: 14,
            fontFamily: "Ubuntu-Regular",
            lineHeight: 20,
          }}
        >
          Podrás revisar tus números de atención agendados, así como también 
          el código de reserva que recibiste en tu correo.
        </Text>

        <MediaQuery minWidth={1224}>
          <View
            style={{
              marginTop: "60px",
              height: 1,
              width: "100%",
              backgroundColor: "#e1e8ee",
            }}
          />
        </MediaQuery>
        <MediaQuery maxWidth={1223}>
          <View
            style={{
              marginTop: "10px",
              height: 1,
              width: "100%",
              backgroundColor: "#e1e8ee",
            }}
          />
        </MediaQuery>
      </View>
      <MediaQuery minWidth={1224}>
        <TouchableOpacity
          style={{
            borderRadius: "4px",
            backgroundColor: "#E81E2B",
            paddingHorizontal: "24px",
            paddingVertical: "12px",
          }}
          onPress={() => {
            props.navigation.goBack();
          }}
        >
          <Text
            style={{
              color: "#FFFFFF",
              fontSize: 14,
              fontFamily: "Ubuntu-Medium",
              lineHeight: 20,
              paddingHorizontal: 20,
            }}
          >
            Entendido
          </Text>
        </TouchableOpacity>
      </MediaQuery>
      <MediaQuery maxWidth={1223}>
        <TouchableOpacity
          style={{
            borderRadius: "4px",
            backgroundColor: "#E81E2B",
            paddingHorizontal: "14px",
            paddingVertical: "8px",
          }}
          onPress={() => {
            props.navigation.goBack();
          }}
        >
          <Text
            style={{
              color: "#FFFFFF",
              fontSize: 14,
              fontFamily: "Ubuntu-Medium",
              lineHeight: 20,
              paddingHorizontal: 20,
            }}
          >
            Entendido
          </Text>
        </TouchableOpacity>
      </MediaQuery>
    </View>
  );
}

export default Slice4;
