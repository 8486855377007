import axios from 'axios';
import { getNewNumber } from '../../../config/config';

class newNumberFunctions {

    async pedirNumero(queueId, userData) {
        return await axios.post(getNewNumber + queueId + '/' + null,
            {
                idUser: userData.rut,
                source: 'App',
                applicantEmail: userData.correo,
                applicantPhone: userData.telefono,
                applicantName: userData.nombre,
                applicantLastName: userData.apellido,
                codigo: userData.codigo
            })
    }
}

export default new newNumberFunctions()