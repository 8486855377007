import dayToString from './dayToString.function'
import {SUCURSALES_MAGALLANES} from '@env'
import moment from 'moment'
export default function branchState(horarios, nameSucursal) {

    for (let horario of horarios) {
        for (let day of horario.dias) {
            if (day === dayToString(new Date())) {

                let cierre = new Date()
                let apertura = new Date()
                let horaCierre = horario.horaCierre.split(':')
                let horaMagallanes = new Date().toLocaleTimeString('es-CL',{timeZone: "America/Punta_Arenas"})
                let horaChile = new Date().toLocaleTimeString('es-CL',{timeZone: "America/Santiago"})
                
                let horaApertura = horario.horaApertura.split(':')
                cierre = cierre.setHours(horaCierre[0], horaCierre[1])
                apertura = apertura.setHours(horaApertura[0], horaApertura[1])
                let arrayBranchesMagallanes = SUCURSALES_MAGALLANES.split(',')
                let horaActual = new Date()
                console.log(parseInt(horaActual.toLocaleTimeString([],{hour:'2-digit',minute:'2-digit',second:'2-digit'}).replaceAll(":","")),parseInt(horaMagallanes.replaceAll(":","")),parseInt(horaActual.toLocaleTimeString({hour:'2-digit',minute:'2-digit',second:'2-digit'}).replaceAll(":",""))<parseInt(horaMagallanes.replaceAll(":","")))
                if(parseInt(horaChile.replaceAll(':',''))<parseInt(horaMagallanes.replaceAll(':','')) && parseInt(horaActual.toLocaleTimeString().replaceAll(':',''))<parseInt(horaMagallanes.replaceAll(':','')) && arrayBranchesMagallanes.filter(sucursal => sucursal===nameSucursal).length!==0){
                    console.log("horario distinto, punta arenas")
                    cierre= cierre-3600000
                    apertura = apertura-3600000
                    
                }
                console.log("apertura, cierre", apertura, cierre)
                
                if (horaActual < new Date(apertura) || horaActual > new Date(cierre)) {
                    return false
                } else {
                    return true
                }
            }
        }
    }
}