import React from 'react';
import { Text, View } from 'react-native';
import { Button } from 'react-native-elements';
import modalStyle from '../modalStyle';

export default function ClosedBranchAdviceWebComponent(props) {

  return (
    <View header style={modalStyle.centerSelfCol}>
      <Text style={[modalStyle.padBotom, { textAlign: 'center', fontSize: 24 }]}>Sucursal se encuentra cerrada</Text>

      <View footer style={modalStyle.selfCenter}>
        <Button bordered success onPress={() => { props.navigation.navigate('Inicio') }}
          title="Ok"
          buttonStyle={[modalStyle.margHori, { backgroundColor: '#F2274C' }]}
          titleStyle={modalStyle.textoBlanco}
        />
      </View>
    </View>
  )
}
