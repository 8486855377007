import React, { useState, useEffect } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Swiper from "react-native-web-swiper";
import Slice1 from "./slice1.layout";
import Slice2 from "./slice2.layout";
import Slice3 from "./slice3.layout";
import Slice4 from "./slice4.layout";
import swiperHelperStyle from "./swiperHelper.style";
import FontAwesome from "react-native-vector-icons/FontAwesome5";
import MediaQuery from "react-native-web-responsive";

function SwiperHelperWeb(props) {
  const [state, setState] = useState({ showPaginate: true });
  useEffect(() => {
    props.navigation.setOptions({
      title: '¿Cómo agendar tu hora?',
    });
  }, [ props.navigation]);
  function indexChanged(index) {
    if (index === 2) {
      setState({ showPaginate: false });
    } else {
      setState({ showPaginate: true });
    }
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "rgba(52, 52, 52, 0.3)",
      }}
    >
      <MediaQuery minWidth={1224}>
        <View
          style={{
            alignSelf: "center",
            flex: 1,
            marginTop: 30,
            backgroundColor: "white",
            borderRadius: 20,
            padding: 35,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
            width: 600,
            maxHeight: "546px",
          }}
        >
          <View>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontFamily: "Ubuntu-Bold",
                  fontSize: 24,
                  lineHeight: 30,
                }}
              >
                ¿Cómo agendar tu hora?
              </Text>
              <TouchableOpacity onPress={() => props.navigation.goBack()}>
                <Text>
                  <FontAwesome
                    name="times"
                    style={{ fontSize: 18, color: "#BBBCBF" }}
                  />
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                marginTop: "10px",
                height: 1,
                width: "100%",
                backgroundColor: "#e1e8ee",
              }}
            />
          </View>

          <Swiper
            containerStyle={swiperHelperStyle.wrapper}
            activeDotColor={"#007DB7"}
            from={0}
            minDistanceForAction={0.1}
            onIndexChanged={(index) => indexChanged(index)}
            controlsProps={{
              dotsTouchable: true,
              prevPos: "bottom-left",
              nextPos: "bottom-right",
              DotComponent: state.showPaginate
                ? ({ index, isActive, onPress }) => (
                    <View
                      style={{
                        borderRadius: 999,
                        backgroundColor: isActive ? "#007DB7" : "#D0CED4",
                        height: "18px",
                        width: "18px",
                        marginRight: index !== 2 ? 15 : 0,
                      }}
                      onPress={onPress}
                    ></View>
                  )
                : () => <View></View>,
              PrevComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <View style={{ flexDirection: "row" }}>
                    <Text>
                      <FontAwesome
                        name="chevron-left"
                        style={{
                          fontSize: 18,
                          marginRight: 6,
                          color: "#EC1B2B",
                        }}
                      />
                    </Text>
                    <Text
                      style={{
                        color: "#EC1B2B",
                        fontSize: 16,
                        fontFamily: "Ubuntu-Medium",
                      }}
                    >
                      Anterior
                    </Text>
                  </View>
                </TouchableOpacity>
              ),
              NextComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={{
                        color: "#EC1B2B",
                        fontSize: 16,
                        fontFamily: "Ubuntu-Medium",
                        marginRight: 6,
                      }}
                    >
                      Siguiente
                    </Text>
                    <FontAwesome
                      name="chevron-right"
                      style={{ fontSize: 18, color: "#EC1B2B" }}
                    />
                  </View>
                </TouchableOpacity>
              ),
            }}
          >
            <Slice1></Slice1>
            <Slice2></Slice2>
            {/*           <Slice3></Slice3> */}
            <Slice4 navigation={props.navigation}></Slice4>
          </Swiper>
        </View>
      </MediaQuery>

      <MediaQuery maxWidth={1223}>
        <View
          style={{
            alignSelf: "center",
            flex: 1,
            marginTop: 10,
            backgroundColor: "white",
            borderRadius: 20,
            padding: 14,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
            width: 300,
            maxHeight: "500px",
          }}
        >
          <View>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontFamily: "Ubuntu-Bold",
                  fontSize: 20,
                  lineHeight: 22,
                }}
              >
                ¿Cómo agendar tu hora?
              </Text>
              <TouchableOpacity onPress={() => props.navigation.goBack()}>
                <Text>
                  <FontAwesome
                    name="times"
                    style={{ fontSize: 18, color: "#BBBCBF" }}
                  />
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                marginTop: "10px",
                height: 1,
                width: "100%",
                backgroundColor: "#e1e8ee",
              }}
            />
          </View>

          <Swiper
            containerStyle={swiperHelperStyle.wrapper}
            activeDotColor={"#007DB7"}
            from={0}
            minDistanceForAction={0.1}
            onIndexChanged={(index) => indexChanged(index)}
            controlsProps={{
              dotsTouchable: true,
              prevPos: "bottom-left",
              nextPos: "bottom-right",
              DotComponent: state.showPaginate
                ? ({ index, isActive, onPress }) => (
                    <View
                      style={{
                        borderRadius: 999,
                        backgroundColor: isActive ? "#007DB7" : "#D0CED4",
                        height: "12px",
                        width: "12px",
                        marginRight: index !== 2 ? 15 : 0,
                      }}
                      onPress={onPress}
                    ></View>
                  )
                : () => <View></View>,
              PrevComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <View style={{ flexDirection: "row" }}>
                    <Text>
                      <FontAwesome
                        name="chevron-left"
                        style={{
                          fontSize: 16,
                          color: "#EC1B2B",
                        }}
                      />
                    </Text>
                  </View>
                </TouchableOpacity>
              ),
              NextComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <View style={{ flexDirection: "row" }}>
                    <FontAwesome
                      name="chevron-right"
                      style={{ fontSize: 16, color: "#EC1B2B" }}
                    />
                  </View>
                </TouchableOpacity>
              ),
            }}
          >
            <Slice1></Slice1>
            <Slice2></Slice2>
            {/*           <Slice3></Slice3> */}
            <Slice4 navigation={props.navigation}></Slice4>
          </Swiper>
        </View>
      </MediaQuery>
    </View>
  );
}

export default SwiperHelperWeb;
