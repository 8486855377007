import * as React from 'react';
import Svg, { G, Path, Defs, Mask, ClipPath, Use } from "react-native-svg"
 
function SvgTicket(props) {
  
    return (
        
      <Svg id="misTurnos_ticket__n_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 334 414" xmlSpace="preserve" {...props} >
      <style>
        {".misTurnos_ticket__sn3{fill-rule:evenodd;clip-rule:evenodd;fill:#e4e4e6}"}
      </style>
      <g id="misTurnos_ticket__Artboard-Copy-15">
        <g id="misTurnos_ticket__TICKET" transform="translate(7 7)">
          <g id="misTurnos_ticket__Combined-Shape">
            <path
              id="misTurnos_ticket__path-1"
              d="M320 0v71c-4.4 0-8 3.6-8 8s3.6 8 8 8v313h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8h-8c0-4.4-3.6-8-8-8s-8 3.6-8 8H0V87c4.4 0 8-3.6 8-8s-3.6-8-8-8V0h320z"
              fillRule="evenodd"
              clipRule="evenodd"
            />
            <path
              d="M319.5.5v70c-2.1.1-4.1 1-5.5 2.5-1.5 1.5-2.5 3.7-2.5 6s1 4.5 2.5 6c1.4 1.4 3.4 2.4 5.5 2.5h0v312h-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7c-.1-2.1-1-4.1-2.5-5.5-1.5-1.5-3.7-2.5-6-2.5s-4.5 1-6 2.5c-1.4 1.4-2.4 3.4-2.5 5.5h0-7v-312c2.1-.1 4.1-1 5.5-2.5 1.5-1.5 2.5-3.7 2.5-6s-1-4.5-2.5-6c-1.4-1.4-3.4-2.4-5.5-2.5h0V.5h319z"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#fff"
              stroke="#e9e9e9"
            />
          </g>
          <path
            id="misTurnos_ticket__Line"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="square"
            strokeDasharray={6}
            d="M10 79.5h301.5"
          />
          <g id="misTurnos_ticket__SELLO-MOBILE" transform="translate(108 280)">
            <g id="misTurnos_ticket__SELLO" transform="translate(12 8)">
              <path
                id="misTurnos_ticket__CIRCULO-EXTERNO"
                className="misTurnos_ticket__sn3"
                d="M40 1C18.5 1 1 18.5 1 40s17.5 39 39 39 39-17.5 39-39S61.5 1 40 1m0 79C17.9 80 0 62.1 0 40S17.9 0 40 0s40 17.9 40 40-17.9 40-40 40"
              />
              <path
                id="misTurnos_ticket__CIRCULO-INTERNO"
                className="misTurnos_ticket__sn3"
                d="M40.5 15C26.5 15 15 26.5 15 40.5S26.5 66 40.5 66 66 54.5 66 40.5 54.5 15 40.5 15m0 52C25.9 67 14 55.1 14 40.5S25.9 14 40.5 14 67 25.9 67 40.5 55.1 67 40.5 67"
              />
              <g id="misTurnos_ticket__ISO-COOPEUCH" transform="translate(26 25)">
                <path
                  id="misTurnos_ticket__ISO-2"
                  className="misTurnos_ticket__sn3"
                  d="M28.3 12.7c-8.4.3-15.1 7-15.4 15.3 0 .3.2.6.6.6h12.1c1.9 0 3.4-1.5 3.4-3.4v-12c-.2-.2-.4-.5-.7-.5"
                />
                <path
                  id="misTurnos_ticket__ISO-1"
                  className="misTurnos_ticket__sn3"
                  d="M25.5 0H10.7C4.8 0 0 4.7 0 10.6v14.7c0 1.8 1.5 3.3 3.4 3.3h2c.3 0 .5-.2.6-.5.2-12.2 10.1-22 22.3-22.2.3 0 .5-.2.5-.5v-2c0-1.9-1.5-3.4-3.3-3.4"
                />
              </g>
              <g id="misTurnos_ticket__TEXTO" transform="translate(6 6)">
                <g id="misTurnos_ticket__COOPERATIVA" transform="translate(.218 .25)">
                  <path
                    id="misTurnos_ticket__n20"
                    className="misTurnos_ticket__sn3"
                    d="M4 33.9c0 .6-.2 1.1-.5 1.4-.3.3-.8.5-1.5.5-.3 0-.6 0-.9-.1-.3-.1-.5-.2-.6-.4-.2-.2-.3-.4-.4-.6 0-.3-.1-.5-.1-.8v-.4c0-.1 0-.2.1-.3 0-.1.1-.2.1-.2 0-.1.1-.1.1-.1l.7.1c-.1.1-.1.3-.2.4 0 .2-.1.3-.1.5 0 .1 0 .3.1.4 0 .1.1.2.2.3.1.1.2.2.4.2.2.1.3.1.6.1.2 0 .3 0 .5-.1.2 0 .3-.1.4-.2l.3-.3c.1-.1.1-.3.1-.5v-.3c0-.1 0-.2-.1-.3 0-.1 0-.1-.1-.2l-.1-.1.7-.2c.1.1.1.3.2.5s.1.4.1.7"
                  />
                  <path
                    id="misTurnos_ticket__n22"
                    className="misTurnos_ticket__sn3"
                    d="M2.1 31.4h.5c.1 0 .3-.1.4-.2l.3-.3c.1-.1.1-.3.1-.4v-.4c0-.1-.1-.2-.2-.3-.1-.1-.2-.2-.4-.2-.1-.1-.3-.1-.5-.1h-.5c-.2 0-.3.1-.4.2l-.3.3c-.1.1-.1.1-.1.2v.4c0 .1.1.2.2.3.1.1.2.2.4.2.2.2.3.3.5.3m.3-2.9c.3 0 .6.1.8.2.2.1.4.3.6.5.1.2.3.4.3.6.1.2.1.5 0 .8 0 .3-.1.5-.2.7-.1.2-.3.4-.4.6-.2.2-.4.3-.7.3-.3.1-.6.1-.9.1-.3 0-.6-.1-.8-.2-.2-.1-.4-.3-.6-.5-.2-.2-.3-.4-.3-.6-.1-.2-.1-.5 0-.7 0-.3.1-.5.2-.7.1-.2.3-.4.5-.6.2-.2.4-.3.7-.3.2-.2.5-.3.8-.2"
                  />
                  <path
                    id="misTurnos_ticket__n24"
                    className="misTurnos_ticket__sn3"
                    d="M2.8 27h.5c.2 0 .3 0 .4-.1.2 0 .3-.1.3-.2.1-.1.2-.2.2-.4v-.4c0-.1-.1-.3-.2-.4l-.3-.3c-.1-.1-.2-.2-.4-.2s-.4-.1-.5-.1c-.2 0-.3 0-.4.1-.1.1-.2.1-.3.2-.1.1-.2.2-.2.4v.4c0 .1.1.3.2.4l.3.3c0 .1.2.2.4.3m.7-2.9c.3.1.6.2.8.4.2.2.4.3.5.5.1.2.2.5.2.7 0 .2 0 .5-.1.8-.1.3-.2.5-.3.7-.1.2-.3.4-.5.5-.2.1-.4.2-.7.2-.3 0-.6 0-.9-.1-.3-.1-.6-.2-.8-.4-.2-.2-.4-.3-.5-.6-.1-.1-.2-.3-.2-.6 0-.2 0-.5.1-.7.1-.3.2-.5.3-.7.1-.2.3-.4.5-.5.2-.1.4-.2.7-.2.3-.1.6-.1.9 0"
                  />
                  <path
                    id="misTurnos_ticket__n26"
                    className="misTurnos_ticket__sn3"
                    d="M3.1 21.8c0 .1 0 .1-.1.2v.1l1 .4.1-.3c.1-.3.2-.5.1-.7 0-.2-.1-.3-.3-.4-.1 0-.2-.1-.3 0-.1 0-.2 0-.2.1l-.2.2c0 .2 0 .3-.1.4m-.7-.2c.2-.5.5-.9.8-1.1.3-.2.7-.2 1.1-.1.4.2.7.4.7.8.1.4 0 .8-.2 1.3l-.1.3 1.2.5-.3.7-3.5-1.4c0-.2.1-.4.2-.6 0-.1 0-.3.1-.4"
                  />
                  <path
                    id="misTurnos_ticket__n28"
                    className="misTurnos_ticket__sn3"
                    d="M6.9 20.9l-3.4-1.8 1.3-2.3.6.3-.8 1.5.7.4.7-1.3.6.3-.7 1.3.8.5.9-1.7.6.4z"
                  />
                  <path
                    id="misTurnos_ticket__n30"
                    className="misTurnos_ticket__sn3"
                    d="M6.6 15.3c0 .1-.1.1-.1.1s0 .1-.1.1l.9.6.1-.2c.2-.3.3-.5.3-.6 0-.2-.1-.3-.2-.4-.2-.1-.3-.1-.5-.1-.1.1-.3.3-.4.5m-.7-.4c.3-.5.7-.8 1-.9.3-.1.7-.1 1.1.2.2.2.4.3.4.5.1.2.1.4 0 .7h1.4c.2 0 .3 0 .5.1l-.6.8H8.1l-.2.3 1.1.7-.5.7-3.1-2.1c.1-.2.2-.3.3-.5 0-.2.1-.4.2-.5"
                  />
                  <path
                    id="misTurnos_ticket__n32"
                    className="misTurnos_ticket__sn3"
                    d="M9.5 11.9c0 .1.1.1.1.2s.1.2.2.3c.1.1.1.2.2.4.1.1.2.3.2.4l.7-.8c-.2-.1-.3-.1-.5-.2s-.3-.1-.4-.2h-.3c-.1-.1-.1-.1-.2-.1zm2.9 1.2c-.1-.1-.2-.1-.4-.2-.1-.1-.3-.1-.4-.2l-1 1.1c.1.1.1.3.2.4.1.1.1.3.2.4l-.6.7c-.2-.4-.4-.7-.6-1.1-.2-.3-.3-.6-.5-.9-.1-.3-.3-.6-.4-.9-.1-.3-.2-.5-.4-.8l.6-.6.9.3.9.3c.3.1.6.2 1 .4.3.1.7.3 1.1.5l-.6.6z"
                  />
                  <path
                    id="misTurnos_ticket__n34"
                    className="misTurnos_ticket__sn3"
                    d="M12.6 7.7l.4.6-.8.7 2.1 2.3-.7.6-2.1-2.3-.8.8-.5-.5z"
                  />
                  <path
                    id="misTurnos_ticket__n36"
                    className="misTurnos_ticket__sn3"
                    d="M13.2 7.3l.7-.6 2.3 3.1-.7.5z"
                  />
                  <path
                    id="misTurnos_ticket__n38"
                    className="misTurnos_ticket__sn3"
                    d="M17.8 8.7l-.9-.6-.9-.6c-.3-.2-.6-.4-.8-.6-.3-.2-.5-.4-.7-.5l.8-.5c.2.1.4.3.6.5.2.2.4.3.6.5.2.2.4.3.6.5.2.2.4.3.5.4 0-.2-.1-.4-.1-.6-.1-.2-.1-.5-.2-.8-.1-.3-.1-.5-.2-.8-.1-.3-.1-.5-.2-.7l.8-.5c.1 0 .2.3.3.6.1.3.1.7.2 1 .1.4.1.7.2 1.1.1.4.1.7.2 1.1l-.8.5z"
                  />
                  <path
                    id="misTurnos_ticket__n40"
                    className="misTurnos_ticket__sn3"
                    d="M20 4.1v1.5l1-.5c0-.1-.1-.2-.3-.3l-.3-.3c-.1-.1-.2-.2-.3-.2 0-.1-.1-.2-.1-.2zm2.2 2.2l-.3-.3-.3-.3-1.4.6v.4c0 .1 0 .3.1.4l-.8.4c0-.4-.1-.8-.1-1.2 0-.4-.1-.7-.1-1.1 0-.3 0-.6-.1-1v-.9l.8-.2c.2.2.5.4.7.6.2.2.5.4.7.6.2.2.5.5.8.7.3.3.6.5.9.9l-.9.4z"
                  />
                  <path
                    id="misTurnos_ticket__n42"
                    className="misTurnos_ticket__sn3"
                    d="M25.3 4.5H25.6c.4-.1.7-.3.8-.6.1-.3.2-.6.1-.9-.1-.4-.3-.7-.5-.8-.2-.1-.6-.2-1 0-.1 0-.1 0-.2.1-.1 0-.1 0-.2.1l.7 2.1zm2.1-1.9c.1.3.1.6.1.9 0 .3-.1.5-.3.7-.1.2-.3.4-.6.5-.2.2-.5.3-.9.4-.2 0-.3.1-.5.1s-.4.1-.6.1l-1-3.6c.2-.1.4-.2.6-.2.2-.1.4-.1.5-.2.3-.1.6-.1.9-.1.3 0 .5 0 .8.1.2.1.4.3.6.5.1.2.3.5.4.8z"
                  />
                  <path
                    id="misTurnos_ticket__n44"
                    className="misTurnos_ticket__sn3"
                    d="M28.5 4.4L27.9.6l2.6-.4.1.7-1.7.3.1.7 1.5-.2.1.7-1.5.2.1.9 1.9-.3.1.8z"
                  />
                  <path
                    id="misTurnos_ticket__n46"
                    className="misTurnos_ticket__sn3"
                    d="M34.6.9c0 .1 0 .1-.1.2 0 .1-.1.2-.1.3 0 .1-.1.3-.2.4 0 .2-.1.3-.2.5h1.1c0-.1-.1-.3-.1-.5-.1-.2-.1-.3-.2-.4s-.1-.2-.1-.3c0-.1-.1-.2-.1-.2zm1 2.9c0-.1-.1-.3-.1-.4 0-.1-.1-.3-.1-.4h-1.5c-.1.1-.1.3-.2.4-.1.1-.1.3-.1.4h-.9c.2-.4.3-.8.4-1.1.1-.3.3-.7.4-1 .1-.3.3-.6.4-.9.1-.3.2-.5.3-.8h.8c.1.3.2.5.4.8.1.3.2.6.4.9.1.3.2.6.4 1 .1.4.3.7.4 1.1h-1z"
                  />
                  <path
                    id="misTurnos_ticket__n48"
                    className="misTurnos_ticket__sn3"
                    d="M39.7.4l.9.1-.5 3.8-.9-.1.2-1.6-1.4-.2-.2 1.6-.9-.1.5-3.8.9.1-.2 1.5 1.4.2z"
                  />
                  <path
                    id="misTurnos_ticket__n50"
                    className="misTurnos_ticket__sn3"
                    d="M42 2.8v.5c0 .2 0 .3.1.4.1.1.1.2.2.3.1.1.2.2.4.2s.3.1.4 0c.1 0 .3-.1.4-.2l.3-.3c.1-.1.2-.2.2-.4.1-.2.1-.4.1-.5 0-.2 0-.3-.1-.4-.1-.1-.1-.2-.2-.3-.1-.1-.2-.2-.4-.2s-.3-.1-.4 0c-.1 0-.3.1-.4.2l-.3.3c-.1.1-.2.2-.3.4m2.9.8c-.1.3-.2.6-.4.8-.2.2-.3.4-.6.5-.2.1-.4.1-.6.2-.3 0-.5 0-.8-.1-.3-.1-.5-.2-.7-.3-.2-.1-.4-.3-.5-.5-.1-.2-.2-.4-.2-.7 0-.3 0-.6.1-.9.1-.3.2-.6.4-.8.2-.2.4-.4.6-.5.2-.1.4-.2.7-.2.2 0 .5 0 .7.1.3.1.5.2.7.3.2.1.4.3.5.5.1.2.2.4.2.7s0 .6-.1.9"
                  />
                  <path
                    id="misTurnos_ticket__n52"
                    className="misTurnos_ticket__sn3"
                    d="M47.2 3.2c-.1 0-.1 0-.2-.1h-.1l-.4 1 .2.1c.3.1.5.2.7.2.2 0 .3-.1.4-.3.1-.2.1-.3 0-.5s-.3-.3-.6-.4m.3-.7c.5.2.9.5 1.1.8s.2.7.1 1.1c-.1.2-.2.4-.4.5-.3.1-.5.2-.7.1 0 .1.1.3.1.4 0 .1.1.3.1.5s0 .3.1.5v.5l-.9-.4v-.4c0-.1 0-.3-.1-.4 0-.1 0-.3-.1-.4 0-.1-.1-.2-.1-.4l-.4-.2-.6 1.3-.8-.3 1.5-3.5c.2 0 .4.1.6.2.2 0 .3.1.5.1"
                  />
                  <path
                    id="misTurnos_ticket__n54"
                    className="misTurnos_ticket__sn3"
                    d="M50.8 4.9c-.1 0-.1-.1-.2-.1 0 0-.1 0-.1-.1l-.5 1 .2.1c.3.2.5.2.7.2.2 0 .3-.1.4-.3.1-.2.1-.3 0-.5-.1 0-.3-.1-.5-.3m.3-.6c.5.3.8.6 1 .9.2.3.1.7-.1 1.1-.1.2-.3.4-.5.5-.2.1-.4.1-.7.1v1.9l-.8-.5v-.4-.4-.4-.4l-.4-.2-.6 1-.8-.4 1.9-3.3c.2.1.4.1.5.2.2.1.4.2.5.3"
                  />
                  <path
                    id="misTurnos_ticket__n56"
                    className="misTurnos_ticket__sn3"
                    d="M53 7.7c-.1.1-.2.3-.3.4-.1.1-.1.3-.1.4 0 .1 0 .3.1.4l.3.3c.1.1.3.2.4.2h.4c.1 0 .3-.1.4-.2.1-.1.3-.2.4-.4.1-.1.2-.3.3-.4 0-.1.1-.2.1-.4 0-.1 0-.3-.1-.4l-.3-.3c-.1-.1-.3-.2-.4-.2h-.4c-.1 0-.3.1-.4.2-.2.1-.3.3-.4.4m2.4 1.8c-.2.3-.4.5-.6.6-.2.1-.5.2-.7.3-.2 0-.5 0-.7-.1-.2-.1-.5-.2-.7-.4-.2-.2-.4-.3-.5-.5-.1-.2-.2-.4-.3-.7V8c.1-.3.2-.5.4-.8s.4-.5.6-.6c.2-.1.5-.2.7-.3.2 0 .5 0 .7.1.2.1.5.2.7.3.2.2.4.3.5.5.1.2.2.4.3.7 0 .2 0 .5-.1.7 0 .3-.2.6-.3.9"
                  />
                  <path
                    id="misTurnos_ticket__n58"
                    className="misTurnos_ticket__sn3"
                    d="M58.2 11.7c.3-.1.6-.1.9-.2.3-.1.6-.2.8-.3l.7.7c-.4.1-.9.2-1.3.3-.4.1-.9.2-1.3.3l-1.1 1-.7-.5 1.1-1c.1-.4.2-.9.4-1.3.1-.4.3-.9.4-1.3l.7.7c-.1.3-.2.5-.3.8-.1.3-.2.6-.3.8"
                  />
                  <path
                    id="misTurnos_ticket__n60"
                    className="misTurnos_ticket__sn3"
                    d="M59.7 17.4c-.4-.5-.5-1-.4-1.5.1-.5.4-.9 1-1.2.3-.2.5-.3.8-.4.3-.1.5-.1.8 0 .2 0 .5.1.7.3.2.1.4.3.6.6.1.1.2.3.2.4.1.1.1.2.1.3 0 .1.1.2.1.3v.2l-.7.2c0-.1 0-.3-.1-.4-.1-.2-.1-.3-.2-.5l-.3-.3c-.1-.1-.2-.1-.4-.2h-.4c-.2 0-.3.1-.5.2-.1.1-.3.2-.4.3-.1.1-.2.2-.2.4 0 .1-.1.3 0 .4 0 .2.1.3.2.5.1.1.1.2.2.3l.2.2c.1.1.1.1.2.1s.1.1.2.1l-.4.6c-.1-.1-.3-.2-.5-.3-.5-.2-.6-.4-.8-.6"
                  />
                  <path
                    id="misTurnos_ticket__n62"
                    className="misTurnos_ticket__sn3"
                    d="M64.1 18.4c0-.1-.1-.1-.1-.1l-.1-.1-.9.4.1.2c.2.3.3.5.4.6.1.1.3.1.5 0s.3-.2.3-.4-.1-.4-.2-.6m.6-.4c.3.5.4.9.3 1.3 0 .4-.3.6-.6.8-.2.1-.5.2-.7.1-.2 0-.4-.1-.6-.3-.1.1-.1.2-.2.4-.1.1-.1.3-.2.4-.1.1-.2.3-.3.4-.1.1-.2.3-.3.4l-.5-.9c.1-.1.2-.2.2-.4.1-.1.2-.2.2-.4.1-.1.1-.2.2-.4.1-.1.1-.2.2-.3l-.2-.4-1.2.6-.4-.8 3.4-1.7c.1.1.2.3.3.5.2.4.3.5.4.7"
                  />
                  <path
                    id="misTurnos_ticket__n64"
                    className="misTurnos_ticket__sn3"
                    d="M67.6 22.1l-1.1-.6.2-.5 1.1.4-.2.7zm-5.3-.1l3.6-1.4.9 2.4-.7.3-.6-1.6-.7.3.6 1.4-.7.3-.6-1.4-.9.3.7 1.7-.7.3-.9-2.6z"
                  />
                  <path
                    id="misTurnos_ticket__n66"
                    className="misTurnos_ticket__sn3"
                    d="M64.4 25.6V25.9c.1.4.3.7.6.8s.6.2 1 .1.7-.3.8-.5c.2-.2.2-.6.1-1v-.2c0-.1 0-.1-.1-.2l-2.4.7zm1.8 2.2c-.3.1-.6.1-.9.1s-.5-.1-.7-.3c-.2-.1-.4-.3-.5-.6-.1-.2-.3-.5-.3-.9 0-.1-.1-.3-.1-.5s-.1-.4-.1-.6l3.6-.9c.1.2.2.4.2.6.1.2.1.4.2.5.1.3.1.6.1.9 0 .3-.1.5-.2.8-.1.2-.3.4-.5.6-.2 0-.5.2-.8.3z"
                  />
                  <path
                    id="misTurnos_ticket__n68"
                    className="misTurnos_ticket__sn3"
                    d="M68.2 28.3l.1.8-3.8.6-.1-.9z"
                  />
                  <path
                    id="misTurnos_ticket__n70"
                    className="misTurnos_ticket__sn3"
                    d="M68.7 33l-.7.1-.1-1.2-3.1.3-.1-.9 3.1-.2-.1-1.2h.8z"
                  />
                  <path
                    id="misTurnos_ticket__n72"
                    className="misTurnos_ticket__sn3"
                    d="M66.8 34.4h-.5c-.2 0-.3.1-.4.2l-.3.3c-.1.1-.1.3-.1.4 0 .2 0 .3.1.4.1.1.1.2.2.3.1.1.2.2.4.2.2.1.3.1.5.1h.5c.2 0 .3-.1.4-.2l.3-.3c.1-.1.1-.3.1-.4 0-.2 0-.3-.1-.4-.1-.1-.1-.2-.2-.3-.1-.1-.2-.2-.4-.2s-.3 0-.5-.1m-.2 3c-.3 0-.6-.1-.9-.2-.2-.1-.4-.3-.6-.4-.2-.2-.3-.4-.4-.6-.1-.2-.1-.5-.1-.8 0-.3.1-.5.2-.7.1-.2.2-.4.4-.6.2-.2.4-.3.6-.4.3-.1.5-.1.9-.1.3 0 .6.1.9.2.2.1.4.3.6.4.2.2.3.4.4.6.1.2.1.5.1.7 0 .3-.1.5-.2.7-.1.2-.2.4-.4.6-.2.2-.4.3-.7.4-.1.1-.4.2-.8.2"
                  />
                </g>
                <g
                  id="misTurnos_ticket___x31_00_x25_-SEGURO"
                  transform="rotate(3 -1126.391 332.913)"
                >
                  <path
                    id="misTurnos_ticket__n74"
                    className="misTurnos_ticket__sn3"
                    d="M.6.8H1.8c.1 0 .2 0 .4-.1l.4.3L.7 4.4 0 4l1.4-2.5H.6V.8z"
                  />
                  <path
                    id="misTurnos_ticket__n76"
                    className="misTurnos_ticket__sn3"
                    d="M4.4 3.9c.1-.2.1-.3.2-.5 0-.2.1-.3.1-.4v-.3c0-.1-.1-.1-.2-.2h-.3c-.1 0-.2.1-.3.2l-.3.3c0 .2-.1.3-.2.5s-.1.4-.2.5c0 .2-.1.3-.1.4v.3c0 .1.1.1.2.2h.3c.1 0 .2-.1.3-.2.1 0 .1-.2.2-.3.1-.1.2-.3.3-.5m.7.4c-.3.6-.6 1-.9 1.2-.4.2-.8.3-1.1.1-.4-.2-.7-.5-.7-.9-.1-.4 0-.9.3-1.5.1-.3.3-.6.4-.8.1-.2.3-.3.5-.4.2-.2.4-.2.5-.2.2 0 .4 0 .6.1.4.1.6.4.7.8.1.4 0 1-.3 1.6"
                  />
                  <path
                    id="misTurnos_ticket__n78"
                    className="misTurnos_ticket__sn3"
                    d="M7.3 5c.1-.2.1-.4.1-.5v-.4c0-.1 0-.2-.1-.3 0-.1-.1-.1-.2-.2h-.3c-.1 0-.2.1-.2.2s-.1.2-.1.3c-.1.2-.2.4-.2.5-.1.2-.1.4-.2.6v.4c0 .1 0 .2.1.3 0 .1.1.1.1.2h.3c.1 0 .2-.1.2-.2.1-.2.2-.3.3-.4 0-.2.1-.3.2-.5m.7.2c-.2.6-.4 1.1-.8 1.3-.3.3-.7.4-1.1.2-.4-.1-.6-.4-.7-.8-.2-.4-.1-.9.1-1.5.1-.3.2-.6.4-.8.1-.2.2-.4.4-.5.2-.1.4-.2.5-.3h.6c.4.2.7.5.8.9.1.4 0 .9-.2 1.5"
                  />
                  <path
                    id="misTurnos_ticket__n80"
                    className="misTurnos_ticket__sn3"
                    d="M12.1 7.1v-.4c0-.1-.1-.2-.2-.2s-.2 0-.3.1c-.1.1-.1.2-.2.4v.4c0 .1.1.2.2.2s.2 0 .3-.1c.1-.1.2-.2.2-.4zm.6.1c-.1.3-.2.6-.4.7-.2.2-.4.2-.7.1-.3-.1-.5-.2-.6-.4-.1-.2-.2-.5-.1-.8.1-.3.2-.6.4-.7s.4-.2.7-.1c.3.1.5.2.6.4.1.2.2.4.1.8zM9.9 4.7v-.4c0-.1-.1-.2-.2-.2s-.2 0-.3.1c-.1.1-.1.2-.2.4V5c0 .1.1.2.2.2s.2 0 .3-.1c.1-.1.2-.2.2-.4zm1.8-.7l.7.1-2.8 3.5-.7-.1L11.7 4zm-1.2.8c-.1.3-.2.6-.4.7-.2.2-.5.3-.8.2-.2 0-.4-.2-.6-.4-.1-.2-.1-.5-.1-.8.1-.4.2-.6.4-.8.2-.2.4-.2.7-.1.3.1.5.2.6.4.2.2.3.5.2.8z"
                  />
                  <path
                    id="misTurnos_ticket__n82"
                    className="misTurnos_ticket__sn3"
                    d="M15.9 7.7h.3c.1 0 .1 0 .2-.1l.1-.1v-.2c0-.1 0-.2-.2-.3-.1-.1-.3-.2-.6-.3-.1 0-.2-.1-.4-.2-.1-.1-.2-.1-.3-.2 0-.1-.1-.2-.2-.3-.1-.1-.1-.3-.1-.5s0-.3.1-.5c.1-.1.2-.3.3-.4.1-.1.3-.2.4-.2.2 0 .4-.1.6-.1.2 0 .5 0 .6.1.2.1.3.1.4.2l-.1.8c-.1-.1-.2-.1-.3-.2-.1 0-.3-.1-.5-.1s-.3 0-.4.1c-.1.1-.1.1-.1.3v.2l.1.1c.1 0 .1.1.2.1s.2.1.3.1c.2.1.4.2.5.2.1.1.3.2.3.3.1.1.2.2.2.3 0 .1.1.3.1.5 0 .4-.1.6-.4.8-.2.2-.6.3-1.1.3h-.4c-.1 0-.2-.1-.3-.1-.1 0-.2-.1-.3-.1-.1 0-.1-.1-.2-.1l.3-.7c.1.1.2.1.4.2 0 .1.2.1.5.1"
                  />
                  <path
                    id="misTurnos_ticket__n84"
                    className="misTurnos_ticket__sn3"
                    d="M18.2 8.4l-.3-3.9 2.5-.2.1.8-1.7.1.1.7 1.5-.1v.7l-1.5.1.1 1 1.8-.1v.7z"
                  />
                  <path
                    id="misTurnos_ticket__n86"
                    className="misTurnos_ticket__sn3"
                    d="M22.9 4.6c-.4.1-.6.2-.8.5-.1.2-.1.6 0 .9 0 .2.1.4.2.5.1.1.2.3.3.4.1.1.2.2.4.2h.7c.1 0 .1 0 .2-.1l-.2-1.3.8-.1.3 1.9c-.1.1-.2.1-.5.2-.2.1-.5.2-.8.2h-.8c-.2 0-.5-.1-.6-.3-.2-.1-.3-.3-.5-.6-.1-.2-.2-.5-.3-.8-.1-.3-.1-.6 0-.9 0-.3.1-.5.3-.7.1-.2.3-.4.5-.5.2-.1.4-.2.7-.3h.9c.1 0 .2 0 .3.1.1 0 .1 0 .2.1l-.1.7c-.1 0-.3-.1-.4-.1h-.8"
                  />
                  <path
                    id="misTurnos_ticket__n88"
                    className="misTurnos_ticket__sn3"
                    d="M27.1 7c-.3.1-.5.1-.7.1-.2-.1-.4-.1-.5-.2-.2-.1-.3-.2-.4-.4l-.3-.6-.7-2.3.8-.2.7 2.2c0 .2.1.3.2.4.1.1.1.2.2.2s.2.1.2.1h.3c.2-.1.3-.2.4-.3.1-.2.1-.4 0-.7L26.6 3l.8-.2.7 2.2c.1.2.1.4.1.6 0 .2 0 .4-.1.6-.1.2-.2.3-.4.4-.1.2-.3.3-.6.4"
                  />
                  <path
                    id="misTurnos_ticket__n90"
                    className="misTurnos_ticket__sn3"
                    d="M29.3 2.7c-.1 0-.1 0-.1.1 0 0-.1 0-.1.1l.4 1 .2-.1c.3-.1.5-.2.6-.4.1-.1.1-.3 0-.5s-.2-.3-.4-.3c-.1-.1-.3 0-.6.1M29 2c.5-.2.9-.3 1.3-.2.4.1.6.3.8.7.1.3.1.5.1.7 0 .2-.1.4-.3.6.1 0 .2.1.3.2.1.1.3.2.4.3.1.1.3.2.4.3.1.1.3.2.4.3l-.9.4-.3-.3c-.1-.1-.2-.2-.4-.3-.1-.1-.2-.2-.3-.2-.1-.1-.2-.1-.3-.2l-.4.2.6 1.3-.8.3L28 2.5c.2-.1.3-.2.5-.3.2 0 .4-.1.5-.2"
                  />
                  <path
                    id="misTurnos_ticket__n92"
                    className="misTurnos_ticket__sn3"
                    d="M32.6 2.5c.1.2.2.3.3.4.1.1.2.2.4.2s.3.1.4.1c.1 0 .3-.1.4-.1.1-.1.3-.1.3-.3.1-.1.1-.2.1-.4V2c0-.2-.1-.3-.2-.5s-.2-.3-.3-.4c-.1-.1-.2-.2-.4-.3-.1-.1-.3-.1-.4-.1-.1 0-.3.1-.4.1l-.3.3c-.1.1-.1.2-.1.4v.4c.1.3.1.5.2.6m2.5-1.4c.2.3.3.6.3.8v.7l-.3.6c-.2.2-.3.3-.6.5-.2.2-.4.3-.7.3h-.7c-.2-.1-.5-.2-.7-.4-.2-.2-.4-.4-.6-.7-.2-.3-.3-.6-.3-.8v-.8l.3-.6c.1-.2.3-.3.5-.5.2-.1.4-.2.7-.2h.7c.2.1.5.2.7.4.3.2.5.4.7.7"
                  />
                </g>
              </g>
            </g>
            <g id="misTurnos_ticket__EXITO" transform="translate(76 60)">
              <path
                id="misTurnos_ticket__FONDO-BLANCO"
                d="M8 .5C3.8.5.5 3.8.5 8s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5C15.5 3.8 12.2.5 8 .5"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#fff"
              />
              <g id="misTurnos_ticket___x30_0_Token_x2F_Icons_x2F_Solid_x2F_32x32_x2F_check">
                <path
                  id="misTurnos_ticket__color-icono"
                  d="M8 .5C3.8.5.5 3.8.5 8s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5C15.5 3.8 12.2.5 8 .5m2.4 5c.3-.3.8-.4 1.1-.1.3.3.4.7.1 1l-.1.1-4.3 5-2.7-2.8c-.3-.3-.3-.8 0-1.1.3-.3.7-.3 1.1-.1l.1.1 1.5 1.5 3.2-3.6z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#787880"
                  fillOpacity={0.2}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  
  );
}


function SvgRelojArena(props) {
  
  return (
      
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <Path fill="none" d="M0 33h32V0H0z" />
      <G fillRule="evenodd" clipRule="evenodd">
        <Path
          d="M6.4 23.73c-.9 2-1.4 4.3-1.4 6.6v.6c0 .6.4 1 1 1h20c.6 0 1-.5 1-1v-.6c0-2.4-.5-4.6-1.4-6.6H6.4z"
          fill="#1bb5ec"
        />
        <Path
          d="M7 29.83c.1-1.8.5-3.6 1.2-5.2h15.6c.7 1.6 1.1 3.3 1.2 5.2H7zm9-12.3c2.8.9 5.1 2.7 6.7 5.1H9.3c1.6-2.3 3.9-4.1 6.7-5.1zM7 3.13h18c-.2 5.7-3.7 10.5-9 12.3-5.3-1.8-8.8-6.7-9-12.3zm21 26.7h-1c-.2-5.8-3.4-10.9-8.3-13.4 4.9-2.5 8.1-7.6 8.3-13.4h1c.6 0 1-.5 1-1s-.4-1-1-1H4c-.6 0-1 .5-1 1s.4 1 1 1h1c.2 5.8 3.4 10.9 8.3 13.4-4.9 2.6-8.1 7.7-8.3 13.4H4c-.6 0-1 .5-1 1s.4 1 1 1h24c.6 0 1-.5 1-1s-.4-1-1-1z"
          fill="#364f6b"
        />
      </G>
    </Svg>
    
  );
}


function SvgFila(props) {
  
  return (
      
    <Svg id="f_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 32 29" xmlSpace="preserve" {...props} >
      <style>
        {
          ".misTurnos_fila__f1,.misTurnos_fila__f2{fill-rule:evenodd;clip-rule:evenodd;fill:#1bb5ec}.misTurnos_fila__f2{fill:#364f6b}"
        }
      </style>
      <g id="f__Artboard-Copy-21">
        <g id="f__Group">
          <g id="f-1">
            <path id="f1" fill="none" d="M0 33h32V0H0z" />
            <g id="misTurnos_fila__Group-20" transform="translate(1 1.03)">
              <path
                id="f2"
                className="misTurnos_fila__f1"
                d="M25 11.3c-3 0-5 2.8-5 5.8v1.4c0 .6.4 1 1 1h8c.6 0 1-.5 1-1v-1.4c0-3-2-5.8-5-5.8"
              />
              <path
                id="f4"
                className="misTurnos_fila__f1"
                d="M10 18.5v-1.4c0-3-2-5.8-5-5.8s-5 2.8-5 5.8v1.4c0 .6.4 1 1 1h8c.6.1 1-.4 1-1"
              />
              <path
                id="f6"
                className="misTurnos_fila__f1"
                d="M15 17.5c-3.4 0-6 3-6 6.4v1.8c0 .6.4 1 1 1h10c.6 0 1-.5 1-1v-1.8c0-3.5-2.7-6.4-6-6.4"
              />
              <path
                id="f8"
                className="misTurnos_fila__f2"
                d="M28 17.5h-6v-.4c0-1.8 1.1-3.7 3-3.7s3 1.9 3 3.7v.4zm-3-6.2c-3 0-5 2.8-5 5.8v1.4c0 .6.4 1 1 1h8c.6 0 1-.5 1-1v-1.4c0-3-2-5.8-5-5.8z"
              />
              <path
                id="f10"
                className="misTurnos_fila__f2"
                d="M23 3.1c0-.6.4-1 1-1h2c.6 0 1 .5 1 1v4.1c0 .6-.4 1-1 1h-2c-.6 0-1-.5-1-1V3.1zm1 7.2h2c1.7 0 3-1.4 3-3.1V3.1C29 1.4 27.7 0 26 0h-2c-1.7 0-3 1.4-3 3.1v4.1c0 1.7 1.3 3.1 3 3.1z"
              />
              <path
                id="f12"
                className="misTurnos_fila__f2"
                d="M8 17.5H2v-.4c0-1.8 1.1-3.7 3-3.7s3 1.9 3 3.7v.4zm2 1v-1.4c0-3-2-5.8-5-5.8s-5 2.8-5 5.8v1.4c0 .6.4 1 1 1h8c.6.1 1-.4 1-1z"
              />
              <path
                id="f14"
                className="misTurnos_fila__f2"
                d="M3 3.1c0-.6.4-1 1-1h2c.6 0 1 .5 1 1v4.1c0 .6-.4 1-1 1H4c-.6 0-1-.5-1-1V3.1zm1 7.2h2c1.7 0 3-1.4 3-3.1V3.1C9 1.4 7.7 0 6 0H4C2.3 0 1 1.4 1 3.1v4.1c0 1.7 1.3 3.1 3 3.1z"
              />
              <path
                id="f16"
                className="misTurnos_fila__f2"
                d="M19 24.7h-8v-.8c0-2.3 1.7-4.4 4-4.4s4 2 4 4.4v.8zm-4-7.2c-3.4 0-6 3-6 6.4v1.8c0 .6.4 1 1 1h10c.6 0 1-.5 1-1v-1.8c0-3.5-2.7-6.4-6-6.4z"
              />
              <path
                id="f18"
                className="misTurnos_fila__f2"
                d="M12 6.2c0-1.1.9-2.1 2-2.1h2c1.1 0 2 .9 2 2.1v5.2c0 1.1-.9 2.1-2 2.1h-2c-1.1 0-2-.9-2-2.1V6.2zm2 9.3h2c2.2 0 4-1.8 4-4.1V6.2c0-2.3-1.8-4.1-4-4.1h-2c-2.2 0-4 1.8-4 4.1v5.2c0 2.2 1.8 4.1 4 4.1z"
              />
            </g>
          </g>
        </g>
      </g>
    </Svg>
    
  );
}


export { SvgTicket, SvgRelojArena, SvgFila }