import {StyleSheet, Platform} from 'react-native'
import * as Font from 'expo-font';


Font.loadAsync({
  'Ubuntu-Light': require('../../../assets/fonts/Ubuntu-Light.ttf'),
  'Ubuntu-Bold': require('../../../assets/fonts/Ubuntu-Bold.ttf'),
  'Ubuntu-Regular': require('../../../assets/fonts/Ubuntu-Regular.ttf'),
  'Ubuntu-Medium': require('../../../assets/fonts/Ubuntu-Medium.ttf'),
})


export default StyleSheet.create({
  textTagsCard: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 14,
    color:"#007db7",
    fontFamily: 'Ubuntu-Bold',
  },
  textInformationCard: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 14,
    color: "#a9a9a9",
  },
  centrarItem: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderRadius: '4px',
    borderColor: "#E7E7E7",
  },
  tituloSucursal:{
    fontFamily: 'Ubuntu-Bold', 
    fontSize: 18, 
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 25,
    paddingBottom: 20
  },
  nameBranch: {
    fontFamily: 'Ubuntu-Medium',
    fontSize: '18px'
  },
  separadorSearch: {
    height: 1, 
    width: "100%", 
    backgroundColor: "#e1e8ee",
  },
  separadorSearch2: {
    height: 1, 
    width: "100%", 
    backgroundColor: "#e1e8ee", 
  },
  tituloEleccion:{
    marginTop: '23px',
    fontFamily: 'Ubuntu-Medium', 
    fontSize: 24, 
    marginBottom: '27px',
    marginLeft: '35px'
  },
  tituloInput:{
    fontFamily: 'Ubuntu-Bold', 
    fontSize: 14, 
    marginBottom: 10,
  },
  tituloInputDisabled:{
    fontFamily: 'Ubuntu-Bold', 
    fontSize: 14, 
    marginBottom: 10,
    color: '#d5d0d5'
  },
  subtituloInput:{
    fontFamily: 'Ubuntu-Medium', 
    fontSize: 14, 
    marginTop: 5,
  },
  subtituloInputDisabled:{
    fontFamily: 'Ubuntu-Medium', 
    fontSize: 14, 
    marginTop: 5,
    color: '#d5d0d5'
  },
  containerAgendarVolver: { 
    width: "100%", 
    flexDirection: "row", 
    justifyContent: "space-between", 
    padding: 5, 
    marginTop: 30,
    marginBottom: 50
  },
  botonAgendar: {
    fontFamily: 'Ubuntu-Regular',
    color: "white",
    backgroundColor: "#e81e2b", 
    paddingTop: 7, 
    paddingBottom: 7, 
    paddingLeft: 20, 
    paddingRight: 20, 
    borderRadius: 5,
    alignItems: "center",
    fontSize: 16
  },
  botonCancelarVolver: {
    fontFamily: 'Ubuntu-Medium',
    paddingTop: 7, 
    paddingBottom: 7, 
    paddingRight: 20, 
    borderRadius: 5,
    alignItems: "center",
    fontSize: 16,
    color: "#e81e2b"
  },
  branchStateOpen: { 
    fontFamily: 'Ubuntu-Light',
    fontSize: 12, 
    color: 'white', 
    backgroundColor: '#0e891a', 
    paddingLeft: 9, 
    paddingRight: 9, 
    paddingTop: 1, 
    paddingBottom: 1, 
    borderRadius: 10,
  },
  branchStateClose: {
    fontFamily: 'Ubuntu-Light',
    fontSize: 12, 
    color: 'white', 
    backgroundColor: '#e81e2b', 
    paddingLeft: 9, 
    paddingRight: 9, 
    paddingTop: 1, 
    paddingBottom: 1, 
    borderRadius: 10 
  },
  barraTextoRojo: {
    fontSize: 15, 
    color: "#e81e2b",
    fontFamily: 'Ubuntu-Medium',
    marginLeft: 6
  },

})