import React, { Component } from 'react';
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { Image, Text, View } from 'react-native'
import MediaQuery from "react-native-web-responsive";
import PieStyle from './pie.style'

class Pie extends Component {

    render(){
        return(
            <View>
            <MediaQuery minWidth={1224}>
            <View style={PieStyle.footer}>
            {/*<View style={PieStyle.footerBackGroundColor}>
                <View style={PieStyle.footer}>
                    <Icon  name="registered-trademark"  size={20} style={PieStyle.footerColor}></Icon> 
                    <Text style={PieStyle.footerColor}> Ganatiempo - Todos los derechos reservados</Text>
                </View>
                <Text style={{alignSelf:'center', fontSize:7, color:'grey'}}>QA.version 1.0.0</Text>
            </View>*/}
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Image
                    style={{ height: '63px', width: '51px',}}
                    source={require('../../../assets/ProCalidad.png')}
                />
                <View style={{ paddingVertical: '14px', maxWidth: '204px', marginLeft: '8px' }}>
                    <Text style={PieStyle.textoSimple}>1° lugar categoría "Instituciones financieras de ahorro y crédito"</Text>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={PieStyle.textoSimple}>por </Text><Text style={PieStyle.textoNegrita}>quinto año consecutivo</Text>
                    </View>
                </View>
                </View>
                <View style={{alignItems: 'flex-end', paddingVertical: '14px', maxWidth: '670px', width: '100%'}}>
                    <Text style={[PieStyle.textoSimple]}>Infórmese sobre la garantía estatal de los depósitos en su cooperativa o banco en www.sbif.cl</Text>
                    <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                        <Text style={PieStyle.textoNegrita}>Ganatiempo <Icon  name="registered-trademark"  size={8} style={[PieStyle.footerColor, {verticalAlign: 'text-top'}]}></Icon> </Text><Text style={PieStyle.textoSimple}> - </Text><Text style={PieStyle.textoSimple}>Todos los derechos reservados.</Text>
                    </View>
                </View>
            </View>
            </MediaQuery>

            <MediaQuery maxWidth={1223}>
                <View style={[PieStyle.footer, {paddingTop: 5, paddingBottom: 5}]}>
                    <View style={{alignItems: 'flex-end', width: '100%'}}>
                        <Text style={[PieStyle.textoSimple, {fontSize: 10}]}>Infórmese sobre la garantía estatal de los depósitos en su cooperativa o banco en www.sbif.cl</Text>
                        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                            <Text style={[PieStyle.textoNegrita, {fontSize: 10}]}>Ganatiempo <Icon  name="registered-trademark"  size={6} style={[PieStyle.footerColor, {verticalAlign: 'text-top'}]}></Icon> </Text><Text style={[PieStyle.textoSimple, {fontSize: 10}]}> - </Text><Text style={[PieStyle.textoSimple, {fontSize: 10}]}>Todos los derechos reservados.</Text>
                        </View>
                    </View>
                </View>
            </MediaQuery>
            </View>
        );
    }

}

export default Pie;