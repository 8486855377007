import * as React from "react";
import Svg, { G, Path, Defs, Mask, ClipPath, Use } from "react-native-svg";

/*
Letras para ID utilizadas:
a b c d e f g h k l m n o p
*/

function SvgDistancia(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <path
          id="sucursald_a"
          d="M3.4 8.4c.1-.1 2.1-2.2 2.1-5.3C5.5 1.7 4.4.5 3 .5S.5 1.7.5 3.1c0 3.1 2.1 5.2 2.1 5.3.2.1.6.1.8 0M3 1.5c.8 0 1.5.7 1.5 1.6 0 1.9-1 3.4-1.5 4.1-.5-.7-1.5-2.2-1.5-4.1 0-.9.7-1.6 1.5-1.6"
        />
      </defs>
      <clipPath id="sucursald_b">
        <use xlinkHref="#sucursald_a" overflow="visible" />
      </clipPath>
      <g clipPath="url(#sucursald_b)">
        <defs>
          <path id="sucursald_c" d="M0 0h16v16H0z" />
        </defs>
        <clipPath id="sucursald_d">
          <use xlinkHref="#sucursald_c" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#sucursald_d)"
          fill="#007db7"
          d="M-4.5-4.5h15v18h-15z"
        />
      </g>
      <defs>
        <path
          id="sucursald_e"
          d="M3.5 3c0-.3-.2-.5-.5-.5s-.5.2-.5.5.2.5.5.5.5-.2.5-.5"
        />
      </defs>
      <clipPath id="sucursald_f">
        <use xlinkHref="#sucursald_e" overflow="visible" />
      </clipPath>
      <g clipPath="url(#sucursald_f)">
        <defs>
          <path id="sucursald_g" d="M0 0h16v16H0z" />
        </defs>
        <clipPath id="sucursald_h">
          <use xlinkHref="#sucursald_g" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#sucursald_h)"
          fill="#007db7"
          d="M-2.5-2.5h11v11h-11z"
        />
      </g>
      <g>
        <defs>
          <path
            id="sucursald_i"
            d="M15.5 6.1c0-1.4-1.1-2.6-2.5-2.6s-2.5 1.2-2.5 2.6c0 3.1 2.1 5.2 2.1 5.3 1 .9 2.9-3 2.9-5.3M13 4.5c.8 0 1.5.7 1.5 1.6 0 1.9-1 3.4-1.5 4.1-.5-.7-1.5-2.2-1.5-4.1 0-.9.7-1.6 1.5-1.6"
          />
        </defs>
        <clipPath id="sucursald_j">
          <use xlinkHref="#sucursald_i" overflow="visible" />
        </clipPath>
        <g clipPath="url(#sucursald_j)">
          <defs>
            <path id="sucursald_k" d="M0 0h16v16H0z" />
          </defs>
          <clipPath id="sucursald_l">
            <use xlinkHref="#sucursald_k" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#sucursald_l)"
            fill="#007db7"
            d="M5.5-1.5h15v18h-15z"
          />
        </g>
      </g>
      <g>
        <defs>
          <path
            id="sucursald_m"
            d="M13.5 6c0-.3-.2-.5-.5-.5s-.5.2-.5.5.2.5.5.5.5-.2.5-.5"
          />
        </defs>
        <clipPath id="sucursald_n">
          <use xlinkHref="#sucursald_m" overflow="visible" />
        </clipPath>
        <g clipPath="url(#sucursald_n)">
          <defs>
            <path id="sucursald_o" d="M0 0h16v16H0z" />
          </defs>
          <clipPath id="sucursald_p">
            <use xlinkHref="#sucursald_o" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#sucursald_p)"
            fill="#007db7"
            d="M7.5.5h11v11h-11z"
          />
        </g>
      </g>
      <g>
        <defs>
          <path
            id="sucursald_q"
            d="M13 12.5c-.6 0-1.1.3-1.3.8l-7.2-2.7C4.3 10 3.7 9.5 3 9.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c.6 0 1.1-.3 1.3-.8l7.2 2.7c.2.7.7 1.1 1.4 1.1.8 0 1.5-.7 1.5-1.5s-.6-1.5-1.4-1.5m-10-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5m10 3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5"
          />
        </defs>
        <clipPath id="sucursald_r">
          <use xlinkHref="#sucursald_q" overflow="visible" />
        </clipPath>
        <g clipPath="url(#sucursald_r)">
          <defs>
            <path id="sucursald_s" d="M0 0h16v16H0z" />
          </defs>
          <clipPath id="sucursald_t">
            <use xlinkHref="#sucursald_s" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#sucursald_t)"
            fill="#007db7"
            d="M-3.5 4.5h23v16h-23z"
          />
        </g>
      </g>
    </Svg>
  );
}

function SvgMapaBlue(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <path
          id="sucursalm_1"
          d="M15.5 14.4l-2-8c-.1-.3-.3-.4-.6-.4-.3.1-.4.3-.4.6l.6 2.5-2.6 1.8L4.7 7H9c.3 0 .5-.2.5-.5S9.3 6 9 6H3c-.2 0-.4.1-.5.4l-2 8c-.1.3.2.6.5.6h14c.3 0 .6-.3.5-.6zM1.6 14l1.7-6.7 10 6.7H1.6zm9.8-2.5l2-1.3.8 3.2-2.8-1.9z"
        />
      </defs>
      <clipPath id="sucursalm_2">
        <use xlinkHref="#sucursalm_1" overflow="visible" />
      </clipPath>
      <g clipPath="url(#sucursalm_2)">
        <defs>
          <path id="sucursalm_3" d="M0 0h16v16H0z" />
        </defs>
        <clipPath id="sucursalm_4">
          <use xlinkHref="#sucursalm_3" overflow="visible" />
        </clipPath>
        <path clipPath="url(#sucursalm_4)" fill="#007db7" d="M-4.5 1h25v19h-25z" />
      </g>
      <g>
        <defs>
          <path
            id="sucursalm_5"
            d="M10.5 4.9v2.6c0 .3.2.5.5.5s.5-.2.5-.5V4.9c.9-.2 1.5-1 1.5-1.9 0-1.1-.9-2-2-2s-2 .9-2 2c0 .9.6 1.7 1.5 1.9M11 2c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1"
          />
        </defs>
        <clipPath id="sucursalm_6">
          <use xlinkHref="#sucursalm_5" overflow="visible" />
        </clipPath>
        <g clipPath="url(#sucursalm_6)">
          <defs>
            <path id="sucursalm_7" d="M0 0h16v16H0z" />
          </defs>
          <clipPath id="sucursalm_8">
            <use xlinkHref="#sucursalm_7" overflow="visible" />
          </clipPath>
          <path clipPath="url(#sucursalm_8)" fill="#007db7" d="M4-4h14v17H4z" />
        </g>
      </g>
    </Svg>
  );
}

function SvgReloj(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <path
          id="sucursalr_1"
          d="M13.3 2.7C10.4-.2 5.6-.2 2.7 2.7c-2.9 2.9-2.9 7.7 0 10.6 2.9 2.9 7.7 2.9 10.6 0 2.9-2.9 2.9-7.7 0-10.6m-.7 9.9c-2.5 2.5-6.6 2.5-9.2 0-2.5-2.5-2.5-6.7 0-9.2C5.9.9 10 .9 12.6 3.4c2.5 2.5 2.5 6.7 0 9.2"
        />
      </defs>
      <clipPath id="sucursalr_2">
        <use xlinkHref="#sucursalr_1" overflow="visible" />
      </clipPath>
      <g clipPath="url(#sucursalr_2)">
        <defs>
          <path id="sucursalr_3" d="M0 0h16v16H0z" />
        </defs>
        <clipPath id="sucursalr_4">
          <use xlinkHref="#sucursalr_3" overflow="visible" />
        </clipPath>
        <path clipPath="url(#sucursalr_4)" fill="#007db7" d="M-4.5-4.5h25v25h-25z" />
      </g>
      <g>
        <defs>
          <path
            id="sucursalr_5"
            d="M8.5 7.8V3c0-.3-.2-.5-.5-.5s-.5.2-.5.5v5c0 .1.1.3.1.4l2 2c.2.2.5.2.7 0 .2-.2.2-.5 0-.7L8.5 7.8z"
          />
        </defs>
        <clipPath id="sucursalr_6">
          <use xlinkHref="#sucursalr_5" overflow="visible" />
        </clipPath>
        <g clipPath="url(#sucursalr_6)">
          <defs>
            <path id="sucursalr_7" d="M0 0h16v16H0z" />
          </defs>
          <clipPath id="sucursalr_8">
            <use xlinkHref="#sucursalr_7" overflow="visible" />
          </clipPath>
          <path clipPath="url(#sucursalr_8)" fill="#007db7" d="M2.5-2.5h13v18h-13z" />
        </g>
      </g>
    </Svg>
  );
}

export { SvgDistancia, SvgMapaBlue, SvgReloj };
