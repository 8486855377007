import { MaterialIcons } from '@expo/vector-icons';
import React, { Component } from 'react';
import { ScrollView, Text, View } from "react-native";
import { key, urlMap } from '../../config/config';
import Pie from '../Pie/pie.component';
import PieStyle from '../Pie/pie.style';
import Map from './map';

class webMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            latini: 0,
            lngini: 0,
            dataSuc: null,
            isReady: false
        }
    }

    componentDidMount() {
        this.setState({
            latini: this.props.route.params.latini,
            lngini: this.props.route.params.lngini,
            dataSuc: this.props.route.params.dataSuc,
            isReady: true,
            locationError: this.props.route.params.locationError
        })
    }

    render() {
        if (this.state.isReady) {
            return (
                <View style={{ flex: 1 }}>
                    <ScrollView>
                        {this.state.locationError || this.state.latitude === 0 ?
                            <>
                                <MaterialIcons name="gps-off" size={250} color="#f8f9fa" style={{ alignSelf: 'center', top: '25%' }} />
                                <Text style={{ textAlign: 'center', color: 'grey' }}>Debes Activar o Autorizar el uso del GPS. Esta información es utilizada para ayudarte a encontrar la sucursal más cercana.</Text>
                            </>
                            :
                            <div style={{ width: '100vw', height: '100vh' }}>
                                <Map
                                    navigation={this.props.route.params.navigation}
                                    googleMapURL={urlMap + key}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `100%` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    center={{ lat: this.state.latini, lng: this.state.lngini }}
                                    dataSuc={this.state.dataSuc}
                                />
                            </div>
                        }
                    </ScrollView>
                </View>
            )
        } else {
            return (<View></View>)
        }
    }
}

export default webMap