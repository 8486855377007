import Moment from "moment";
import axios from "axios";
import {
  getAvailableHoursByDay,
  verifyPassedHour,
  getBranchScheduleAdress,
  getBranchByName,
  getGroupName,
} from "../../config/config";

class reservarFunctions {
  async getAvailableHoursByDay(date, queueId, branchId, groupName, groupQueues) {

    return await axios.post(getAvailableHoursByDay, {
      branchId: branchId,
      queueId: queueId,
      selectedDate: date,
      groupName: groupName,
      groupQueues: groupQueues,
    });
  }

  async verifyPassedHour(hourReservation, dateToReservation) {
    return await axios.post(verifyPassedHour, {
      requestedTime:
        Moment(dateToReservation).format("YYYY-MM-DD") +
        "T" +
        hourReservation +
        ":00.000+0000",
    });
  }

  async getGroupName(branch, groupName) {
    return await axios.post(getGroupName, {
      groupName: groupName,
      branch: branch,
    })
  }

  async getSucursal(branchName) {
    return await axios.post(getBranchByName, { branchFind: branchName });
  }

  async getHorarioDireccion(branchId) {
    return await axios.post(getBranchScheduleAdress, {
      branchId: branchId,
    });
  }

  scheduleFormat(dataIn) {
    var aux = "";
    let semana = [];
    semana = [
      { dia: "Lunes", num: 0 },
      { dia: "Martes", num: 0 },
      { dia: "Miercoles", num: 0 },
      { dia: "Jueves", num: 0 },
      { dia: "Viernes", num: 0 },
      { dia: "Sabado", num: 0 },
      { dia: "Domingo", num: 0 },
    ];

    dataIn.horarioFormato = [];

    for (let dia of semana) {
      for (var j = 0; j < dataIn.dias.length; j++) {
        if (dia.dia === dataIn.dias[j]) {
          dia.num = 1;
        }
      }
    }

    let flag = false;
    let aux3 = [];
    let aux2 = "";

    for (var b = 0; b < semana.length; b++) {
      aux2 = [];
      if (flag) {
        if (semana[b].num === 1) {
          aux2 = aux.split(" a ");
          aux = aux2[0] + " a " + semana[b].dia;
          if (b == 6 && semana[b].num === 1) {
            aux3.push(aux);
          }
        } else {
          aux = aux;
          aux3.push(aux);
          aux = "";
          flag = false;
        }
      } else {
        if (semana[b].num === 1 && b != 6) {
          if (aux3.length === 0) {
            aux = aux + semana[b].dia;
            flag = true;
          } else {
            aux = ". " + aux + semana[b].dia;
            flag = true;
          }
        } else {
          aux = aux;
        }
      }
    }
    dataIn.horarioFormato.push(aux3);
    //   }
    // }
    return dataIn;
  }

  isSelectedDateHoliday(date, holidays) {
    var day = new Date(date);
    day.setUTCHours(0);
    day.setUTCMinutes(0);
    day.setUTCSeconds(0);
    for (let holiday of holidays) {
      var auxDate = new Date(holiday.dia);
      if (day.toUTCString() === auxDate.toUTCString()) {
        return true;
      }
    }
    return false;
  }
}

export default new reservarFunctions();
