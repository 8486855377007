import { MaterialIcons } from "@expo/vector-icons";
import React, { Component } from "react";
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Linking,
} from "react-native";
import { SearchBar } from "react-native-elements";
import { ActivityIndicator } from "react-native-paper";
import MediaQuery from "react-native-web-responsive";
import FontAwesome from "react-native-vector-icons/FontAwesome5";
import { key, recaptcha3Key, urlMap } from "../../config/config";
import Map from "../Map/map";
import { BranchCard, BranchCardMobile } from "./branchCard.component";
import inicioFunctions from "./inicio.functions";
import InicioStyle from "./inicio.style";
import { SvgMapaRojo, SvgVerListaMapa, SvgLista } from "./inicio.svgs";
import { isSafari } from "react-device-detect";
import { connect } from 'react-redux';
import store from '../../redux/store';

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: 0,
      longitude: 0,
      dataSucursales: { branches: [] },
      isReady: false,
      search: "",
      buscar: false,
      locationError: false,
      branchState: false,
      selectedBranch: null,
      dimensions: { window, screen },
      listaVisible: true,
      mapaVisible: true,
      selectedFromList: false,
      selectedMarker: null,
      userInicial: null,
    };
  }

  useEffect = () => {
    Dimensions.addEventListener("change", onChange);
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  };
  userDataHandleCallback = (value) => {
    this.setState({userInicial: value})
  };
  notSelectedFromList = () => {
    this.setState({ selectedMarker: null, selectedFromList: false });
  };

  onChange = ({ window, screen }) => {
    this.setState({
      dimensions: { window, screen },
    });
  };

  loadScriptByURL = (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  }

  componentDidMount() {
    this.loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${recaptcha3Key}`, function () {
    });
    const urlParams = new URLSearchParams(window.location.search);
    const rutParam = urlParams.get("rut");
    const codigoParam = urlParams.get("codigo");
    if (rutParam && codigoParam) {
      this.props.navigation.navigate("SeguimientoTurnos", {
        rut: rutParam,
        codigo: codigoParam,
      });
    }
    this.loadBranches();
    // socketClient();
    if (this.state.dimensions.window.innerWidth < 1223) {
      this.listOnly();
    } else {
      this.mapAndList();
    }
    
    if(this.state.user)
    {
      this.setState({userInicial: this.props.user})
    }else{
    this.props.navigation.navigate("ModalComponent", {
      type: "datosIniciales",
      tipoFuncion: "pedir",
      userInicial: this.userDataHandleCallback
    });}
  }

  selectBranch(branch) {
    this.setState({
      selectedBranch: branch,
      selectedMarker: branch,
      selectedFromList: true,
    });
  }

  redirectSucursal() {
    this.props.navigation.navigate("Sucursal", {
      branch: this.state.selectedBranch,
      user: this.props.user,
      noGps: this.state.locationError,
    });
  }

  loadBranches() {
    inicioFunctions
      .getBranches()
      .then((branches) => {
        this.findCoordinates(branches)
          .then(async (coordinates) => {
            let branchesDistance = await inicioFunctions.addDistance(
              branches,
              coordinates.latitude,
              coordinates.longitude,
              true
            );
            let brancheSchedule = await inicioFunctions.scheduleFormat(
              branchesDistance
            );
            this.setState({
              latitude: coordinates.latitude,
              longitude: coordinates.longitude,
              dataSucursales: brancheSchedule,
              data: brancheSchedule,
              isReady: true,
            });
            store.dispatch({type: 'SET_GPS', payload: false});
          })
          .catch(async (error) => {
            let branchesDistance = await inicioFunctions.addDistance(
              branches,
              -33.4360892,
              -70.6446652,
              false
            );
            let brancheSchedule = await inicioFunctions.scheduleFormat(
              branchesDistance
            );
            this.setState({
              latitude: -33.4360892,
              longitude: -70.6446652,
              dataSucursales: brancheSchedule,
              data: brancheSchedule,
              locationError: true,
              isReady: true,
            });
            store.dispatch({type: 'SET_GPS', payload: true});
          });
      })
      .catch((error) => {
        alert("Error al cargar los datos de las sucursales");
      });
  }

  SearchFilterFunction(text) {
    const newData = this.state.dataSucursales.branches.filter(function (item) {
      const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
      //const textData = text.toUpperCase() == 'OFICINA MATRIZ 2' ? 'SANTIAGO CENTRO' : text.toUpperCase();
      const textData = text.toUpperCase();
      //if(textData.includes('OFICINA MATRIZ')){
      //  console.log('entro')
     // }
      return itemData.indexOf(textData) > -1;
      
    });
        for(let i = 0; i < newData.length; i++){
          if(newData[i].name.toUpperCase().includes('OFICINA MATRIZ') || text.toUpperCase() == 'OFICINA MATRIZ 2'){
            if (newData.filter(e=>e.name=='Santiago Centro').length == 0  ){
            this.state.dataSucursales.branches.find((branch) => branch.name == 'Santiago Centro') ?  newData.push(this.state.dataSucursales.branches.find((branch) => branch.name == 'Santiago Centro')) : null;

            }
            
          }}
          if(text.toUpperCase() == 'OFICINA MATRIZ 2' || text.toUpperCase() == 'OFICINA MATRIZ '){
            if (newData.filter(e=>e.name=='Santiago Centro').length == 0  ){
            this.state.dataSucursales.branches.find((branch) => branch.name == 'Santiago Centro') ?  newData.push(this.state.dataSucursales.branches.find((branch) => branch.name == 'Santiago Centro')) : null;

            }
          }
          
    console.log(newData)

    this.setState({
      data: { branches: newData },
      search: text,
      buscar: true,
    });
  }

  findCoordinates = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = JSON.stringify(position);

          let coordinates = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          resolve(coordinates);
        },
        (error) => reject(null),
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );
    });
  };

  mapOnly = () => {
    this.setState({ listaVisible: false, mapaVisible: true });
  };

  mapAndList = () => {
    this.setState({ listaVisible: true, mapaVisible: true });
  };

  listOnly = () => {
    this.setState({ listaVisible: true, mapaVisible: false });
  };

  render() {
  
    return (
      <View style={{ flex: 1 }}>
        <ScrollView style={{ flex: 1 }}>
          <View
            style={{
              maxWidth: 983,
              /*maxHeight: 908,*/ alignSelf: "center",
              width: "100%",
            }}
          >
            <MediaQuery minWidth={1224}>
              <View style={{ marginHorizontal: "17px", marginBottom: "28px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignSelf: "center",
                    width: "937px",
                    marginTop: "28px"
                  }}
                >
                  <View style={{ width: "302px" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <View
                        style={{
                          height: 22,
                          width: 22,
                          border: "8px solid #F42534",
                          borderRadius: 30,
                          marginRight: "10px",
                        }}
                      />
                      <View
                        style={{
                          height: 2,
                          width: "270px",
                          backgroundColor: "#d8d8d8",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                          marginRight: 10,
                        }}
                      ></View>
                    </View>
                    <Text style={InicioStyle.barraTextoRojo}>1. Sucursal</Text>
                  </View>
                  <View style={{ width: "302px", marginLeft: "10px" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <View
                        style={{
                          height: 22,
                          width: 22,
                          border: "2px solid #d8d8d8",
                          backgroundColor: "#FFF",
                          borderRadius: 30,
                          marginRight: "10px",
                        }}
                      />
                      <View
                        style={{
                          height: 2,
                          width: "272px",
                          backgroundColor: "#d8d8d8",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                          marginRight: 10,
                        }}
                      ></View>
                    </View>
                    <Text
                      style={[InicioStyle.barraTextoRojo, { color: "#d8d8d8" }]}
                    >
                      2. Agenda tu hora
                    </Text>
                  </View>
                  <View style={{ width: "306px", marginLeft: "10px" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <View
                        style={{
                          height: 22,
                          width: 22,
                          border: "2px solid #d8d8d8",
                          backgroundColor: "#FFF",
                          borderRadius: 30,
                          marginRight: "10px",
                        }}
                      />
                      <View style={{ flexDirection: "row", width: "90%" }}>
                        <View
                          style={{
                            height: 2,
                            width: "274px",
                            backgroundColor: "#d8d8d8",
                            marginTop: 10,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        ></View>
                        <View
                          style={{
                            height: "12px",
                            width: 3,
                            backgroundColor: "#d8d8d8",
                            borderRadius: "1.5px",
                            marginTop: 5,
                          }}
                        ></View>
                      </View>
                    </View>
                    <Text
                      style={[InicioStyle.barraTextoRojo, { color: "#d8d8d8" }]}
                    >
                      3. Comprobante
                    </Text>
                  </View>
                </View>
              </View>
            </MediaQuery>

            <MediaQuery maxWidth={1223}>
              <View style={{ paddingHorizontal: "10px", marginBottom: "15px" }}>

                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    alignSelf: "center",
                    marginTop: "10px",
                  }}
                >
                  <View style={{ width: "100%" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <View
                        style={{
                          height: 22,
                          width: 22,
                          border: "8px solid #F42534",
                          borderRadius: 30,
                        }}
                      />
                      <View
                        style={{
                          height: 2,
                          width: "94%",
                          backgroundColor: "#d8d8d8",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                        }}
                      ></View>
                    </View>
                    <Text
                      style={[InicioStyle.barraTextoRojo, { color: "#F42534" }]}
                    >
                      1. Sucursal
                    </Text>
                  </View>
                </View>
              </View>
            </MediaQuery>

            <View>
              <MediaQuery minWidth={1224}>
                <View style={InicioStyle.containerBarraEleccion}>
                  <View
                    style={{
                      marginTop: "23px",
                      width: "411px",
                      marginLeft: "35.34px",
                      marginBottom: "20.19px",
                    }}
                  >
                    <Text style={InicioStyle.barraEleccion}>
                      Elige la sucursal más cercana a ti
                    </Text>
                    <Text style={InicioStyle.barraSubtitulo}>
                      Puedes hacerlo desde el mapa o desde la lista de
                      sucursales.
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: "26px",
                    }}
                  >
                    {!(this.state.listaVisible && this.state.mapaVisible) && (
                      <TouchableOpacity
                        style={{ marginRight: 28 }}
                        onPress={() => {
                          this.mapAndList();
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#FFF",
                            flexDirection: "row",
                          }}
                        >
                          <SvgVerListaMapa width={28} height={28} />
                          <Text style={InicioStyle.barraOpciones}>
                            {" "}
                            Ver lista y mapa
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                    {/*this.state.mapaVisible && !this.state.listaVisible && (*/}
                    {this.state.mapaVisible && (
                      <TouchableOpacity
                        style={{ marginRight: 28 }}
                        onPress={() => {
                          this.listOnly();
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#FFF",
                            flexDirection: "row",
                          }}
                        >
                          <SvgLista width={28} height={28} />
                          <Text style={InicioStyle.barraOpciones}>
                            {" "}
                            Elegir desde lista
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                    {this.state.listaVisible && (
                      <TouchableOpacity
                        style={{ marginRight: 28 }}
                        onPress={() => {
                          this.mapOnly();
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#FFF",
                            flexDirection: "row",
                          }}
                        >
                          <SvgMapaRojo width={28} height={28} />
                          <Text style={InicioStyle.barraOpciones}>
                            {" "}
                            Elegir desde el mapa
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </MediaQuery>

              <MediaQuery maxWidth={1223}>
                <View style={InicioStyle.containerBarraEleccionMobile}>
                  <View
                    style={{
                      alignSelf: "flex-start",
                      paddingBottom: 10,
                      paddingRight: 10,
                      width: "100%",
                    }}
                  >
                    <Text style={[InicioStyle.barraEleccion, { fontSize: 20 }]}>
                      Elige la sucursal más cercana a ti
                    </Text>
                    <Text style={InicioStyle.barraSubtitulo}>
                      Puedes hacerlo desde el mapa o desde la lista de
                      sucursales.
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignSelf: "flex-end",
                      justifyContent: "flex-end",
                      display: 'table-row',
                    }}
                  >
                    {this.state.mapaVisible && (
                      <TouchableOpacity
                        onPress={() => {
                          this.listOnly();
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#FFF",
                            flexDirection: "row",
                          }}
                        >
                          <SvgLista width={24} height={24}/>
                          <Text
                            style={[
                              InicioStyle.barraOpciones,
                              { fontSize: 14 },
                            ]}
                          >
                            {" "}
                            Elegir desde lista
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}

                    {this.state.listaVisible && (
                      <TouchableOpacity
                        onPress={() => {
                          this.mapOnly();
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#FFF",
                            flexDirection: "row",
                          }}
                        >
                          <SvgMapaRojo width={24} height={24}/>
                          <Text
                            style={[
                              InicioStyle.barraOpciones,
                              { fontSize: 14 },
                            ]}
                          >
                            {" "}
                            Elegir desde el mapa
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </MediaQuery>
            </View>
            <View style={{ flex: 1 }}>
              <View
                style={
                  {
                    /*backgroundColor: "#FFF",
              minHeight: this.state.dimensions.window.innerHeight * 0.53,
              maxHeight: this.state.dimensions.window.innerHeight * 0.53,*/
                  }
                }
              >
                <MediaQuery minWidth={1224}>
                  <View style={InicioStyle.contenedor}>
                    <View
                      style={
                        this.state.listaVisible
                          ? { flex: 2 }
                          : {
                              flex: 0,
                              maxHeight:
                                this.state.dimensions.window.innerHeight * 0.3,
                              display: 'none',
                            }
                      }
                    >
                      <View
                        style={{
                          paddingBottom: "34px",
                          paddingTop: "30px",
                          paddingLeft: "32px",
                          paddingRight: "30px",
                        }}
                      >
                        <Text style={InicioStyle.searchText}>
                          Busca la sucursal que te acomoda
                        </Text>
                        <SearchBar
                          inputContainerStyle={InicioStyle.serachBarInput}
                          containerStyle={InicioStyle.searchBarContainer}
                          lightTheme
                          style={InicioStyle.searchTextInput}
                          round={true}
                          searchIcon={{ size: 16 }}
                          placeholderTextColor={"#9C9A9F"}
                          onChangeText={(text) =>
                            this.SearchFilterFunction(text)
                          }
                          onClear={(text) => this.SearchFilterFunction("")}
                          placeholder="Escribe por nombre o zona"
                          value={this.state.search}
                        />
                      </View>
                      <View style={InicioStyle.separadorSearch} />
                      <View
                        style={{
                          paddingVertical: "31px",
                          paddingHorizontal: "32px",
                        }}
                      >
                        {!this.state.isReady ? (
                          <ActivityIndicator></ActivityIndicator>
                        ) : this.state.dataSucursales.branches.length === 0 ? (
                          <View>
                            <Text
                              style={[
                                InicioStyle.barraSubtitulo,
                                {
                                  fontSize: 16,
                                  alignSelf: "center",
                                  marginVertical: 10,
                                  textAlign: 'center',
                                },
                              ]}
                            >
                              No se encontraron sucursales disponibles
                            </Text>
                          </View>
                        ) : (
                          <View
                            style={
                              this.state.listaVisible
                                ? {
                                    minHeight:
                                      this.state.dimensions.window.innerHeight *
                                      0.38,
                                    maxHeight:
                                      this.state.dimensions.window.innerHeight *
                                      0.38,
                                  }
                                : { display: "none" }
                            }
                          >
                            <ScrollView
                              style={
                                {
                                  /*paddingBottom: 20*/
                                }
                              }
                            >
                              <BranchCard
                                navigation={this.props.navigation}
                                branches={this.state.data.branches}
                                locationError={this.state.locationError}
                                selectBranch={(name) => this.selectBranch(name)}
                              ></BranchCard>
                            </ScrollView>
                          </View>
                        )}
                      </View>
                    </View>
                    <View
                      style={
                        this.state.listaVisible && this.state.mapaVisible
                          ? { flex: 2 }
                          : this.state.listaVisible && !this.state.mapaVisible
                          ? {
                              flex: 0,
                            }
                          : {
                              flex: 1,
                              height:
                                this.state.dimensions.window.innerHeight * 0.6,
                            }
                      }
                    >
                      {this.state.latitude === 0 ? (
                        <>
                          <MaterialIcons
                            name="gps-off"
                            size={250}
                            color="#f8f9fa"
                            style={{ alignSelf: "center", top: "25%" }}
                          />
                          <Text style={{ textAlign: "center", color: "grey" }}>
                            Debes Activar o Autorizar el uso del GPS. Esta
                            información es utilizada para ayudarte a encontrar
                            la sucursal más cercana.
                          </Text>
                        </>
                      ) : (
                        <Map
                          navigation={this.props.navigation}
                          googleMapURL={urlMap + key}
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `100%` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          isListVisible={this.state.listaVisible}
                          locationError={this.state.locationError}
                          center={
                            this.state.selectedMarker === null
                              ? {
                                  lat: this.state.latitude,
                                  lng: this.state.longitude,
                                }
                              : {
                                  lat: this.state.selectedMarker.lat,
                                  lng: this.state.selectedMarker.long,
                                }
                          }
                          dataSuc={this.state.dataSucursales}
                          selectedMarker={this.state.selectedMarker}
                          toggleSelected={() => this.notSelectedFromList()}
                          userData={this.props.user}
                        />
                      )}
                    </View>
                  </View>
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
                  <View style={InicioStyle.contenedor2}>
                    {this.state.listaVisible ? (
                      <>
                        <View
                          style={[InicioStyle.rowDirection, { padding: 10 }]}
                        >
                          <View style={InicioStyle.searchBarView}>
                            <Text style={InicioStyle.searchText}>
                              Busca la sucursal que te acomoda
                            </Text>
                            <SearchBar
                              inputContainerStyle={[
                                InicioStyle.serachBarInput,
                                { maxHeight: 35 },
                              ]}
                              style={{outlineWidth: 0}}
                              containerStyle={InicioStyle.searchBarContainer}
                              lightTheme
                              round={true}
                              searchIcon={{ size: 20 }}
                              onChangeText={(text) =>
                                this.SearchFilterFunction(text)
                              }
                              onClear={() => this.SearchFilterFunction("")}
                              placeholder="Busca tu sucursal..."
                              value={this.state.search}
                            />
                          </View>
                        </View>
                        <View style={InicioStyle.separadorSearch} />
                        {!this.state.isReady ? (
                          <ActivityIndicator></ActivityIndicator>
                        ) : this.state.dataSucursales.branches.length === 0 ? (
                          <View>
                            <Text
                              style={[
                                InicioStyle.barraSubtitulo,
                                {
                                  fontSize: 16,
                                  alignSelf: "center",
                                  marginVertical: 10,
                                  textAlign: 'center',
                                },
                              ]}
                            >
                              No se encontraron sucursales disponibles
                            </Text>
                          </View>
                        ) : (
                          <View
                            style={{
                              padding: 10,
                              /*minHeight:
                            this.state.dimensions.window.innerHeight * 0.37,
                          maxHeight:
                          this.state.dimensions.window.innerHeight * 0.37,*/
                            }}
                          >
                            {/*<ScrollView>*/}
                            <BranchCardMobile
                              navigation={this.props.navigation}
                              branches={this.state.data.branches}
                              locationError={this.state.locationError}
                              selectBranch={(name) => this.selectBranch(name)}
                            />
                            {/*</ScrollView>*/}
                          </View>
                        )}
                      </>
                    ) : (
                      <View
                        style={{
                          width: "100%",
                          minHeight:
                            !isSafari && this.state.dimensions.window.innerHeight * 0.37,
                          maxHeight:
                            !isSafari && this.state.dimensions.window.innerHeight * 0.37,
                          height: isSafari && '400px',
                        }}
                      >
                        <Map
                          navigation={this.props.navigation}
                          googleMapURL={urlMap + key}
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `100%` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          isListVisible={this.state.listaVisible}
                          locationError={this.state.locationError}
                          center={
                            this.state.selectedMarker === null
                              ? {
                                  lat: this.state.latitude,
                                  lng: this.state.longitude,
                                }
                              : {
                                  lat: this.state.selectedMarker.lat,
                                  lng: this.state.selectedMarker.long,
                                }
                          }
                          dataSuc={this.state.dataSucursales}
                          selectedMarker={this.state.selectedMarker}
                          toggleSelected={() => this.notSelectedFromList()}
                          userData={this.props.user}
                        />
                      </View>
                    )}
                  </View>
                </MediaQuery>
              </View>
            </View>
            <MediaQuery minWidth={1224}>
              <View style={InicioStyle.containerAgendarVolver}>
                <TouchableOpacity
                  style={InicioStyle.botonCancelarVolver}
                  onPress={() => {
                    Linking.openURL("https://www.coopeuch.cl");
                  }}
                >
                  <Text style={InicioStyle.botonCancelarVolver}>
                    <FontAwesome
                      name="chevron-left"
                      style={{ fontSize: 16, marginRight: 8 }}
                    />{" "}
                    Volver a Coopeuch.cl
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.redirectSucursal()}
                  disabled={this.state.selectedBranch === null}
                >
                  <Text
                    style={
                      this.state.selectedBranch === null
                        ? InicioStyle.botonDisabled
                        : InicioStyle.botonAgendar
                    }
                  >
                    {this.state.selectedBranch === null
                      ? "Continuar"
                      : "Agendar hora"}
                  </Text>
                </TouchableOpacity>
              </View>
            </MediaQuery>
            {this.state.selectedBranch === null && (
              <MediaQuery maxWidth={1223}>
                <View style={InicioStyle.containerAgendarVolver}>
                  <TouchableOpacity
                    style={InicioStyle.botonCancelarVolver}
                    onPress={() => {
                      Linking.openURL("https://www.coopeuch.cl");
                    }}
                  >
                    <Text style={[InicioStyle.botonCancelarVolver, {fontSize: 14}]}>
                      <FontAwesome
                        name="chevron-left"
                        style={{ fontSize: 16, marginRight: 8 }}
                      />{" "}
                      Volver a Coopeuch.cl
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => this.redirectSucursal()}
                    disabled={this.state.selectedBranch === null}
                  >
                    <Text
                      style={
                        this.state.selectedBranch === null
                          ? [
                              InicioStyle.botonDisabled,
                              { paddingHorizontal: "15px", fontSize: 14 },
                            ]
                          : [
                              InicioStyle.botonAgendar,
                              { paddingHorizontal: "15px", fontSize: 14 },
                            ]
                      }
                    >
                      {this.state.selectedBranch === null
                        ? "Continuar"
                        : "Agendar hora"}
                    </Text>
                  </TouchableOpacity>
                </View>
              </MediaQuery>
            )}
          </View>
        </ScrollView>
        {this.state.selectedBranch !== null && this.state.listaVisible && (
          <MediaQuery maxWidth={1223}>
            <View
              style={{
                backgroundColor: "#F2F2F2",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                maxHeight: 50,
              }}
            >
              <TouchableOpacity
                style={InicioStyle.botonCancelarVolver}
                onPress={() => {
                  Linking.openURL("https://www.coopeuch.cl");
                }}
              >
                <Text
                  style={{
                    fontFamily: "Ubuntu-Medium",
                    alignItems: "center",
                    fontSize: 14,
                    color: "#e81e2b",
                  }}
                >
                  <FontAwesome
                    name="chevron-left"
                    style={{ fontSize: 12, marginRight: 4 }}
                  />
                  Volver a Coopeuch.cl
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.redirectSucursal()}
                disabled={this.state.selectedBranch === null}
              >
                <Text
                  style={{
                    fontFamily: "Ubuntu-Medium",
                    color: "white",
                    backgroundColor: "#e81e2b",
                    paddingVertical: 10,
                    paddingHorizontal: 10,
                    borderRadius: 5,
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  {this.state.selectedBranch === null
                    ? "Continuar"
                    : "Agendar hora"}
                </Text>
              </TouchableOpacity>
            </View>
          </MediaQuery>
        )}
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    noGps: state.noGps,
    user: state.user
  }
}
export default connect(mapStateToProps)(Inicio);
