import React from "react";
import { Text, View, Image, Dimensions } from "react-native";
import MediaQuery from "react-native-web-responsive";
import FontAwesome from "react-native-vector-icons/FontAwesome5";

function Recomendations(props) {
  return (
    <View>
      <View
        style={{
          flex: 1,
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-end",
          marginBottom: "30px",
          marginTop: "10px",
        }}
      >
        <MediaQuery minWidth={600}>
            <View style={{ alignItems: "center" }} >
                <Image
                    style={{ height: 158, width: 151, marginVertical: Dimensions.get('window').height < 700 ? 10 : 20, }}
                    source={require("../../../../assets/slider2.png")}
                />
            </View>
            <Text
              style={{
                color: "#383638",
                fontSize: 18,
                fontFamily: "Ubuntu-Bold",
                marginBottom: "4%",
                textAlign: "center",
              }}
            >
              Recomendaciones para tu visita
            </Text>
        </MediaQuery>
        
        <MediaQuery maxWidth={599}>
            
            <Text
            style={{
                color: "#383638",
                fontSize: 18,
                fontFamily: "Ubuntu-Bold",
                marginTop: "30px",
                textAlign: "center",
            }}
            >
            Recomendaciones para tu visita
            </Text>
        </MediaQuery>

        <Text
          style={{
            textAlign: "center",
            color: "#383638",
            fontSize: 14,
            fontFamily: "Ubuntu-Regular",
            lineHeight: 20,
            marginBottom: "2%",
            marginTop: "4%",
          }}
        >
          <FontAwesome
            name="check"
            style={{
                fontSize: 14,
                color: "#e81e2b",
            }}
          />{'  '}
          Te recomendamos llegar 10 minutos antes de la hora agendada.
        </Text>
        
        <Text
          style={{
            textAlign: "center",
            color: "#383638",
            fontSize: 14,
            fontFamily: "Ubuntu-Regular",
            lineHeight: 20,
            marginBottom: "2%",
          }}
        >
          <FontAwesome
            name="check"
            style={{
                fontSize: 14,
                color: "#e81e2b",
            }}
          />{'  '}
          Recuerda mostrar tu comprobante en la sucursal al momento de llegar.
        </Text>
        
        <Text
          style={{
            textAlign: "center",
            color: "#383638",
            fontSize: 14,
            fontFamily: "Ubuntu-Regular",
            lineHeight: 20,
            marginBottom: "2%",
          }}
        >
          <FontAwesome
            name="check"
            style={{
                fontSize: 14,
                color: "#e81e2b",
            }}
          />{'  '}
          Si llegas 15 minutos después de la hora agendada no se considerará el agendamiento.
        </Text>
      </View>
    </View>
  );
}

export default Recomendations;
