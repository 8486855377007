import { StyleSheet } from "react-native";


export default StyleSheet.create({
    tramite: { flexDirection: 'column', flex: 2, height: '100%', backgroundColor: '#d9d9d9' },
    titulo: { fontWeight: 'bold', paddingBottom: 10 },
    description: { paddingBottom: 10, },
    reqTitle: { paddingBottom: 10, },
    requisitos: { paddingBottom: 10, },
    tramTitle: { paddingBottom: 10 }



});