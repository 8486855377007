import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import branchStateFunction from "../Utils/branchState.function";
import InicioStyle from "./inicio.style";
import { List, RadioButton } from "react-native-paper";
import { SvgDistancia, SvgReloj } from "./branchCard.svgs";
import inicioFunctions from "./inicio.functions";

function BranchCard(props) {
  const [checked, setChecked] = React.useState("");

  function selectBranch(branch) {
    props.selectBranch(branch);
  }

  return props.branches.map((branches) => (
    <TouchableOpacity key={branches._id}>
      <List.Section style={{ width: "100%" }}>
        <List.Accordion
          titleNumberOfLines={5}
          onPress={() => {
            setChecked(branches.name);
            selectBranch(branches);
          }}
          title={
            <View style={{ flexDirection: "column" }}>
              <Text style={InicioStyle.textSucursalPrevio}>
                Sucursal
                <Text style={InicioStyle.textSucursal}>{branches.name == 'Santiago Centro' ? 'Oficina Matriz 2' : branches.name}</Text>
              </Text>
              <Text
                style={[
                  InicioStyle.textInformationCard,
                  { textTransform: "capitalize" },
                ]}
              >
                {/*' '*/}
                {branches.address}, {branches.comuna}
              </Text>
              {branchStateFunction(branches.horarios, branches.name) &&
              !inicioFunctions.isTodayHoliday(branches.feriados) ? (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "3px",
                    marginBottom: "1px",
                  }}
                >
                  <Text style={InicioStyle.branchStateOpen}>Abierto</Text>
                </View>
              ) : (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "3px",
                    marginBottom: "1px",
                  }}
                >
                  <Text style={InicioStyle.branchStateClose}>Cerrado</Text>
                </View>
              )}
            </View>
          }
          style={{
            backgroundColor: "white",
            width: "100%",
            paddingRight: 0,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: "#e2e2e2",
            paddingLeft: 0,
          }}
          left={(props) => (
            <RadioButton
              {...props}
              value={branches.name}
              status={checked === branches.name ? "checked" : "unchecked"}
              color="#007db7"
              onPress={() => {
                setChecked(branches.name);
                selectBranch(branches);
              }}
            />
          )}
        >
          <List.Item
            style={{
              backgroundColor: "white",
              width: "100%",
              paddingLeft: 30,
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderBottomWidth: 1,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              borderColor: "#e2e2e2",
            }}
            titleStyle={{
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
            titleNumberOfLines={5}
            title={
              <View>
                {/* <View style={InicioStyle.queueName}>
                  <SvgTelefono width={18} />
                  <Text style={InicioStyle.textTagsCard}>Teléfono: </Text>
                  <Text style={InicioStyle.textInformationCard}>
                    {branches.phone}
                  </Text>
                </View> */}
                {branches.horarios && (
                  <View style={{ alignSelf: "flex-start" }}>
                    <View
                      style={{
                        display: "table-row",
                        flexWrap: "wrap",
                        width: "100%",
                        paddingRight: "10px",
                      }}
                    >
                      <SvgReloj width={16} height={16} />
                      <Text style={InicioStyle.textTagsCard}>
                        Horarios de Atenci&oacute;n:{" "}
                      </Text>
                      {branches.horarios.map((horarios) => {
                        return horarios.inicioBreak !== "Sin Break" ? (
                          <Text
                            key={horarios._id}
                            style={InicioStyle.textInformationCard}
                          >
                            {horarios.horarioFormato} de {horarios.horaApertura}{" "}
                            Hrs a {horarios.inicioBreak} Hrs y{" "}
                            {horarios.terminoBreak} Hrs a {horarios.horaCierre}{" "}
                            Hrs.{" "}
                          </Text>
                        ) : (
                          <Text
                            key={horarios._id}
                            style={InicioStyle.textInformationCard}
                          >
                            {horarios.horarioFormato} de {horarios.horaApertura}{" "}
                            Hrs a {horarios.horaCierre} Hrs.
                          </Text>
                        );
                      })}
                    </View>
                  </View>
                )}
                {!props.locationError && (
                  <View
                    style={{
                      display: "table-row",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <SvgDistancia width={16} height={16} />
                    <Text style={InicioStyle.textTagsCard}>Distancia: </Text>
                    <Text style={InicioStyle.textInformationCard}>
                      {branches.distance} km.
                    </Text>
                  </View>
                )}
              </View>
            }
          />
        </List.Accordion>
      </List.Section>
    </TouchableOpacity>
  ));
}

function BranchCardMobile(props) {
  const [checked, setChecked] = React.useState("");

  function selectBranch(branch) {
    props.selectBranch(branch);
  }

  return props.branches.map((branches) => (
    <TouchableOpacity key={branches._id}>
      <List.Section style={{ width: "100%" }}>
        <List.Accordion
          titleNumberOfLines={5}
          onPress={() => {
            setChecked(branches.name);
            selectBranch(branches);
          }}
          title={
            <View>
              <Text style={InicioStyle.textSucursalPrevio}>
                <Text style={[InicioStyle.textSucursal, { marginLeft: 0 }]}>
                  {branches.name == 'Santiago Centro' ? 'Oficina Matriz 2' : branches.name}
                </Text>
                {branchStateFunction(branches.horarios, branches.name) &&
                !inicioFunctions.isTodayHoliday(branches.feriados) ? (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "3px",
                      marginBottom: "1px",
                    }}
                  >
                    <Text style={InicioStyle.branchStateOpen}>Abierto</Text>
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "3px",
                      marginBottom: "1px",
                    }}
                  >
                    <Text style={InicioStyle.branchStateClose}>Cerrado</Text>
                  </View>
                )}
              </Text>
            </View>
          }
          description={
            <Text
              style={[
                InicioStyle.textInformationCard,
                { textTransform: "capitalize" },
              ]}
            >
              {branches.address}, {branches.comuna}
            </Text>
          }
          style={{
            backgroundColor: "white",
            width: "100%",
            paddingRight: 0,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: "#e2e2e2",
            paddingLeft: 0,
          }}
          left={(props) => (
            <RadioButton
              {...props}
              value={branches.name}
              status={checked === branches.name ? "checked" : "unchecked"}
              color="#007db7"
              onPress={() => {
                setChecked(branches.name);
                selectBranch(branches);
              }}
            />
          )}
        >
          <List.Item
            style={{
              backgroundColor: "white",
              width: "100%",
              paddingLeft: 30,
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderBottomWidth: 1,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              borderColor: "#e2e2e2",
            }}
            titleStyle={{
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
            titleNumberOfLines={5}
            title={
              <View>
                {/* <View style={InicioStyle.queueName}>
                  <SvgTelefono width={18} />
                  <Text style={InicioStyle.textTagsCard}>Teléfono: </Text>
                  <Text style={InicioStyle.textInformationCard}>
                    {branches.phone}
                  </Text>
                </View> */}
                {branches.horarios && (
                  <View style={{ alignSelf: "flex-start" }}>
                    <View
                      style={{
                        display: "table-row",
                        flexWrap: "wrap",
                        width: "100%",
                        paddingRight: "10px",
                      }}
                    >
                      <SvgReloj width={16} height={16} />
                      <Text style={InicioStyle.textTagsCard}>
                        Horarios de Atenci&oacute;n:{" "}
                      </Text>
                      {branches.horarios.map((horarios) => {
                        return horarios.inicioBreak !== "Sin Break" ? (
                          <Text
                            key={horarios._id}
                            style={InicioStyle.textInformationCard}
                          >
                            {horarios.horarioFormato} de {horarios.horaApertura}{" "}
                            Hrs a {horarios.inicioBreak} Hrs y{" "}
                            {horarios.terminoBreak} Hrs a {horarios.horaCierre}{" "}
                            Hrs.{" "}
                          </Text>
                        ) : (
                          <Text
                            key={horarios._id}
                            style={InicioStyle.textInformationCard}
                          >
                            {horarios.horarioFormato} de {horarios.horaApertura}{" "}
                            Hrs a {horarios.horaCierre} Hrs.
                          </Text>
                        );
                      })}
                    </View>
                  </View>
                )}
                {!props.locationError && (
                  <View
                    style={{
                      display: "table-row",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <SvgDistancia width={16} height={16} />
                    <Text style={InicioStyle.textTagsCard}>Distancia: </Text>
                    <Text style={InicioStyle.textInformationCard}>
                      {branches.distance} km.
                    </Text>
                  </View>
                )}
              </View>
            }
          />
        </List.Accordion>
      </List.Section>
    </TouchableOpacity>
  ));
}

export { BranchCard, BranchCardMobile };
