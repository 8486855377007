import * as React from 'react';
import Svg, { G, Path, Defs, Mask, ClipPath, Use } from "react-native-svg"


function SvgDistancia(props) {
  
  return (
      
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      {...props}
    >
      <Defs>
        <Path
          id="map_distancia__a"
          d="M3.4 8.4c.1-.1 2.1-2.2 2.1-5.3C5.5 1.7 4.4.5 3 .5S.5 1.7.5 3.1c0 3.1 2.1 5.2 2.1 5.3.2.1.6.1.8 0M3 1.5c.8 0 1.5.7 1.5 1.6 0 1.9-1 3.4-1.5 4.1-.5-.7-1.5-2.2-1.5-4.1 0-.9.7-1.6 1.5-1.6"
        />
      </Defs>
      <ClipPath id="map_distancia__b">
        <Use xlinkHref="#map_distancia__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#map_distancia__b)">
        <Defs>
          <Path id="map_distancia__c" d="M0 0h16v16H0z" />
        </Defs>
        <ClipPath id="map_distancia__d">
          <Use xlinkHref="#map_distancia__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#map_distancia__d)"
          fill="#007db7"
          d="M-4.5-4.5h15v18h-15z"
        />
      </G>
      <Defs>
        <Path
          id="map_distancia__e"
          d="M3.5 3c0-.3-.2-.5-.5-.5s-.5.2-.5.5.2.5.5.5.5-.2.5-.5"
        />
      </Defs>
      <ClipPath id="map_distancia__f">
        <Use xlinkHref="#map_distancia__e" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#map_distancia__f)">
        <Defs>
          <Path id="map_distancia__g" d="M0 0h16v16H0z" />
        </Defs>
        <ClipPath id="map_distancia__h">
          <Use xlinkHref="#map_distancia__g" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#map_distancia__h)"
          fill="#007db7"
          d="M-2.5-2.5h11v11h-11z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="map_distancia__i"
            d="M15.5 6.1c0-1.4-1.1-2.6-2.5-2.6s-2.5 1.2-2.5 2.6c0 3.1 2.1 5.2 2.1 5.3 1 .9 2.9-3 2.9-5.3M13 4.5c.8 0 1.5.7 1.5 1.6 0 1.9-1 3.4-1.5 4.1-.5-.7-1.5-2.2-1.5-4.1 0-.9.7-1.6 1.5-1.6"
          />
        </Defs>
        <ClipPath id="map_distancia__j">
          <Use xlinkHref="#map_distancia__i" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#map_distancia__j)">
          <Defs>
            <Path id="map_distancia__k" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="map_distancia__l">
            <Use xlinkHref="#map_distancia__k" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#map_distancia__l)"
            fill="#007db7"
            d="M5.5-1.5h15v18h-15z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="map_distancia__m"
            d="M13.5 6c0-.3-.2-.5-.5-.5s-.5.2-.5.5.2.5.5.5.5-.2.5-.5"
          />
        </Defs>
        <ClipPath id="map_distancia__n">
          <Use xlinkHref="#map_distancia__m" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#map_distancia__n)">
          <Defs>
            <Path id="map_distancia__o" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="map_distancia__p">
            <Use xlinkHref="#map_distancia__o" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#map_distancia__p)"
            fill="#007db7"
            d="M7.5.5h11v11h-11z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="map_distancia__q"
            d="M13 12.5c-.6 0-1.1.3-1.3.8l-7.2-2.7C4.3 10 3.7 9.5 3 9.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c.6 0 1.1-.3 1.3-.8l7.2 2.7c.2.7.7 1.1 1.4 1.1.8 0 1.5-.7 1.5-1.5s-.6-1.5-1.4-1.5m-10-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5m10 3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5"
          />
        </Defs>
        <ClipPath id="map_distancia__r">
          <Use xlinkHref="#map_distancia__q" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#map_distancia__r)">
          <Defs>
            <Path id="map_distancia__s" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="map_distancia__t">
            <Use xlinkHref="#map_distancia__s" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#map_distancia__t)"
            fill="#007db7"
            d="M-3.5 4.5h23v16h-23z"
          />
        </G>
      </G>
    </Svg>
    
  );
}


function SvgReloj(props) {
  
  return (
      
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      {...props}
    >
      <Defs>
        <Path
          id="map_reloj__a"
          d="M13.3 2.7C10.4-.2 5.6-.2 2.7 2.7c-2.9 2.9-2.9 7.7 0 10.6 2.9 2.9 7.7 2.9 10.6 0 2.9-2.9 2.9-7.7 0-10.6m-.7 9.9c-2.5 2.5-6.6 2.5-9.2 0-2.5-2.5-2.5-6.7 0-9.2C5.9.9 10 .9 12.6 3.4c2.5 2.5 2.5 6.7 0 9.2"
        />
      </Defs>
      <ClipPath id="map_reloj__b">
        <Use xlinkHref="#map_reloj__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#map_reloj__b)">
        <Defs>
          <Path id="map_reloj__c" d="M0 0h16v16H0z" />
        </Defs>
        <ClipPath id="map_reloj__d">
          <Use xlinkHref="#map_reloj__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#map_reloj__d)"
          fill="#007db7"
          d="M-4.5-4.5h25v25h-25z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="map_reloj__e"
            d="M8.5 7.8V3c0-.3-.2-.5-.5-.5s-.5.2-.5.5v5c0 .1.1.3.1.4l2 2c.2.2.5.2.7 0 .2-.2.2-.5 0-.7L8.5 7.8z"
          />
        </Defs>
        <ClipPath id="map_reloj__f">
          <Use xlinkHref="#map_reloj__e" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#map_reloj__f)">
          <Defs>
            <Path id="map_reloj__g" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="map_reloj__h">
            <Use xlinkHref="#map_reloj__g" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#map_reloj__h)"
            fill="#007db7"
            d="M2.5-2.5h13v18h-13z"
          />
        </G>
      </G>
    </Svg>
    
  );
}


function SvgTelefono(props) {
  
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      {...props}
    >
      <Defs>
        <Path
          id="map_telefono__a"
          d="M3.8 6.5c1.4 0 2.5-1.1 2.5-2.5 0-.6-.2-1.1-.5-1.5h4.5c-.4.4-.6.9-.6 1.5 0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5c0-1.6-1.5-2.5-2.2-2.5h-9c-.7 0-2.2.8-2.2 2.5 0 1.4 1.1 2.5 2.5 2.5M13.7 4c0 2-3 2-3 0 0-.8.7-1.5 1.5-1.5.9 0 1.5.7 1.5 1.5M3.8 2.5c.4 0 .8.2 1.1.4.2.3.4.7.4 1.1 0 2-3 2-3 0 0-.8.6-1.5 1.5-1.5"
        />
      </Defs>
      <ClipPath id="map_telefono__b">
        <Use xlinkHref="#map_telefono__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#map_telefono__b)">
        <Defs>
          <Path id="map_telefono__c" d="M0 0h16v16H0z" />
        </Defs>
        <ClipPath id="map_telefono__d">
          <Use xlinkHref="#map_telefono__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#map_telefono__d)"
          fill="#007db7"
          d="M-3.7-3.5h23.5v15H-3.7z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="map_telefono__e"
            d="M13.6 8.1l-3.3-.9-.9-1.5c-.1-.1-.2-.2-.4-.2H7c-.2 0-.3.1-.4.2l-.9 1.6-3.3.8C1.3 8.4.5 9.4.5 10.5V13c0 .8.7 1.5 1.5 1.5h12c.8 0 1.5-.7 1.5-1.5v-2.5c0-1.1-.8-2.1-1.9-2.4zm.9 4.9c0 .3-.2.5-.5.5H2c-.3 0-.5-.2-.5-.5v-2.5c0-.7.5-1.3 1.1-1.5l3.5-.9c.1 0 .2-.1.3-.2l.9-1.5h1.4L9.6 8c.1.1.2.2.3.2l3.5.9c.7.2 1.1.8 1.1 1.5V13z"
          />
        </Defs>
        <ClipPath id="map_telefono__f">
          <Use xlinkHref="#map_telefono__e" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#map_telefono__f)">
          <Defs>
            <Path id="map_telefono__g" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="map_telefono__h">
            <Use xlinkHref="#map_telefono__g" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#map_telefono__h)"
            fill="#007db7"
            d="M-4.5.5h25v19h-25z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="map_telefono__i"
            d="M8 8.5c-1.2 0-2.2 1-2.2 2.2S6.8 13 8 13s2.2-1 2.2-2.2-1-2.3-2.2-2.3M8 12c-.7 0-1.2-.6-1.2-1.2S7.3 9.5 8 9.5s1.2.6 1.2 1.2S8.7 12 8 12"
          />
        </Defs>
        <ClipPath id="map_telefono__j">
          <Use xlinkHref="#map_telefono__i" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#map_telefono__j)">
          <Defs>
            <Path id="map_telefono__k" d="M0 0h16v16H0z" />
          </Defs>
          <ClipPath id="map_telefono__l">
            <Use xlinkHref="#map_telefono__k" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#map_telefono__l)"
            fill="#007db7"
            d="M.8 3.5h14.5V18H.8z"
          />
        </G>
      </G>
    </Svg>
    
  );
}


export { SvgDistancia, SvgReloj, SvgTelefono }