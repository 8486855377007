export default function CapitalizedText(textoMinuscula) {
      let words = textoMinuscula.split(" ");
      let text = "";
      
      words.map(palabras =>
        text += palabras.slice(0,1).toUpperCase() + palabras.slice(1, palabras.length) + " "
      )
      
      return text.slice(0, -1);
    }
