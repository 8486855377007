import { StyleSheet, Platform } from "react-native";
import * as Font from 'expo-font';


Font.loadAsync({
  'Ubuntu-Light': require('../../../assets/fonts/Ubuntu-Light.ttf'),
  'Ubuntu-Bold': require('../../../assets/fonts/Ubuntu-Bold.ttf'),
  'Ubuntu-Regular': require('../../../assets/fonts/Ubuntu-Regular.ttf'),
  'Ubuntu-Medium': require('../../../assets/fonts/Ubuntu-Medium.ttf'),
})


export default StyleSheet.create({
  flexDMarg:{ flexDirection: 'row', margin: 10 },
  tuTurno: {  flexDirection: 'column',  
  padding: 6, alignItems: 'center',
  flex:1,backgroundColor: '#333746',
  borderRadius:10, width: 115
},
  row:{flexDirection: Platform.OS=='web'? 'row' : 'column' },
  contacto:{ flexDirection: 'column', flex: 1, paddingLeft: 10, paddingTop: Platform.OS!='web'? 10 : 0, backgroundColor:'#eaeaea' },
  negrita: { 
    fontFamily: "Ubuntu-Bold", 
    color: "#1bb5ec",
    textAlign: 'center'
  },
  simpleText: { 
    fontFamily: "Ubuntu-Regular",
    textAlign: 'center'
  },
  padBot:{ paddingBottom: 10 },
  centrarSelf:{ 
    flexDirection:'row', 
    alignSelf: 'center',
    justifyContent:'center',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#007db7',
    borderRadius: 30,
    marginVertical: '14px',
    //marginBottom: 15
    maxWidth: '396px',
    height: '48px'
  },
  atendiendo:{ fontSize: 25, fontWeight: 'bold', alignSelf:'center'},
  tituloAmarillo:{ color: '#f6f88c', fontWeight: 'bold' },
  colCard: {  
    flex: 1, 
    flexDirection: 'column', 
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent:'center',
    borderRadius: 5,
    borderWidth: 1,
    paddingHorizontal: '5px',
    margin: '6px',
    borderColor: '#f7f7f7',
    backgroundColor: '#FFF',
    maxWidth: '128px', 
    height: '136px'
  },
  container:{ 
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    paddingTop: '32px',
    paddingBottom: '35px',
    paddingLeft: '36px',
    alignSelf: 'flex-start',
  },
  containerMobile:{ 
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    justifyContent: 'center', 
    paddingVertical: '15px',
    paddingLeft: '8px',
  },
  noTramites:{ flexDirection: 'column', flex: 2, height: '100%' },
  tramite:{ flexDirection: 'column', flex: 2, height: '100%' },
  Horarios:{padding:0,margin:5, color:'#bbbcc1', textAlign:'center'},
  padTop:{ fontSize:20},
  horarioTitle:{color:'#00ac4d', fontSize:15, textAlign:'center'},
  noFilas:{ fontSize: 25, color: 'grey', alignSelf: 'center', marginVertical: 20 },
  atendiendo2:{ fontSize: 24, fontWeight: 'bold', color:'red' },
  textTagsCard: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 14,
    color:"#007db7",
    fontFamily: 'Ubuntu-Bold',
  },
  textInformationCard: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 14,
    color: "#a9a9a9",
  },
  centrarItem: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#e2e2e2",
    margin: 5
  },
  tituloSucursal:{
    fontFamily: 'Ubuntu-Medium', 
    fontSize: 18, 
    paddingVertical: '23px',
    paddingLeft: '34px',
    paddingBottom: '24px'
  },
  separadorSearch: {
    height: 1, 
    width: "100%", 
    backgroundColor: "#e1e8ee",
  },
  contenedorBranchData: {
    flexDirection: "row", 
    flexWrap: 'wrap', 
    justifyContent:"space-between", 
    paddingTop: '24.47px', 
    paddingLeft: '31px', 
    paddingRight: '29px', 
    paddingBottom: '16.53px', 
    maxWidth: '983px'
  },
  cabeceraCard:{
    fontFamily: 'Ubuntu-Regular',
    textAlign: 'left',
    alignSelf: 'center',
    fontSize: 16
  },
  cabeceraCardSucursal: {
    fontFamily: 'Ubuntu-Bold',
    justifyContent: 'center',
    alignSelf: 'center',
    fontSize: 16
  },
  tituloFilas:{
    fontFamily: 'Ubuntu-Medium', 
    fontSize: 24, 
    alignSelf: 'flex-start',
    marginBottom: 5,
    paddingLeft: '35px',
    paddingTop: '23px'
  },
  filasSubtitulo:{
    fontFamily: 'Ubuntu-Regular',
    paddingLeft: '35px',
    paddingBottom: '22px',
    alignSelf: 'flex-start',
  },
  containerFilas: {
    flexWrap: "wrap",
    backgroundColor: "#FFF", 
    alignItems: 'center', 
    marginLeft: '15px',
    marginRight: '16px',
    marginTop: '16px',
    marginBottom: '15px',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#e2e2e2",
    maxWidth: '969px',
  },
  containerFilasMobile: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#e2e2e2",
    margin: 5
    //maxWidth: '969px',
  },
  branchStateOpen: {
    fontFamily: 'Ubuntu-Medium',
    fontSize: 12,
    color: 'white',
    backgroundColor: '#0e891a',
    width: '61px',
    height: '17px',
    borderRadius: 10,
    textAlign: 'center',
    paddingTop: 1
  },
  branchStateClose: {
    fontFamily: 'Ubuntu-Medium',
    fontSize: 12,
    color: 'white',
    backgroundColor: '#e81e2b',
    width: '61px',
    height: '17px',
    borderRadius: 10,
    textAlign: 'center',
    paddingTop: 1
  },
  containerAgendarVolver: { 
    flexDirection: "row", 
    flexWrap: 'wrap',
    justifyContent: "space-around", 
    padding: 5, 
    marginBottom: 5
  },
  botonAgendar: {
    fontFamily: 'Ubuntu-Regular',
    color: "white",
    backgroundColor: "#e81e2b", 
    paddingTop: 7, 
    paddingBottom: 7, 
    paddingLeft: 20, 
    paddingRight: 20, 
    borderRadius: 5,
    alignItems: "center",
    fontSize: 14
  },
  botonCancelarVolver: {
    fontFamily: 'Ubuntu-Medium',
    paddingTop: 7, 
    paddingBottom: 7, 
    paddingRight: 20, 
    borderRadius: 5,
    alignItems: "center",
    fontSize: 14,
    color: "#e81e2b"
  },
  barraTextoRojo: {
    fontSize: '14px', 
    color: "#F42534",
    fontFamily: 'Ubuntu-Medium',
    marginLeft: '7px'
  },
  tituloWizard: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: 24,
    marginBottom: '10px',
    marginTop: '50px',
  },
  subtituloWizard: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 16,
    marginBottom: '29px',
  },
});
