import * as React from 'react';
import Svg, { G, Path, Defs, Mask, ClipPath, Use } from "react-native-svg"


function SvgMapaRojo(props) {
  
  return (
    
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 28 28"
      {...props}
    >
      <Path fill="none" d="M0 28h28V0H0z" />
      <Defs>
        <Path
          id="inicio_mapaRojo__a"
          d="M27.1 25.2l-3.5-14a.8.8 0 00-1.1-.6c-.5.1-.8.6-.6 1.1L23 16l-4.6 3.1-10.3-6.9h7.6c.5 0 .9-.4.9-.9s-.4-.9-.9-.9H5.3c-.4 0-.7.3-.8.7L1 25.1c-.1.6.3 1.1.8 1.1h24.5c.6 0 .9-.5.8-1zm-24.2-.7l2.9-11.7 17.6 11.7H2.9zM20 20.1l3.5-2.3 1.4 5.6-4.9-3.3z"
        />
      </Defs>
      <ClipPath id="inicio_mapaRojo__b">
        <Use xlinkHref="#inicio_mapaRojo__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#inicio_mapaRojo__b)">
        <Defs>
          <Path id="inicio_mapaRojo__c" d="M0 0h28v28H0z" />
        </Defs>
        <ClipPath id="inicio_mapaRojo__d">
          <Use xlinkHref="#inicio_mapaRojo__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#inicio_mapaRojo__d)"
          fill="#f42534"
          d="M-4.1 5.5h36.2v25.8H-4.1z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="inicio_mapaRojo__e"
            d="M18.4 8.6v4.5c0 .5.4.9.9.9s.9-.4.9-.9V8.6c1.5-.4 2.6-1.8 2.6-3.4 0-1.9-1.6-3.5-3.5-3.5s-3.5 1.6-3.5 3.5c0 1.7 1.1 3 2.6 3.4m.9-5.1c1 0 1.8.8 1.8 1.8S20.2 7 19.3 7s-1.8-.8-1.8-1.8.8-1.7 1.8-1.7"
          />
        </Defs>
        <ClipPath id="inicio_mapaRojo__f">
          <Use xlinkHref="#inicio_mapaRojo__e" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#inicio_mapaRojo__f)">
          <Defs>
            <Path id="inicio_mapaRojo__g" d="M0 0h28v28H0z" />
          </Defs>
          <ClipPath id="inicio_mapaRojo__h">
            <Use xlinkHref="#inicio_mapaRojo__g" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#inicio_mapaRojo__h)"
            fill="#f42534"
            d="M10.8-3.2h17V19h-17z"
          />
        </G>
      </G>
    </Svg>
    
  );
}


function SvgVerListaMapa(props) {
  
  return (
      
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 28 32"
      {...props}
    >
      <Defs>
        <Path
          id="inicio_verListaMapa__a"
          d="M14.1 5c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1s-1 .4-1 1v2c0 .6.4 1 1 1"
        />
      </Defs>
      <ClipPath id="inicio_verListaMapa__b">
        <Use xlinkHref="#inicio_verListaMapa__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#inicio_verListaMapa__b)">
        <Defs>
          <Path id="inicio_verListaMapa__c" d="M.1 0h28v32H.1z" />
        </Defs>
        <ClipPath id="inicio_verListaMapa__d">
          <Use xlinkHref="#inicio_verListaMapa__c" overflow="visible" />
        </ClipPath>
        <Path clipPath="url(#inicio_verListaMapa__d)" fill="#f42534" d="M8.1-4h12v14h-12z" />
      </G>
      <Defs>
        <Path
          id="inicio_verListaMapa__e"
          d="M18.3 6.2l1.4-1.4c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-1.4 1.4c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0"
        />
      </Defs>
      <ClipPath id="inicio_verListaMapa__f">
        <Use xlinkHref="#inicio_verListaMapa__e" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#inicio_verListaMapa__f)">
        <Defs>
          <Path id="inicio_verListaMapa__g" d="M.1 0h28v32H.1z" />
        </Defs>
        <ClipPath id="inicio_verListaMapa__h">
          <Use xlinkHref="#inicio_verListaMapa__g" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#inicio_verListaMapa__h)"
          fill="#f42534"
          d="M11.6-2H25v13.4H11.6z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="inicio_verListaMapa__i"
            d="M18.1 9c0 .6.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1h-2c-.6 0-1 .4-1 1"
          />
        </Defs>
        <ClipPath id="inicio_verListaMapa__j">
          <Use xlinkHref="#inicio_verListaMapa__i" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#inicio_verListaMapa__j)">
          <Defs>
            <Path id="inicio_verListaMapa__k" d="M.1 0h28v32H.1z" />
          </Defs>
          <ClipPath id="inicio_verListaMapa__l">
            <Use xlinkHref="#inicio_verListaMapa__k" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#inicio_verListaMapa__l)"
            fill="#f42534"
            d="M13.1 3h14v12h-14z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="inicio_verListaMapa__m"
            d="M7.1 10h2c.6 0 1-.4 1-1s-.4-1-1-1h-2c-.6 0-1 .4-1 1s.4 1 1 1"
          />
        </Defs>
        <ClipPath id="inicio_verListaMapa__n">
          <Use xlinkHref="#inicio_verListaMapa__m" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#inicio_verListaMapa__n)">
          <Defs>
            <Path id="inicio_verListaMapa__o" d="M.1 0h28v32H.1z" />
          </Defs>
          <ClipPath id="inicio_verListaMapa__p">
            <Use xlinkHref="#inicio_verListaMapa__o" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#inicio_verListaMapa__p)"
            fill="#f42534"
            d="M1.1 3h14v12h-14z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="inicio_verListaMapa__q"
            d="M9.8 6.2c.4.4 1 .4 1.4 0s.4-1 0-1.4L9.8 3.3c-.4-.3-1-.3-1.4 0-.4.4-.4 1.1 0 1.5l1.4 1.4z"
          />
        </Defs>
        <ClipPath id="inicio_verListaMapa__r">
          <Use xlinkHref="#inicio_verListaMapa__q" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#inicio_verListaMapa__r)">
          <Defs>
            <Path id="inicio_verListaMapa__s" d="M.1 0h28v32H.1z" />
          </Defs>
          <ClipPath id="inicio_verListaMapa__t">
            <Use xlinkHref="#inicio_verListaMapa__s" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#inicio_verListaMapa__t)"
            fill="#f42534"
            d="M3.1-2h13.4v13.4H3.1z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="inicio_verListaMapa__u"
            d="M27 24.4l-.7-4.1c-.2-1.3-1.3-2.3-2.6-2.5l-6.6-.7V11c0-1.7-1.3-3-3-3s-3 1.3-3 3v7.4l-.1-.2c-.9-1.4-2.7-1.8-4.1-.9-1.2.8-1.6 2.3-1.1 3.6l2.4 5.5c0 .1.1.1.1.2l3 4c.3.4 1 .5 1.4.2.4-.3.5-1 .2-1.4L10 25.5l-2.3-5.4c-.2-.4 0-.9.3-1.1.4-.3 1-.2 1.3.3l2 3.3c.2.4.7.6 1.1.4.4-.1.7-.5.7-1V11c0-.6.4-1 1-1s1 .4 1 1v7c0 .5.4.9.9 1l7.5.8c.4 0 .8.4.9.8l.7 4.1c0 .2.1.5.1.7 0 .8-.2 1.6-.7 2.3l-1.1 1.7c-.3.5-.2 1.1.3 1.4.5.3 1.1.2 1.4-.3l1.1-1.7c.7-1 1-2.2 1-3.4-.1-.3-.2-.7-.2-1"
          />
        </Defs>
        <ClipPath id="inicio_verListaMapa__v">
          <Use xlinkHref="#inicio_verListaMapa__u" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#inicio_verListaMapa__v)">
          <Defs>
            <Path id="inicio_verListaMapa__w" d="M.1 0h28v32H.1z" />
          </Defs>
          <ClipPath id="inicio_verListaMapa__x">
            <Use xlinkHref="#inicio_verListaMapa__w" overflow="visible" />
          </ClipPath>
          <Path clipPath="url(#inicio_verListaMapa__x)" fill="#f42534" d="M.5 3H32v33H.5z" />
        </G>
      </G>
    </Svg>
    
  );
}


function SvgLista(props) {
  
  return (
      
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 28 32"
      {...props}
    >
      <Defs>
        <Path
          id="inicio_lista__a"
          d="M4.4 29.1c-1.4 0-2.6-1.2-2.6-2.6 0-1.4 1.2-2.6 2.6-2.6S7 25 7 26.5c0 1.4-1.2 2.6-2.6 2.6m0-3.5c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9c-.1-.5-.4-.9-.9-.9"
        />
      </Defs>
      <ClipPath id="inicio_lista__b">
        <Use xlinkHref="#inicio_lista__a" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#inicio_lista__b)">
        <Defs>
          <Path id="inicio_lista__c" d="M0 0h28v32H0z" />
        </Defs>
        <ClipPath id="inicio_lista__d">
          <Use xlinkHref="#inicio_lista__c" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#inicio_lista__d)"
          fill="#f42534"
          d="M-3.2 18.9H12v15.2H-3.2z"
        />
      </G>
      <Defs>
        <Path
          id="inicio_lista__e"
          d="M4.4 18.6c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6S7 14.5 7 16s-1.2 2.6-2.6 2.6m0-3.5c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9c-.1-.5-.4-.9-.9-.9"
        />
      </Defs>
      <ClipPath id="inicio_lista__f">
        <Use xlinkHref="#inicio_lista__e" overflow="visible" />
      </ClipPath>
      <G clipPath="url(#inicio_lista__f)">
        <Defs>
          <Path id="inicio_lista__g" d="M0 0h28v32H0z" />
        </Defs>
        <ClipPath id="inicio_lista__h">
          <Use xlinkHref="#inicio_lista__g" overflow="visible" />
        </ClipPath>
        <Path
          clipPath="url(#inicio_lista__h)"
          fill="#f42534"
          d="M-3.2 8.4H12v15.2H-3.2z"
        />
      </G>
      <G>
        <Defs>
          <Path
            id="inicio_lista__i"
            d="M4.4 8.1C3 8.1 1.8 6.9 1.8 5.5S3 2.9 4.4 2.9 7 4 7 5.5 5.8 8.1 4.4 8.1m0-3.5c-.5 0-.9.4-.9.9s.4.9.9.9.8-.4.8-.9-.3-.9-.8-.9"
          />
        </Defs>
        <ClipPath id="inicio_lista__j">
          <Use xlinkHref="#inicio_lista__i" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#inicio_lista__j)">
          <Defs>
            <Path id="inicio_lista__k" d="M0 0h28v32H0z" />
          </Defs>
          <ClipPath id="inicio_lista__l">
            <Use xlinkHref="#inicio_lista__k" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#inicio_lista__l)"
            fill="#f42534"
            d="M-3.2-2.2H12V13H-3.2z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="inicio_lista__m"
            d="M25.4 27.4h-14c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h14c.5 0 .9.4.9.9s-.4.9-.9.9"
          />
        </Defs>
        <ClipPath id="inicio_lista__n">
          <Use xlinkHref="#inicio_lista__m" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#inicio_lista__n)">
          <Defs>
            <Path id="inicio_lista__o" d="M0 0h28v32H0z" />
          </Defs>
          <ClipPath id="inicio_lista__p">
            <Use xlinkHref="#inicio_lista__o" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#inicio_lista__p)"
            fill="#f42534"
            d="M5.5 20.6h25.8v11.8H5.5z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="inicio_lista__q"
            d="M25.4 16.9h-14c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h14c.5 0 .9.4.9.9s-.4.9-.9.9"
          />
        </Defs>
        <ClipPath id="inicio_lista__r">
          <Use xlinkHref="#inicio_lista__q" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#inicio_lista__r)">
          <Defs>
            <Path id="inicio_lista__s" d="M0 0h28v32H0z" />
          </Defs>
          <ClipPath id="inicio_lista__t">
            <Use xlinkHref="#inicio_lista__s" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#inicio_lista__t)"
            fill="#f42534"
            d="M5.5 10.1h25.8v11.8H5.5z"
          />
        </G>
      </G>
      <G>
        <Defs>
          <Path
            id="inicio_lista__u"
            d="M25.4 6.3h-14c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h14c.5 0 .9.4.9.9s-.4.9-.9.9"
          />
        </Defs>
        <ClipPath id="inicio_lista__v">
          <Use xlinkHref="#inicio_lista__u" overflow="visible" />
        </ClipPath>
        <G clipPath="url(#inicio_lista__v)">
          <Defs>
            <Path id="inicio_lista__w" d="M0 0h28v32H0z" />
          </Defs>
          <ClipPath id="inicio_lista__x">
            <Use xlinkHref="#inicio_lista__w" overflow="visible" />
          </ClipPath>
          <Path
            clipPath="url(#inicio_lista__x)"
            fill="#f42534"
            d="M5.5-.4h25.8v11.8H5.5z"
          />
        </G>
      </G>
    </Svg>
    
  );
}


export { SvgMapaRojo, SvgVerListaMapa, SvgLista }