
const initialState = {
    reserva: {
        detalles: null,
        cancelar: false
    }
}

function reservaReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_RESERVA':
            return {
                ...state,
                reserva: {
                    detalles: action.payload,
                    cancelar: false
                }
            }
        case 'CANCEL_RESERVA':
            return {
                ...state,
                reserva: {
                    detalles: action.payload,
                    cancelar: true
                }
            }
        default:
            return state
    }
}

export default reservaReducer