import React, { Component } from "react";
import { ScrollView } from "react-native";
import { View } from "react-native";
import FormularioComponent from "../../components/Modal/Formulario/formulario.component";
import encryptConfig from "../Utils/encryptConfig";
import MediaQuery from "react-native-web-responsive";
import { connect } from 'react-redux';
class SeguimientoReservasComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      misReservas: null,
      rut: null,
    };
  }

  componentDidMount() {
    if (this.props.route.params.qrRut && this.props.route.params.qrCodigo) {
      const rut = this.props.route.params.qrRut;
      this.props.navigation.navigate("SeguimientoTurnos", {
        rut: encryptConfig.rsaEncrypt(rut.replace('.', '').replace('.', '')),
        codigo: this.props.route.params.qrCodigo,
      });
    }
  }

  userDataHandleCallback() {
    this.props.navigation.navigate(
      this.props.route.params.type === "reservas"
        ? "SeguimientoReservas"
        : "SeguimientoTurnos",
        this.props 
    );
  };

  render() {
    this.userDataHandleCallback();
    return (
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        {/* <View style={{ maxWidth: 983, maxHeight: 908, alignSelf: "center" }}>
          <MediaQuery minWidth={1224}>
            <View
              style={{
                width: "937px",
              }}
            >
              <FormularioComponent
                userData={this.userDataHandleCallback}
                navigation={this.props.navigation}
                tipoFuncion={"seguimiento"}
                type={this.props.route.params.type}
              />
            </View>
          </MediaQuery>
          <MediaQuery maxWidth={1223}>
            <View
              style={{
                width: "100%",
              }}
            >
              <FormularioComponent
                userData={this.userDataHandleCallback}
                navigation={this.props.navigation}
                tipoFuncion={"seguimiento"}
                type={this.props.route.params.type}
              />
            </View>
          </MediaQuery>
        </View> */}
      </ScrollView>
    );
  }
}
const mapStateToProps = state => {
  return {
  
    user: state.user
  }
}
export default connect(mapStateToProps)(SeguimientoReservasComponent);
