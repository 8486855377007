import React, { Component } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { ActivityIndicator, Colors } from 'react-native-paper';
import MediaQuery from "react-native-web-responsive";
import FontAwesome from 'react-native-vector-icons/FontAwesome5';
import FilasComponent from '../Filas/filas.component';
import TramitesComponent from '../Tramites/tramites.component';
import scheduleValidation from '../Utils/scheduleValidation';
import sucursalStyle from './sucursal.style';
import {SvgDistancia, SvgMapaBlue, SvgReloj} from './sucursal.svgs';
import branchStateFunction from '../Utils/branchState.function';
import inicioFunctions from '../Inicio/inicio.functions';

class SucursalComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      branch: this.props.route.params.branch,
      branchState: false,
      noGps: this.props.route.params.noGps,
    }

  }

  async componentDidMount() {
    scheduleValidation(this.state.branch._id).then((response) => {
      this.setState({
        isReady: true,
        branchState: response.data.branchState
      });
    }).catch((error) => {
      alert('Error al recuperar la informacion de la sucursal')
    })

  }

  render() {

    if (this.state.isReady) {
      return (
          <ScrollView contentContainerStyle={{flex:1}}>
            <View style={{maxWidth: 983, maxHeight: 908, alignSelf: 'center'}}>
            <MediaQuery minWidth={1224}>
              <View style={{marginHorizontal: '17px', marginBottom: '28px'}}>
                <View>
                  <Text style={sucursalStyle.tituloWizard}>Selecciona la fecha y tipo de atención</Text>
                  <Text style={sucursalStyle.subtituloWizard}>Elige la fecha de tu atención, así como también si es una  atención en Cajas o con un Ejecutivo(a).</Text>
                </View>
                  
                <View style={{ flexDirection: "row", alignSelf: 'center', width: '937px'}}>
                  <View style={{ width: '302px' }}>
                    <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10
                      }}
                    >
                      <FontAwesome
                        name="check-circle"
                        style={{ fontSize: 22, color: "#F42534", marginRight: '10px' }}
                      />
                      <View
                        style={{
                          height: 2,
                          width: '270px',
                          backgroundColor: "#F42534",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                          marginRight: 10
                        }}
                      ></View>
                    </View>
                    <Text style={sucursalStyle.barraTextoRojo}>
                      1. Sucursal
                    </Text>
                  </View>
                  <View style={{ width: '302px', marginLeft: '10px' }}>
                    <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10
                      }}
                    >
                      <View
                        style={{ height: 22, width: 22, border: "8px solid #F42534", borderRadius: 30, marginRight: '10px' }}
                      />
                      <View style={{
                          height: 2,
                          width: '272px',
                          backgroundColor: "#d8d8d8",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                          marginRight: 10
                        }}
                      ></View>
                    </View>
                    <Text style={[sucursalStyle.barraTextoRojo, {color: '#F42534'}]}>
                      2. Agenda tu hora
                    </Text>
                  </View>
                  <View style={{ width: '306px', marginLeft: '10px' }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10
                      }}
                    >
                      
                      <View
                        style={{ height: 22, width: 22, border: "2px solid #d8d8d8", backgroundColor: '#FFF', borderRadius: 30, marginRight: '10px' }}
                      />
                      <View style={{flexDirection: 'row', width: "90%"}}>
                        <View
                          style={{
                            height: 2,
                            width: '274px',
                            backgroundColor: "#d8d8d8",
                            marginTop: 10,
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        ></View>
                        <View style={{
                          height: '12px', 
                          width: 3, 
                          backgroundColor: "#d8d8d8",
                          borderRadius: '1.5px',
                          marginTop: 5}}>
                        </View>
                      </View>
                    </View>
                    <Text style={[sucursalStyle.barraTextoRojo, {color: '#d8d8d8'}]}>
                      3. Comprobante
                    </Text>
                  </View>
                </View>

              </View>
            </MediaQuery>

            
            <MediaQuery maxWidth={1223}>
              <View style={{paddingHorizontal: '10px', marginBottom: '15px'}}>
                <View>
                  <Text
                    style={[
                      sucursalStyle.tituloWizard, {marginTop: '10px', fontSize: 20}
                    ]}
                  >
                    Selecciona la fecha y tipo de atención
                  </Text>
                  <Text
                    style={[
                      sucursalStyle.subtituloWizard, {marginBottom: '15px', fontSize: 14}
                    ]}
                  >
                    Elige la fecha de tu atención, así como también si es una  atención en Cajas o con un Ejecutivo(a).
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    alignSelf: "center",
                  }}
                >
                  <View style={{ width: '100%' }}>
                    <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10
                      }}
                    >
                      <View
                        style={{ height: 22, width: 22, border: "8px solid #F42534", borderRadius: 30 }}
                      />
                      <View style={{
                          height: 2,
                          width: '94%',
                          backgroundColor: "#d8d8d8",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                        }}
                      ></View>
                    </View>
                    <Text style={[sucursalStyle.barraTextoRojo, {color: '#F42534'}]}>
                      2. Agenda tu hora
                    </Text>
                  </View>
                </View>
              </View>
            </MediaQuery>


            <MediaQuery minWidth={1224}>
              <View style={[sucursalStyle.centrarItem, {maxWidth: '969px', maxHeight: '201px', margin: '16px'}]}>
                <Text style={sucursalStyle.tituloSucursal}>Sucursal elegida: Sucursal {this.state.branch.name == 'Santiago Centro' ? 'Oficina Matriz 2' : this.state.branch.name}
                  {branchStateFunction(this.state.branch.horarios, this.state.branch.name) && !inicioFunctions.isTodayHoliday(this.state.branch.feriados) ?
                    <View style={{ flexDirection: 'row', alignItems: "center", marginLeft: '16px' }}>
                      <Text style={sucursalStyle.branchStateOpen}>Abierto</Text>
                    </View>
                    :
                    <View style={{ flexDirection: 'row', alignItems: "center", marginLeft: '16px' }}>
                      <Text style={sucursalStyle.branchStateClose}>Cerrado</Text>
                    </View>
                  }
                </Text>
                <View style={sucursalStyle.separadorSearch}/>
                <View style={sucursalStyle.contenedorBranchData}>
                  <View style={{alignSelf: "flex-start", width: "50%"}}>
                    <View style={{flexDirection: "row", flexWrap: 'wrap', marginBottom: '11px'}}>
                      <SvgMapaBlue width={16} height={16} />
                      <Text style={sucursalStyle.textTagsCard}>Dirección: </Text>
                      <Text style={[sucursalStyle.textInformationCard, {textTransform: 'capitalize'}]}>{this.state.branch.address}, {this.state.branch.comuna}</Text>
                    </View>
                    {!this.state.noGps && 
                      <View style={{flexDirection: "row", marginBottom: '11px'}}>
                        <SvgDistancia width={16} height={16} />
                        <Text style={sucursalStyle.textTagsCard}>Distancia: </Text>
                        <Text style={sucursalStyle.textInformationCard}>{this.state.branch.distance} km</Text>
                      </View>
                    }
                  </View>
                  <View style={{alignSelf: "flex-start", width: '50%', marginBottom: '11px'}}>
                    <View style={{display: 'table-row', flexWrap: 'wrap', width: '100%'}}>
                      <SvgReloj width={16} height={16} />
                      <Text style={sucursalStyle.textTagsCard}>Horarios de Atención: </Text>
                      {
                        this.state.branch.horarios.map(horarios => {
                          return (
                            horarios.inicioBreak !== 'Sin Break' ?
                              <Text key={horarios._id} style={sucursalStyle.textInformationCard}>{horarios.horarioFormato} de {horarios.horaApertura} Hrs a {horarios.inicioBreak} Hrs y {horarios.terminoBreak} Hrs a {horarios.horaCierre} Hrs. </Text>
                              :
                              <Text key={horarios._id} style={sucursalStyle.textInformationCard}>{horarios.horarioFormato} de {horarios.horaApertura} Hrs a {horarios.horaCierre} Hrs.</Text>
                          )
                        }
                        )
                      }
                    </View>
                  </View>
                </View>
              </View>
            </MediaQuery>

            <MediaQuery maxWidth={1223}>
              <View style={sucursalStyle.centrarItem}>
                <Text style={[sucursalStyle.tituloSucursal, {padding: 15}]}>Sucursal elegida: Sucursal {this.state.branch.name == 'Santiago Centro' ? 'Oficina Matriz 2' : this.state.branch.name}
                  {branchStateFunction(this.state.branch.horarios, this.state.branch.name) && !inicioFunctions.isTodayHoliday(this.state.branch.feriados)?
                    <View style={{ flexDirection: 'row', alignItems: "center", marginLeft: '16px' }}>
                      <Text style={sucursalStyle.branchStateOpen}>Abierto</Text>
                    </View>
                    :
                    <View style={{ flexDirection: 'row', alignItems: "center", marginLeft: '16px' }}>
                      <Text style={sucursalStyle.branchStateClose}>Cerrado</Text>
                    </View>
                  }
                </Text>
                <View style={[sucursalStyle.separadorSearch, {marginTop: 5, marginBottom: 5}]}/>
                <View style={{flexDirection: "row", flexWrap: 'wrap', justifyContent:"space-between", padding: 15, width: '100%'}}>
                  <View style={{alignSelf: "flex-start", flexWrap: 'wrap', width: '100%'}}>
                    <View style={{flexWrap: 'wrap', display: 'table-row', marginBottom: '10px'}}>
                      <SvgMapaBlue width={16} height={16} />
                      <Text style={sucursalStyle.textTagsCard}>Dirección: </Text>
                      <Text style={[sucursalStyle.textInformationCard, {textTransform: 'capitalize'}]}>{this.state.branch.address}, {this.state.branch.comuna}</Text>
                    </View>
                    {!this.state.noGps && 
                      <View style={{flexDirection: "row", marginBottom: '10px'}}>
                        <SvgDistancia width={16} height={16} />
                        <Text style={sucursalStyle.textTagsCard}>Distancia: </Text>
                        <Text style={sucursalStyle.textInformationCard}>{this.state.branch.distance} km</Text>
                      </View>
                    }
                  </View>
                  <View style={{alignSelf: "flex-start", flexWrap: 'wrap', width: '100%'}}>
                    <View style={{display: 'table-row', marginBottom: 5, flexWrap: 'wrap', width: '100%'}}>
                      <SvgReloj width={16} height={16} />
                      <Text style={sucursalStyle.textTagsCard}>Horarios de Atención: </Text>
                      {
                        this.state.branch.horarios.map(horarios => {
                          return (
                            horarios.inicioBreak !== 'Sin Break' ?
                              <Text key={horarios._id} style={sucursalStyle.textInformationCard}>{horarios.horarioFormato} de {horarios.horaApertura} Hrs a {horarios.inicioBreak} Hrs y {horarios.terminoBreak} Hrs a {horarios.horaCierre} Hrs. </Text>
                              :
                              <Text key={horarios._id} style={sucursalStyle.textInformationCard}>{horarios.horarioFormato} de {horarios.horaApertura} Hrs a {horarios.horaCierre} Hrs. </Text>
                          )
                        }
                        )
                      }
                    </View>
                  </View>
                </View>
              </View>
            </MediaQuery>


            <MediaQuery minWidth={1224}>
              <View style={sucursalStyle.containerFilas}>
                <Text style={sucursalStyle.tituloFilas}>¿Qué tipo de atención necesitas y cuándo?</Text>
                <Text style={sucursalStyle.filasSubtitulo}>Agenda tu hora para visitar nuestras sucursales en el horario que más te acomode.</Text>

                <View style={sucursalStyle.separadorSearch}/>
                <View style={sucursalStyle.container}>
                {/*<ScrollView contentContainerStyle={sucursalStyle.container}>*/}
                  <FilasComponent branch={this.state.branch} width={'433px'} navigation={this.props.navigation} branchState={this.state.branchState} user={this.props.route.params.user} noGps={this.state.noGps}></FilasComponent>
                {/*</ScrollView>*/}
                </View>
              </View>
            </MediaQuery>

            <MediaQuery maxWidth={1223}>
              <View style={sucursalStyle.containerFilasMobile}>
                <Text style={[sucursalStyle.tituloFilas, {fontSize: 20, paddingLeft: 10, paddingRight: 10}]}>¿Qué tipo de atención necesitas y cuándo?</Text>
                <Text style={[sucursalStyle.filasSubtitulo, {paddingLeft: 10, paddingRight: 10}]}>Agenda tu hora para visitar nuestras sucursales en el horario que más te acomode.</Text>

                <View style={sucursalStyle.separadorSearch}/>
                <View style={sucursalStyle.containerMobile}>
                {/*<ScrollView contentContainerStyle={sucursalStyle.container}>*/}
                  <FilasComponent branch={this.state.branch} width={'100%'} navigation={this.props.navigation} branchState={this.state.branchState} user={this.props.route.params.user} noGps={this.state.noGps}></FilasComponent>
                {/*</ScrollView>*/}
                </View>
              </View>
            </MediaQuery>
            <View style={{padding:10}}></View>
          </View>
        </ScrollView>
      )
    } else {
      return <ActivityIndicator animating={true} color={Colors.green600} ></ActivityIndicator>
    }
  }
  
}

export default SucursalComponent