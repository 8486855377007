import React from "react";
import { Image, Text, View } from "react-native";
import swiperHelperStyle from "./swiperHelper.style";
import MediaQuery from "react-native-web-responsive";

function Slice2(props) {
  return (
    <View style={swiperHelperStyle.slide1}>
      <View
        style={{
          flex: 1,
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-end",
          marginBottom: "50px",
        }}
      >
        <Image
          style={{ height: 150, width: 145, marginVertical: 20 }}
          source={require("../../../assets/slider2.png")}
        />
        <Text
          style={{
            color: "#383638",
            fontSize: 18,
            fontFamily: "Ubuntu-Bold",
            marginBottom: "4%",
            textAlign: "center",
          }}
        >
          Agenda tu hora
        </Text>

        <Text
          style={{
            textAlign: "center",
            color: "#383638",
            fontSize: 14,
            fontFamily: "Ubuntu-Regular",
            lineHeight: 20,
          }}
        >
          En el listado de sucursales podrás seleccionar la fecha y tramo horario 
          en el que deseas visitar una de nuestras sucursales.
        </Text>

        <MediaQuery minWidth={1224}>
          <View
            style={{
              marginTop: "60px",
              height: 1,
              width: "100%",
              backgroundColor: "#e1e8ee",
            }}
          />
        </MediaQuery>
        <MediaQuery maxWidth={1223}>
          <View
            style={{
              marginTop: "10px",
              height: 1,
              width: "100%",
              backgroundColor: "#e1e8ee",
            }}
          />
        </MediaQuery>
      </View>
    </View>
  );
}

export default Slice2;
