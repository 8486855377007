import { combineReducers, createStore } from "redux";

function userReducer(state=null, action){
    switch (action.type){
        case 'LOGGED_IN':
            return action.payload;
        case 'SIGN_OUT':
            return null;
        default:
            return state;
    }
}

/*let rootReducer = combineReducers({
    user: userReducer
})*/

export default userReducer