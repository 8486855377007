import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Card } from 'react-native-elements';
import MediaQuery from 'react-native-web-responsive';
import MiTurnoWebComponent from '../Modal/MiTurno/miTurnoWeb.component';
import ConfirmarCancelarReservaComponent from './CancelarRserva/confirmarCancelarReserva.component';
import ClosedBranchAdviceWebComponent from './ClosedBranchAdviceWeb/modalClosedBranchWeb';
import ConfirmarReservaComponent from './ConfirmarReserva/confirmarReserva.component';
import ErrorVerifyComponent from './ErrorVerify/errorVerify.component';
import FormularioComponent from './Formulario/formulario.component';
import modalStyle from './modalStyle';
import NewNumberComponent from './NewNumber/newnumber.component';
import ReservarWebComponent from './ReservarWeb/reservaWeb.component';
import store from '../../redux/store'

export default function modalComponent(props) {
  const [userData, setUserData] = useState(store.getState().user);
  const [errorVerifyData, setErrorVerifyData] = useState(false);
  const [confReservation, setConfReservation] = useState(false);
  const [openCode, setOpenCode] = useState(false);
  useEffect(() => {
    props.navigation.setOptions({
      title: 'Agenda tu hora',
    });
  }, [ props.navigation]);
  const userDataHandleCallback = (value) => {
    setUserData(value);
  };

  const errorVerifyDataHandleCallback = (value) => {
    setErrorVerifyData(value);
  };

  const confReservationHandleCallback = (value) => {
    setConfReservation(value);
  };

  const openCodeHandleCallback = (value) => {
    setOpenCode(value);
  };

  return (
    <View style={modalStyle.centeredView}>
        <View style={modalStyle.viewConfigModal}>
          <Card style={modalStyle.modalView}>
            {props.route.params.type === 'miTurno' ? (
              <MiTurnoWebComponent
                navigation={props.navigation}
                payload={props.route.params.payload}
              />
            ) : props.route.params.type === 'closedBranch' ? (
              <ClosedBranchAdviceWebComponent navigation={props.navigation} />
            ) : errorVerifyData ? (
              <ErrorVerifyComponent navigation={props.navigation} />
            
              ) :props.route.params.type === 'numero' ? (
                <NewNumberComponent
                  userData={store.getState().user}
                  navigation={props.navigation}
                  queueId={props.route.params.queueId}
                  branchAddress={props.route.params.branch.address}
                  branchComuna={props.route.params.branch.comuna}
                  onOk={() => props.route.params.onOk ? props.route.params.onOk() : {}}
                />): !userData || (props.route.params.openCode && !openCode ) ? (
              <FormularioComponent
                type={props.route.params.type}
                userData={userDataHandleCallback}
                userDataSecond={userData}
                openCode={openCodeHandleCallback}
                errorVerify={errorVerifyDataHandleCallback}
                navigation={props.navigation}
                tipoFuncion={props.route.params.tipoFuncion}
              />
            ) : 
              props.route.params.type === 'datosIniciales' ? (
                props.route.params.userInicial(userData),
                props.navigation.goBack()
                
            ) : props.route.params.tipoFuncion === 'cancelar' ? (
              <ConfirmarCancelarReservaComponent
                navigation={props.navigation}
                idReserva={props.route.params.idReserva}
                userData={userData}
                onGoBack={() => props.route.params.onGoBack()}
              />
            ) : !confReservation ? (
              <ConfirmarReservaComponent
                confReservation={confReservationHandleCallback}
                userData={userData}
                navigation={props.navigation}
                queueId={props.route.params.queueId}
                queueName={props.route.params.queueName}
                hora={props.route.params.hora}
                dateToReservation={props.route.params.dateToReservation}
                reservationType={props.route.params.reservationType}
                tipoFuncion={props.route.params.tipoFuncion}
                onReservationError={() => props.route.params.onGoBack()}
              />
            ) : (
              <ReservarWebComponent
                userData={props.route.params.tipoFuncion === 'reprogramar' ? userData : store.getState().user}
                navigation={props.navigation}
                queueId={props.route.params.queueId}
                branchId={props.route.params.branchId}
                idReserva={props.route.params.idReserva}
                queueName={props.route.params.queueName}
                hora={props.route.params.hora}
                dateToReservation={props.route.params.dateToReservation}
                tipoFuncion={props.route.params.tipoFuncion}
                description={props.route.params.description}
                branchName={props.route.params.branchName}
                reservationType={props.route.params.reservationType}
                site={props.route.params.site}
                comuna={props.route.params.branch && props.route.params.branch.comuna}
                address={props.route.params.branch && props.route.params.branch.address}
                onReservationError={() => props.route.params.onGoBack()}
                onRedirect={() => props.route.params.onSuccess ? props.route.params.onSuccess() : {}}
              />
            )}
          </Card>
        </View>
    </View>
  );
}
