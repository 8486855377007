import React, { useState } from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import { Button } from 'react-native-elements';
import FontAwesome from 'react-native-vector-icons/FontAwesome5';
import MediaQuery from "react-native-web-responsive";
import { cancelReservation } from '../../config/config';
import store from '../../redux/store';
import modalStyle from '../Modal/modalStyle';

function CancelarReservaComponent(props) {
  return (
    <View>
      <Button
        onPress={() => show()}
        title='Cancelar'
        buttonStyle={modalStyle.botonCancelar}
        titleStyle={[modalStyle.botonCancelar, {paddingTop: 5, paddingBottom: 5}]}
        icon={
          <FontAwesome
            name='window-close'
            style={{ fontSize: 18, marginRight: 8, color: '#e81e2b' }}
          />
        }
      />
    </View>
  );

  async function show() {
    props.navigation.navigate('ModalComponent', {
      tipoFuncion: props.type,
      type: 'reserva',
      openCode: props.openCode,
      idReserva: props.idReserva,
      onGoBack: props.onGoBack,
    });
  }
}

function CancelarReservaComponentBlue(props) {
  const [branchState, setBranchState] = useState(true);
  const [selected, setSelected] = useState(false);

  return (
    <View>
      {/*<Button
        onPress={() => show()}
        title='Cancelar'
        titleStyle={modalStyle.textoBlanco}
        buttonStyle={modalStyle.buttonPedirNumero}
        icon={
          <FontAwesome
            name='window-close'
            style={{ fontSize: 19, marginRight: 8, color: '#FFF' }}
          />
        }
      />*/}
      <MediaQuery minWidth={1224}>
        <TouchableHighlight
          style={[modalStyle.buttonReservar, {paddingBottom: 13}]}
          onPress={() => show()}
          underlayColor={"#007db7"}
          onShowUnderlay={() => setSelected(true)}
          onHideUnderlay={() => setSelected(false)}
        >
          <Text
            style={
              selected
                ? {
                    color: "white",
                    marginHorizontal: 1,
                    fontSize: 14,
                    alignSelf: "center",
                    fontFamily: "Ubuntu-Medium",
                  }
                : {
                    color: "#007db7",
                    marginHorizontal: 1,
                    fontSize: 14,
                    alignSelf: "center",
                    fontFamily: "Ubuntu-Medium",
                  }
            }
          >
            {!selected ? (
              <FontAwesome
                name='window-close'
                style={{ fontSize: 20, marginRight: 8, color: '#007db7' }}
              />
            ) : (
              <FontAwesome
                name='window-close'
                style={{ fontSize: 20, marginRight: 8, color: '#FFF' }}
              />
            )}{" "}
            {props.title}
          </Text>
        </TouchableHighlight>
      </MediaQuery>

      <MediaQuery maxWidth={1223}>
      <TouchableHighlight
          style={[modalStyle.buttonReservar, {paddingLeft: 12, paddingRight: 12, paddingTop: 12, paddingBottom: 13}]}
          onPress={() => show()}
          underlayColor={"#007db7"}
          onShowUnderlay={() => setSelected(true)}
          onHideUnderlay={() => setSelected(false)}
        >
          <Text
            style={
              selected
                ? {
                    color: "white",
                    marginHorizontal: 1,
                    fontSize: 13,
                    alignSelf: "center",
                    fontFamily: "Ubuntu-Medium",
                  }
                : {
                    color: "#007db7",
                    marginHorizontal: 1,
                    fontSize: 13,
                    alignSelf: "center",
                    fontFamily: "Ubuntu-Medium",
                  }
            }
          >
            {!selected ? (
              <FontAwesome
                name='window-close'
                style={{ fontSize: 18, marginRight: 8, color: '#007db7' }}
              />
            ) : (
              <FontAwesome
                name='window-close'
                style={{ fontSize: 18, marginRight: 8, color: '#FFF' }}
              />
            )}{" "}
            {props.title}
          </Text>
        </TouchableHighlight>
      </MediaQuery>
    </View>
  );

  async function show() {
    props.navigation.navigate('ModalComponent', {
      tipoFuncion: props.type,
      type: 'reserva',
      openCode: props.openCode,
      idReserva: props.idReserva,
      onGoBack: props.onGoBack,
    });
  }
}

export { CancelarReservaComponent, CancelarReservaComponentBlue };
