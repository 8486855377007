
import { getNumbers, getQueues, getReservasByUser, obtenerFila } from '../../config/config';

class filasFunctions {


    async obtenerFilas(branchName) {

        let responseFilas = await fetch(getQueues + branchName, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });

        return responseFilas
    }

    async obtenerTurnos(applicantId) {
        let responseTurnos = await fetch(getNumbers, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                applicant: applicantId
            })
        });

        return responseTurnos
    }

    async obtenerReservas(applicantId, token) {
        let responseReservas = await fetch(getReservasByUser, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                applicantId: applicantId,
                status: "esperando",
                token: token,
            })
        });

        return responseReservas
    }

    async obtenerFila(queueId) {
        let responseFila = await fetch(obtenerFila + queueId, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });

        return responseFila
    }

}

export default new filasFunctions();

