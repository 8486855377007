import { Entypo, Feather } from "@expo/vector-icons";
import { ActivityIndicator, Colors } from "react-native-paper";
import { Card, Button } from "react-native-elements";
import React, { Component } from "react";
import { Text, View, ScrollView, TouchableHighlight, Linking } from "react-native";
import { connect, shallowEqual } from "react-redux";
import FontAwesome from "react-native-vector-icons/FontAwesome5";
import MediaQuery from "react-native-web-responsive";
import sucursalStyle from "../Sucursal/sucursal.style";
import misTurnosFunctions from "./misTurnos.functions";
import misTurnosStyle from "./misTurnos.style";
import { SvgTicket, SvgRelojArena, SvgFila } from "./misTurnos.svgs";
import { PedirNumeroComponentRed } from "../Utils/pedirNumero.component";

class misTurnosComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      misTurnos: null,
      rut: this.props.route.params.user ? this.props.route.params.user.rut : this.props.route.params.rut,
      codigo: this.props.route.params.codigo,
    };
  }

  async componentDidMount() {
    // if (this.props.user) {
    let responseTurnos = await misTurnosFunctions.obtenerTurnos(this.state.rut);
    let jsonTurnos = await responseTurnos.json();

    for (let fila of jsonTurnos.numbers) {
      let responseFila = await misTurnosFunctions.obtenerFila(fila.queue);
      let jsonFila = await responseFila.json();
      fila.currentAttendingNumber = jsonFila.queues[0].currentAttendingNumber;
      fila.timeToWaiting = jsonFila.queues[0].timeToWaiting;
      fila.description = jsonFila.queues[0].description;
      
      //SACAR ESTE CONSOLE.LOG ANTES DE PASAR A PRODUCCION
      //console.log("Datos Ticket", jsonTurnos.numbers);
      
    }

    if (this.state.codigo) {
      const numeros = jsonTurnos.numbers.filter(
        (numero) => numero.codigo === this.state.codigo
      );
      this.setState({ misTurnos: numeros, isReady: true });
    } else {
      this.setState({ misTurnos: jsonTurnos.numbers, isReady: true });
    }
    // }
  }

  componentDidUpdate(prevProps) {
    if (shallowEqual(prevProps.msg, this.props.msg)) {
    } else {
      if (!this.props.msg.recoverNum) {
        this.setState({
          isReady: false,
        });
        let misTurnosAux = [];

        if (this.state.misTurnos) {
          misTurnosAux = this.state.misTurnos;
        }

        if (
          this.state.rut == this.props.msg.applicantId ||
          this.state.rut == this.props.msg.rut
        ) {
          for (var i = 0; i < misTurnosAux.length; i++) {
            if (
              misTurnosAux[i].number == this.props.msg.currentAttendingNumber &&
              (misTurnosAux[i].queue == this.props.msg._id ||
                misTurnosAux[i].queue == this.props.msg.queue)
            ) {
              let payload = {
                reserva: true,
                isVisible: true,
                fila: misTurnosAux[i].name,
                module: misTurnosAux[i].module,
                numeroPedido: this.props.msg.reservationNumber,
                description: this.props.msg.description,
              };
              this.props.navigation.navigate("ModalMainComponent", {
                type: "miTurno",
                payload,
              });
              misTurnosAux.splice(i, 1);
              break;
            } else {
              if (
                misTurnosAux[i].queue == this.props.msg._id ||
                misTurnosAux[i].queue == this.props.msg.queue
              ) {
                misTurnosAux[
                  i
                ].currentAttendingNumber = this.props.msg.currentAttendingNumber;
                misTurnosAux[i].timeToWaiting = this.props.msg.timeToWaiting;
                if (this.props.msg.lastNumberTaken) {
                  misTurnosAux[
                    i
                  ].lastNumberTaken = this.props.msg.lastNumberTaken;
                } else {
                  misTurnosAux[i].lastNumberTaken = this.props.msg.number;
                }
              }
            }
          }
        } else {
          for (var j = 0; j < misTurnosAux.length; j++) {
            if (
              misTurnosAux[j].queue == this.props.msg.queue ||
              misTurnosAux[j].queue == this.props.msg._id
            ) {
              misTurnosAux[
                j
              ].currentAttendingNumber = this.props.msg.currentAttendingNumber;
              misTurnosAux[j].timeToWaiting = this.props.msg.timeToWaiting;
              if (this.props.msg.lastNumberTaken) {
                misTurnosAux[
                  j
                ].lastNumberTaken = this.props.msg.lastNumberTaken;
              } else {
                misTurnosAux[j].lastNumberTaken = this.props.msg.number;
              }
            }
          }
        }
        this.setState({
          misTurnos: misTurnosAux,
          isReady: true,
        });
      }
    }
  }

  render() {
    return (
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        {this.state.isReady === false ? (
          <View>
            <ActivityIndicator></ActivityIndicator>
          </View>
        ) : this.state.misTurnos === null ? (
          <View>
            <Text>Error al cargar los datos</Text>
          </View>
        ) : this.state.misTurnos.length === 0 ? (
          <View>
            <View
              style={[
                { padding: 20, alignSelf: "center" },
                misTurnosStyle.cuadroBlanco,
              ]}
            >
              <Text
                style={[
                  misTurnosStyle.tituloCabecera,
                  { textAlign: "center", fontSize: 20 },
                ]}
              >
                <Feather name="clock" size={25} color="#e81e2b" /> Aún no tienes
                turnos tomados
              </Text>
            </View>
          </View>
        ) : (
          <View style={{ maxWidth: 983, maxHeight: 908, alignSelf: "center" }}>
            <View>
              <Text style={misTurnosStyle.tituloMisTurnos}>Aquí puedes revisar tus números solicitados</Text>
            </View>
            {/*<MediaQuery minWidth={1224}>
              <View style={{marginHorizontal: '17px', marginBottom: '28px'}}>
                <View>
                  <Text style={sucursalStyle.tituloWizard}>Tipo de atención y fecha de atención</Text>
                  <Text style={sucursalStyle.subtituloWizard}>Elige si es una atención de caja o con ejecutivo, y si es un número o un agendamiento.</Text>
                </View>
                  
                <View style={{ flexDirection: "row", alignSelf: 'center', width: '937px'}}>
                  <View style={{ width: '302px' }}>
                    <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10
                      }}
                    >
                      <FontAwesome
                        name="check-circle"
                        style={{ fontSize: 22, color: "#F42534", marginRight: '10px' }}
                      />
                      <View
                        style={{
                          height: 2,
                          width: '270px',
                          backgroundColor: "#F42534",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                          marginRight: 10
                        }}
                      ></View>
                    </View>
                    <Text style={sucursalStyle.barraTextoRojo}>
                      1. Elige la sucursal
                    </Text>
                  </View>
                <View style={{ width: '302px', marginLeft: '10px' }}>
                  <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10
                      }}
                    >
                      <FontAwesome
                        name="check-circle"
                        style={{ fontSize: 22, color: "#F42534", marginRight: '10px' }}
                      />
                      <View
                        style={{
                          height: 2,
                          width: '272px',
                          backgroundColor: "#F42534",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                          marginRight: 10
                        }}
                      ></View>
                    </View>
                  <Text style={sucursalStyle.barraTextoRojo}>
                    2. Obten ticket o agenda hora
                  </Text>
                </View>
                <View style={{ width: '306px', marginLeft: '10px' }}>
                  <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10
                      }}
                    >
                      <FontAwesome
                        name="check-circle"
                        style={{ fontSize: 22, color: "#F42534", marginRight: '10px' }}
                      />
                      <View
                        style={{
                          height: 2,
                          width: '274px',
                          backgroundColor: "#F42534",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                        }}
                      ></View>
                      <View style={{
                        height: '12px', 
                        width: 3, 
                        backgroundColor: "#F42534",
                        borderRadius: '1.5px',
                        marginTop: 5}}>
                      </View>
                    </View>
                  <Text style={sucursalStyle.barraTextoRojo}>
                    3. Gestiona tus tickets
                  </Text>
                </View>
              </View>
              </View>
            </MediaQuery>

            <MediaQuery maxWidth={1223}>
              <View>
                <Text
                  style={[
                    misTurnosStyle.tituloCabecera,
                    { fontSize: 20 },
                  ]}
                >
                  Tipo de atención y fecha de atención
                </Text>
                <Text
                  style={[
                    misTurnosStyle.subtituloCabecera,
                    { fontSize: 14 },
                  ]}
                >
                  Elige si es una atención de caja o con ejecutivo, y si es un
                  número o un agendamiento
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "95%",
                  marginVertical: 10,
                  alignSelf: "center",
                }}
              >
                <View style={{ width: "33%" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      marginBottom: 5,
                    }}
                  >
                    <FontAwesome
                      name="check-circle"
                      style={{
                        fontSize: 18,
                        color: "#e81e2b",
                        backgroundColor: "#FFF",
                        borderRadius: 10,
                      }}
                    />
                    <View
                      style={{
                        height: 2,
                        width: "90%",
                        backgroundColor: "#e81e2b",
                        marginTop: 10,
                        marginBottom: 10,
                        alignItems: "center",
                        marginRight: 10,
                      }}
                    ></View>
                  </View>
                  <Text style={misTurnosStyle.barraTextoRojo}>
                    1. Elige la sucursal
                  </Text>
                </View>
                <View style={{ width: "33%" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      marginBottom: 5,
                    }}
                  >
                    <FontAwesome
                      name="check-circle"
                      style={{
                        fontSize: 18,
                        color: "#e81e2b",
                        backgroundColor: "#FFF",
                        borderRadius: 10,
                      }}
                    />
                    <View
                      style={{
                        height: 2,
                        width: "90%",
                        backgroundColor: "#e81e2b",
                        marginTop: 10,
                        marginBottom: 10,
                        alignItems: "center",
                        marginRight: 10,
                      }}
                    ></View>
                  </View>
                  <Text style={misTurnosStyle.barraTextoRojo}>
                    2. Obten ticket o agenda hora
                  </Text>
                </View>
                <View style={{ width: "33%" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "90%",
                      justifyContent: "space-between",
                      marginBottom: 5,
                    }}
                  >
                    <FontAwesome
                      name="check-circle"
                      style={{
                        fontSize: 18,
                        color: "#e81e2b",
                        backgroundColor: "#FFF",
                        borderRadius: 10,
                      }}
                    />
                    <View style={{ flexDirection: "row", width: "90%" }}>
                      <View
                        style={{
                          height: 2,
                          width: "100%",
                          backgroundColor: "#e81e2b",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          height: "60%",
                          width: 3,
                          backgroundColor: "#e81e2b",
                          marginTop: 3.5,
                        }}
                      ></View>
                    </View>
                  </View>
                  <Text style={misTurnosStyle.barraTextoRojo}>
                    3. Gestiona tus tickets
                  </Text>
                </View>
              </View>
            </MediaQuery>*/}

            <View
              style={{
                flex: 2,
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              {this.state.misTurnos.map((turno, index) => (
                <View
                  key={index}
                  style={{
                    marginTop: 10,
                    marginRight: 12,
                    marginLeft: 12,
                    marginBottom: 20,
                    width: 300,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <SvgTicket
                      style={{ alignSelf: "center", position: "fixed" }}
                      width={310}
                    />
                    <View
                      style={{
                        alignContent: "center",
                        justifyContent: "center",
                        marginTop: 20,
                      }}
                    >
                      <View style={{ alignItems: "center" }}>
                        <Text style={misTurnosStyle.ticketSucursal}>
                          Sucursal {turno.queueBranch == 'Santiago Centro' ? 'Oficina Matriz 2' : turno.queueBranch}
                        </Text>
                        <Text style={misTurnosStyle.ticketTextoMediano}>
                          {turno.branch.address}, {turno.branch.comuna}
                        </Text>
                        <Text style={{ fonSize: 14, marginTop: 20 }}>
                          Número de atención
                        </Text>
                        <Text style={misTurnosStyle.ticketNumero}>
                          {turno.description}
                          {turno.number}
                        </Text>
                        <Text style={misTurnosStyle.ticketTextQueue}>
                          {turno.queueName}
                        </Text>
                      </View>
                      <View style={{ alignItems: "center", marginTop: 10 }}>
                        <View style={{ flexDirection: "row" }}>
                          <View style={misTurnosStyle.colCard}>
                            <SvgFila width={24} />
                            <View
                              style={{
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                alignContent: "center",
                                marginTop: 10,
                              }}
                            >
                              <Text style={sucursalStyle.negrita}>
                                {turno.number - turno.currentAttendingNumber}{" "}
                                personas
                              </Text>
                              <Text style={sucursalStyle.simpleText}> en </Text>
                              <Text style={sucursalStyle.simpleText}>
                                la fila
                              </Text>
                            </View>
                          </View>
                          <View style={misTurnosStyle.colCard}>
                            <SvgRelojArena width={24} />
                            <View
                              style={{
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                alignContent: "center",
                                marginTop: 10,
                              }}
                            >
                              <Text style={sucursalStyle.negrita}>
                                {turno.timeToWaiting *
                                  (turno.number -
                                    turno.currentAttendingNumber)}{" "}
                                minutos
                              </Text>
                              <Text style={sucursalStyle.simpleText}> de </Text>
                              <Text style={sucursalStyle.simpleText}>
                                espera
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={misTurnosStyle.separadorSearch} />
                      <View style={sucursalStyle.containerAgendarVolver}>
                        {
                          this.props.route.params.codigo ? 
                          <Button
                            buttonStyle={misTurnosStyle.botonReagendar}
                            title={"Ir a inicio"}
                            titleStyle={misTurnosStyle.botonReagendar}
                            onPress={() => {
                              Linking.openURL(window.location.origin);
                            }}
                          />
                          : null
                        }
                        {/*<PedirNumeroComponentRed
                          type="reprogramar"
                          fila={this.state.fila}
                          branch={turno.branch}
                          navigation={this.props.navigation}
                          title={"Reprogramar"}
                          idQueue={turno.queue}
                          description={turno.description}
                          branchState={turno.branch.isOnTurn}
                          branchId={turno.branch._id}
                          onOk={() => this.componentDidMount()}
                        />*/}
                      </View>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>
        )}
        {/*<View style={{ marginTop: 10, marginBottom: 10 }}>
                <Text style={misTurnosStyle.titulo}>
                  <Feather name="clock" size={25} color="grey" /> Tus números activos</Text>

                <View style={{ flex: 2, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                  {
                    this.state.misTurnos.map((turno, index) =>

                      <Card containerStyle={{ borderRadius: 10, padding: 5, width: 280 }} key={index}>
                        <View style={{ flex: 1 }}>
                          <Text style={{ textAlign: 'center', fontSize: 24 }}>Atención:</Text>
                          <Text style={{ textAlign: 'center', fontSize: 24 }}>{turno.queueName}</Text>
                        </View>
                        <View style={{ flex: 1 }}>
                          <View style={sucursalStyle.centrarItem}>
                            <Card containerStyle={sucursalStyle.tuTurno, { backgroundColor: '#333746', borderRadius: 10 }}>
                              <Text style={sucursalStyle.tituloAmarillo}>Tu Número</Text>
                              <Text style={[sucursalStyle.tituloAmarillo, { alignSelf: 'center', fontSize: 27 }]}>{turno.description}{turno.number}</Text>
                            </Card>
                          </View>
                          <View style={{ flexDirection: 'row', flex: 1, marginTop: 10, marginBottom: 10 }}>

                            <View style={sucursalStyle.colCard}>
                              <Entypo name="man" size={24} color="black" />
                              <Text style={sucursalStyle.negrita}>{turno.number - turno.currentAttendingNumber}</Text>
                              <Text>Antes</Text>
                              <Text>que tú</Text>
                            </View>
                            <View style={sucursalStyle.colCard}>
                              <Feather name="clock" size={24} color="black" />
                              <Text style={sucursalStyle.negrita}>{turno.timeToWaiting * (turno.number - turno.currentAttendingNumber)}
                                <Text style={{ fontSize: 14, fontWeight: 'normal' }}>min</Text></Text>
                              <Text style={{ textAlign: 'center' }}>Espera</Text>
                              <Text>Aprox.</Text>
                            </View>
                            <View style={sucursalStyle.colCard}>
                              <Text><Text style={sucursalStyle.atendiendo2}>{turno.timeToWaiting}</Text><Text>min</Text></Text>
                              <Text >Atención</Text>
                              <Text>estimada</Text>
                            </View>

                          </View>
                        </View>
                      </Card>
                    )}
                </View>
                </View>*/}
      </ScrollView>
    );
  }
}

export default (misTurnosComponent);

