import { AntDesign, Entypo } from '@expo/vector-icons';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer, CommonActions } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, {useState} from 'react';
import { Image, TouchableOpacity, View, Text, ScrollView } from 'react-native';
import { Provider } from 'react-redux';
import MediaQuery from 'react-native-web-responsive';
import Inicio from '../components/Inicio/inicio.component';
import webMap from '../components/Map/webMap.component';
import MisReservarComponent from '../components/misReservas/misReservas.component';
import MisTurnosComponent from '../components/misTurnos/misTurnos.component';
import ModalComponent from '../components/Modal/modal.component';
import Pie from '../components/Pie/pie.component';
import ReservarWebComponent from '../components/Reservar/reservarWeb.component';
import SeguimientoComponent from '../components/Seguimiento/seguimiento.component';
import SucursalComponent from '../components/Sucursal/sucursal.component';
import SwiperHelperWeb from '../components/SwiperHelper/swiperHelperWeb.component';
import store from '../redux/store';
import InicioStyle from '../components/Inicio/inicio.style';
import {
  SvgCalendarioBlanco,
  SvgLogoCoopeuch,
  SvgLogoCoopeuchMobile,
} from '../components/svg/svgComponent';
import { TouchableHighlight } from 'react-native';
import routeStyle from './route.style';

const Drawer = createDrawerNavigator();
const MainScreenStack = createStackNavigator();
const SeguimientoReservaStack = createStackNavigator();
const SeguimientoTurnosStack = createStackNavigator();
const RootStack = createStackNavigator();
const isTotemURL = checkTotemUrl()
function LogoTitle(props) {
  return (
    <View style={{ flexDirection: 'row' }}>
      <MediaQuery minWidth={510}>
        <View style={{ flexDirection: 'row', backgroundColor: '#e81e2b' }}>
          <SvgLogoCoopeuch style={{ width: 165, height: 32 }} />
          <TouchableOpacity disabled={isTotemURL}
            style={{ marginHorizontal: 10, flexDirection: 'row' }}
            onPress={() => {
              props.navigation.toggleDrawer();
            }}
          >
            {isTotemURL ?<><Entypo name='menu' size={32} color='#e81e2b' /></>:
            <><Entypo name='menu' size={32} color='#FFF' /></>}
          </TouchableOpacity>
        </View>
      </MediaQuery>
      <MediaQuery maxWidth={509}>
        <View style={{ flexDirection: 'row', backgroundColor: '#e81e2b' }}>
          <SvgLogoCoopeuchMobile style={{ width: 32, height: 32 }} />
          <TouchableOpacity disabled={isTotemURL}
            style={{ marginHorizontal: 10, flexDirection: 'row' }}
            onPress={() => {
              props.navigation.toggleDrawer();
            }}
          >
            {isTotemURL ?<><Entypo name='menu' size={32} color='#e81e2b' /></>:
            <><Entypo name='menu' size={32} color='#FFF' /></>}
          </TouchableOpacity>
        </View>
      </MediaQuery>
    </View>
  );
}
function checkTotemUrl(){
  const urlParams = new URLSearchParams(window.location.search);
  const rutParam = urlParams.get("rut");
  if(rutParam)
  {
    return true;
  }else{
    return false;
  }
}
function Helper(props) {
  return (
    <View style={{ flexDirection: 'row' }}>
      {/*<TouchableOpacity style={{ marginHorizontal: 10, alignItems: 'center', justifyContent: 'flex-end' }} >
                <SvgCalendarioBlanco width={24} style={{marginBottom: 8}} color="#FFF" ></SvgCalendarioBlanco>
                <Text style={InicioStyle.textoAyuda} >Números y horas</Text>
  </TouchableOpacity>*/}
      <TouchableOpacity style={{ marginHorizontal: 10, alignItems: 'center' }} onPress={() => { props.navigation.navigate("SwiperComponent") }}>
          <AntDesign name="questioncircleo" size={24} style={{marginBottom: 8}} color="#FFF" />
          <Text style={InicioStyle.textoAyuda} >¿Necesitas ayuda?</Text>
      </TouchableOpacity>
    </View>
  );
}

// salve stack overflow *praise*
function navigateToScreen(props, screenName) {
  
  props.navigation.dispatch((state) => {
    let i;
  
    for (i = 0; i < state.routes.length; i++) {
      if (state.routes[i].name === screenName) {
        break;
      }
    }
    let routes2 = { ...state.routes };
    if (routes2[i].state) {
      delete routes2[i].state;
    }
    let j
    for (j = 0; j < 3; j++) {
      if (routes2[j].name === "SeguimientoReservas" || routes2[j].name === "Home" || routes2[j].name === "Seguimiento") {
        delete routes2[j].state;
      }
    }
    return CommonActions.reset({
      ...state,
      routes2,
    });
  });
  props.navigation.navigate(screenName);
}

function Mantencion() {
  return (
    <ScrollView contentContainerStyle={{ flex: 1 }}>
      <View style={{ flex: 1, alignItems: 'center' }}>
        <Text style={{ alignSelf: 'center' }}>
          Nos encontramos actualmente en mantención
        </Text>
      </View>
    </ScrollView>
  );
}

export default function Root(props) {
  return (
    <Provider store={store}>
      <NavigationContainer>
        <RootStack.Navigator mode='modal' headerMode='none'>
          {/* <RootStack.Screen name='Mantención' component={Mantencion} /> */}
          <RootStack.Screen name='App' component={AppComponent} />
          <RootStack.Screen
            name='ModalComponent'
            component={ModalComponent}
            options={{ cardStyle: { backgroundColor: 'transparent' } }}
          />
          <RootStack.Screen
            name='SwiperComponent'
            component={SwiperHelperWeb}
            options={{ cardStyle: { backgroundColor: 'transparent' } }}
          />
        </RootStack.Navigator>
      </NavigationContainer>
      <View style={{paddingTop: "0.6px", backgroundColor: "#f2f2f2"}} />
    </Provider>
  );
}
function AppComponent(props) {
  return (
    <Drawer.Navigator
      drawerContent={(props) => {
        return (
          <>
            <View
              style={{
                backgroundColor: '#E81E2B',
                width: '100%',
                height: 63,
                paddingLeft: 30,
                paddingVertical: 20,
              }}
            >
              <Text style={[routeStyle.drawerText, { color: 'white' }]}>
                Menú
              </Text>
            </View>
            <View style={{ paddingLeft: 30 }}>
              <TouchableHighlight
                activeOpacity={0.6}
                underlayColor='white'
                onPress={() => {
                  navigateToScreen(props, 'Home');
                }}
              >
                <View style={{ paddingTop: 20 }}>
                  <Text style={routeStyle.drawerText}>Inicio</Text>
                </View>
              </TouchableHighlight>
              <TouchableHighlight
                activeOpacity={0.6}
                underlayColor='white'
                onPress={() => {
            
                  navigateToScreen(props, 'SeguimientoReservas');
                }}
              >
                <View style={{ paddingVertical: 15 }}>
                  <Text style={routeStyle.drawerText}>
                    Seguimiento de Agendamiento
                  </Text>
                </View>
              </TouchableHighlight>
              <TouchableHighlight
                activeOpacity={0.6}
                underlayColor='white'
                onPress={() => {
                  
                  navigateToScreen(props, 'SeguimientoTurnos');
                }}
              >
                <View>
                  <Text style={routeStyle.drawerText}>Seguimiento de Ticket</Text>
                </View>
              </TouchableHighlight>
            </View>
          </>
        );
      }}
    >
      <Drawer.Screen name='Home' component={MainStackScreen} />
      <Drawer.Screen
        name='SeguimientoReservas'
        component={SeguimientoReservas}
      />
      <Drawer.Screen name='SeguimientoTurnos' component={SeguimientoTurnos} />
    </Drawer.Navigator>
  );
}
function MainStackScreen(props) {
  return (
    <MainScreenStack.Navigator
      sceneContainerStyle={{ flex: 1 }}
      screenOptions={({ navigation, route }) => ({
        headerTitle: (props) => (
          <LogoTitle {...props} navigation={navigation} />
        ),
        headerRight: (props) => <Helper {...props} navigation={navigation} />,
        headerTintColor: 'white',
        headerStyle: {
          backgroundColor: '#e81e2b',
        },
      })}
    >
      <MainScreenStack.Screen name='Inicio' component={Inicio} />
      <MainScreenStack.Screen name='Sucursal' component={SucursalComponent} />
      <MainScreenStack.Screen
        name='Reservar'
        component={ReservarWebComponent}
      />
    </MainScreenStack.Navigator>
  );
}
function SeguimientoReservas(props) {
  return (
    <SeguimientoReservaStack.Navigator
      sceneContainerStyle={{ flex: 1, width: '500px' }}
      screenOptions={({ navigation, route }) => ({
        headerTitle: (props) => (
          <LogoTitle {...props} navigation={navigation} />
        ),
        headerRight: (props) => <Helper {...props} navigation={navigation} />,
        headerTintColor: 'white',
        headerStyle: {
          backgroundColor: '#e81e2b',
        },
      })}
    >
      {/* <SeguimientoReservaStack.Screen
        name='Seguimiento'
        component={SeguimientoComponent}
        initialParams={{ type: 'reservas' }}
      /> */}
      <SeguimientoReservaStack.Screen
        name='SeguimientoReservas'
        component={MisReservarComponent}
      />
    </SeguimientoReservaStack.Navigator>
  );
}

function SeguimientoTurnos(props) {
  return (
    <SeguimientoTurnosStack.Navigator
      sceneContainerStyle={{ flex: 1, width: '500px' }}
      screenOptions={({ navigation, route }) => ({
        headerTitle: (props) => (
          <LogoTitle {...props} navigation={navigation} />
        ),
        headerRight: (props) => <Helper {...props} navigation={navigation} />,
        headerLeft: ()=> {if(isTotemURL){null}},
        headerTintColor: 'white',
        headerStyle: {
          backgroundColor: '#e81e2b',
        },
      })}
    >
      <SeguimientoTurnosStack.Screen
        name='Seguimiento'
        component={SeguimientoComponent}
        initialParams={{
          type: "turnos",
          qrRut:
            props.route.params && props.route.params.rut
              ? props.route.params.rut
              : null,
          qrCodigo:
            props.route.params && props.route.params.codigo
              ? props.route.params.codigo
              : null,
        }}
      />
      <SeguimientoTurnosStack.Screen
        name='SeguimientoTurnos'
        component={MisTurnosComponent}
        headerLeft={isTotemURL}
      />
    </SeguimientoTurnosStack.Navigator>
  );
}
