import { StyleSheet,Dimensions } from "react-native";
import * as Font from 'expo-font';


Font.loadAsync({
  'Ubuntu-Light': require('../../../assets/fonts/Ubuntu-Light.ttf'),
  'Ubuntu-Bold': require('../../../assets/fonts/Ubuntu-Bold.ttf'),
  'Ubuntu-Regular': require('../../../assets/fonts/Ubuntu-Regular.ttf'),
  'Ubuntu-Medium': require('../../../assets/fonts/Ubuntu-Medium.ttf'),
})


export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mapStyle: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  },
  marker: {
    height: 4,
    width: 4
  },
  detalle:
    {

      borderWidth:1,
      borderColor:'rgba(0,0,0,0.2)',
      alignItems:'center',
      justifyContent:'flex-start',
      width:'90%',
      position: 'absolute',                                          
      bottom: 5,        
      backgroundColor:'white',
      marginLeft: '5%',
      marginRight: '5%',
      height:150
    },
    card: {
      
    },
    card2:{
      alignSelf:'center',
      alignItems:'center',
      width:400,
      height: 70,
    },
    cardHeader: {
      alignItems:'center',
      flex: 1,
      flexDirection: 'column',
      width: "100%"
    },
    branchName: {
      flex: 1,
      width:'100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    branchAdress: {
      //backgroundColor:'red',
      
      flex: 1,
      flexDirection: 'column',
      width:'100%', 
      fontSize:10,
      marginTop: 2
    },
    queueName: {
      //backgroundColor:'yellow',
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      width:'100%', 
      fontSize:10,
      marginTop: 4,
      marginBottom: 4,
      padding: 1,
    },
    cardBody: {
      flex: 1,
      flexDirection: 'row'
    },
    miTurno: {
      flex: 1, backgroundColor: '#333746', flexDirection: 'column', alignItems: 'center',
      borderRadius: 5, width: 100, height: 88
    },
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5
    },
    iconB:
    {
      marginTop:4
    },
    scrollView: {
      flex: 1,
      backgroundColor: '#EDEAEA',
      padding:10
    },
    textTitleList:
    {
      fontWeight: 'bold', 
      marginLeft:5, 
      fontSize:10
    },
    contenedor:
    {
      flex: 1,  
      flexDirection: 'row'
    },
    contenedorBusqueda:
    {
      flex: 1
    },
    contenedorLista:
    {
      flex: 2
    },
    contenedorMapa:
    {
      flex: 2
    },
    textSucursalPrevio: {
      fontFamily: 'Ubuntu-Regular',
      fontSize: 14
    },
    textSucursal: {
      fontFamily: 'Ubuntu-Bold',
      fontSize: 14,
    },
    textDireccion:
    {
      color: 'grey', fontSize:10
    },
    textHorario:
    {
      fontWeight: 'bold',
      color: '#007db7', 
      marginLeft:5,
      fontSize:10
    },
    textHorario2:
    {
      color: 'grey', fontSize:10, textAlign:'center'
    },
    textKm:
    {
      fontSize:10
    },
    textTel:
    {
      fontSize:10
    },
    contentVacio:
    {
      justifyContent:'center',
      marginHorizontal: 10
    },
    contentKm:
    {
        justifyContent:'center',
       marginHorizontal: 10, 
       flexDirection: 'column',
       width:'40%'
      //  borderLeftColor: 'gray', borderLeftWidth: 1
    },
    contentTel:
    {
      justifyContent:'center',
      marginHorizontal: 10, 
      // borderLeftColor: 'gray', borderLeftWidth: 2
    },
    font10: {
      fontSize:10, 
      fontWeight:'bold', 
      textDecorationLine:'underline',
    },
    textTagsCard: {
      fontSize: 14,
      color: "#007db7",
      fontWeight: 'bold', 
      fontFamily: 'Ubuntu-Bold',
    },
    textInformationCard: {
      fontFamily: 'Ubuntu-Regular',
      fontSize: 14,
      color: "#a9a9a9",
    },
    botonAgendar: {
      fontFamily: 'Ubuntu-Regular',
      color: "white",
      backgroundColor: "#e81e2b", 
      paddingVertical: 7,
      paddingHorizontal: 20,
      borderRadius: 5,
      alignItems: "center",
      fontSize: 14
    },
    textoBotonAgendar: {
      fontFamily: 'Ubuntu-Regular',
      color: "#FFF",
      fontSize: 16
    },
    separadorInfoWindow: {
      height: 1, 
      width: "100%", 
      backgroundColor: "#e1e8ee", 
      marginTop: 10, 
      marginBottom: 10,
    },
    branchStateOpen: { 
      fontFamily: 'Ubuntu-Light',
      fontSize: 12, 
      color: 'white', 
      backgroundColor: '#0e891a', 
      paddingLeft: 8, 
      paddingRight: 8, 
      paddingTop: 1, 
      paddingBottom: 1, 
      borderRadius: 10 
    },
    branchStateClose: {
      fontFamily: 'Ubuntu-Light',
      fontSize: 12, 
      color: 'white', 
      backgroundColor: '#e81e2b', 
      paddingLeft: 8, 
      paddingRight: 8, 
      paddingTop: 1, 
      paddingBottom: 1, 
      borderRadius: 10 
    }
  });