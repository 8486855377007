import FontAwesome from "react-native-vector-icons/FontAwesome5";
import es from "date-fns/locale/es";
import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ActivityIndicator,
  ScrollView,
  View,
  TouchableOpacity,
} from "react-native";
import { Text } from "react-native-elements";
import MediaQuery from "react-native-web-responsive";
import { Portal } from "react-overlays";
import reservarFunctions from "./reservar.functions";
import reservarStyle from "./reservar.style";
import { Picker } from "@react-native-community/picker";
import {
  SvgDistancia,
  SvgMapaBlue,
  SvgReloj,
  SvgDia,
  SvgCaja,
  SvgPersonas,
} from "./reservar.svgs";
import branchStateFunction from "../Utils/branchState.function";
import sucursalStyle from "../Sucursal/sucursal.style";
import inicioFunctions from "../Inicio/inicio.functions";
import { INICIO_CIERRE, FIN_CIERRE } from '@env';
import CapitalizedText from '../Utils/common.functions'
import moment from "moment";

registerLocale("es", es);

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

const ExampleCustomInput = ({ value, onClick }) => (
  <button
    style={{
      borderRadius: 5,
      color: "#007db7",
      textAlign: "center",
      flexDirection: "row",
      fontSize: 16,
      width: 280,
      height: 48,
      paddingLeft: 10,
      paddingRight: 10,
      backgroundColor: "white",
    }}
    onClick={onClick}
  >
    {value}
    <SvgDia width={20} height={20} style={{ marginLeft: 140 }} />
  </button>
);

class ReservarWebComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      horasDisponibles: null,
      isReady: false,
      date: new Date(),
      minDate: new Date(),
      isMinDateSet: false,
      address: "",
      comuna: "",
      horariosBranch: [],
      user: this.props.route.params.user,
      noGps: this.props.route.params.noGps,
      branchName: this.props.route.params.branchName,
      queueName: this.props.route.params.queueName,
      branchId: this.props.route.params.branchId,
      queueId: this.props.route.params.queueId,
      description: this.props.route.params.description,
      idReserva: this.props.route.params.idReserva,
      groupName: this.props.route.params.groupName,
      reservationType: "",
      intervalo: null,
      reservationCapacity: 0,
      hourReservation: "",
      maxDays: this.props.route.params.maxDays
        ? this.props.route.params.maxDays
        : 10,
      diasReserva: [],
      feriados: [],
      distance: this.props.route.params.branch
        ? this.props.route.params.branch.distance
        : 0,
      phone: this.props.route.params.branch
        ? this.props.route.params.branch.phone
        : "",
      branch: this.props.route.params.branch,
      isFeriadosReady: false,
      groupQueues: [],
      queueSelect: null,
    };
  }

  getGroupNames() {
    reservarFunctions.getGroupName(this.state.branchName, this.state.groupName).then((response) => {
      this.setState({
        groupQueues: response.data.queues,
      });
    }
    ).catch((error) => {
      alert("Error al cargar las horas disponibles");
      this.props.navigation.goBack();
    }
    );
  }

  getHours() {
    reservarFunctions
      .getAvailableHoursByDay(
        this.state.date,
        this.state.queueId,
        this.state.branchId,
        this.state.groupName,
        this.state.groupQueues.name
      )
      .then((response) => {
        this.setState({
          horasDisponibles: this.filtrarHoras(response),
          reservationType: response.data.fila.reservationType,
          reservationCapacity: response.data.fila.reservationCapacity,
          intervalo: response.data.fila.intervaloReserva,
          isReady: true,
          reservationError: false,
        });
        if (
          response.data.availableHours.length === 0 ||
          (reservarFunctions.isSelectedDateHoliday(
            this.state.date,
            this.props.route.params.branch
              ? this.props.route.params.branch.feriados
              : this.state.feriados
          ) &&
            this.state.isFeriadosReady)
        ) {
          var newDate = new Date(
            this.state.date.setDate(this.state.date.getDate() + 1)
          );

          var isWorkingDay = false;

          while (!isWorkingDay) {
            for (var i = 0; i < this.state.diasReserva.length; i++) {
              if (
                this.state.diasReserva[i].getDate() === newDate.getDate() &&
                this.state.diasReserva[i].getMonth() === newDate.getMonth() &&
                !reservarFunctions.isSelectedDateHoliday(
                  newDate,
                  this.props.route.params.branch
                    ? this.props.route.params.branch.feriados
                    : this.state.feriados
                ) &&
                this.state.isFeriadosReady
              ) {
                isWorkingDay = true;
                break;
              }
            }
            if (!isWorkingDay) {
              newDate = new Date(newDate.setDate(newDate.getDate() + 1));
            }
          }
          this.setState({ minDate: this.state.date, isMinDateSet: true });
          this.setDay(newDate);
        }
      })
      .catch((error) => {
        alert("Error al cargar las horas disponibles");
        this.props.navigation.goBack();
      });
  }

  setWorkingDays() {
    let day = new Date();
    let workingDays = [];
    while (workingDays.length < this.state.maxDays) {
      if (day.getDay() !== 0 && day.getDay() !== 6) {
        workingDays.push(new Date(day));
      }
      if (
        this.props.route.params.branch &&
        this.props.route.params.branch.horarios[0].dias.includes("Domingo") &&
        day.getDay() === 0
      ) {
        workingDays.push(new Date(day));
      }
      if (
        this.props.route.params.branch &&
        this.props.route.params.branch.horarios[0].dias.includes("Sabado") &&
        day.getDay() === 6
      ) {
        workingDays.push(new Date(day));
      }
      const auxDate = day.getDate();
      day.setDate(auxDate + 1);
    }
    this.setState({ diasReserva: workingDays });
  }

  setHolidays() {
    if (
      this.props.route.params.branch &&
      this.props.route.params.branch.feriados
    ) {
      let feriados = [];
      const feriadosAux = this.props.route.params.branch.feriados;
      feriadosAux.forEach((feriado) => {
        if (feriado.aplica) {
          feriados.push(new Date(feriado.dia.substring(0, 19)));
        }
      });
      this.setState({
        feriados: feriados,
        isFeriadosReady: true,
      });
    }
  }

  findCoordinates = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = JSON.stringify(position);

          let coordinates = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          resolve(coordinates);
        },
        (error) => reject(null),
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );
    });
  };

  componentDidMount() {
    if (!this.props.route.params.branch) {
      reservarFunctions.getSucursal(this.state.branchName).then((response) => {
        const branch = response.data.branches[0];
        this.findCoordinates().then((coordinates) => {
          this.setState({
            phone: branch.phone,
            feriados: branch.feriados,
            isFeriadosReady: true,
            horarios: branch.horarios,
            distance: inicioFunctions.getDistanceFromLatLonInKm(
              branch.lat,
              branch.long,
              coordinates.latitude,
              coordinates.longitude
            ),
            branch: branch,
          });
        }).catch(async (error) => {
          this.setState({
            phone: branch.phone,
            feriados: branch.feriados,
            isFeriadosReady: true,
            horarios: branch.horarios,
            distance: inicioFunctions.getDistanceFromLatLonInKm(
              branch.lat,
              branch.long,
              -33.4360892,
              -70.6446652,
            ),
            branch: branch,
          });
        });;
        reservarFunctions
          .getHorarioDireccion(this.state.branchId)
          .then((response) => {
            var count = response.data.response.horarios.length;
            for (var i = 0; i < count; i++) {
              reservarFunctions.scheduleFormat(
                response.data.response.horarios[i]
              );
            }
            this.setState({
              horariosBranch: response.data.response.horarios,
              comuna: response.data.response.comuna,
              address: response.data.response.address,
            });
            this.getGroupNames()
            this.getHours();
            this.setWorkingDays();
            this.setHolidays();
          })
          .catch((error) => {
            alert("Error al cargar la informacion de la sucursal");
            this.props.navigation.goBack();
          });
      });
    } else {
      reservarFunctions
        .getHorarioDireccion(this.state.branchId)
        .then((response) => {
          var count = response.data.response.horarios.length;
          for (var i = 0; i < count; i++) {
            reservarFunctions.scheduleFormat(response.data.response.horarios[i]);
          }
          this.setState({
            horariosBranch: response.data.response.horarios,
            comuna: response.data.response.comuna,
            address: response.data.response.address,
          });
          this.getGroupNames();
          this.getHours();
          this.setWorkingDays();
          this.setHolidays();
        })
        .catch((error) => {
          alert("Error al cargar la informacion de la sucursal");
          this.props.navigation.goBack();
        });
    }
  }
  checkHour(hora) {
    const hourToCheck = moment(`2021-01-01 ${hora.hour}`, "YYYY-MM-DD HH:mm:ss")
    const pauseStart = moment(`2021-01-01 ${INICIO_CIERRE}`, "YYYY-MM-DD HH:mm:ss")
    const pauseEnd = moment(`2021-01-01 ${FIN_CIERRE}`, "YYYY-MM-DD HH:mm:ss")

    return !hourToCheck.isBetween(pauseStart, pauseEnd, undefined, '[]')
  }

  verificarUltimoTramo(hora) {

    return hora;
  }
  filtrarHoras(response) {
    let horasDisponibles = response.data.availableHours;
    let horasFiltradas;
    // if (!INICIO_CIERRE == "" || !FIN_CIERRE == "") {
    //   for (let i = 0; i < response.data.availableHours.length; i++) {
    //     let momentHour = moment(response.data.availableHours[i].hour, 'hh:mm');
    //     horasFiltradas = horasDisponibles.filter(hour => this.checkHour(hour));
    //   }
    //   console.log(horasFiltradas, 'horasFiltradas')

    //   return horasFiltradas
    // } else {
      return horasDisponibles;
    // }

  }
  async setDay(date) {
    this.setState({ isReady: false });
    reservarFunctions
      .getAvailableHoursByDay(date, this.state.queueId, this.state.branchId)
      .then((response) => {
        this.setState({
          horasDisponibles: this.filtrarHoras(response),
          reservationType: response.data.fila.reservationType,
          reservationCapacity: response.data.fila.reservationCapacity,
          intervalo: response.data.fila.intervaloReserva,
          date: date,
          isReady: true,
        });

      })
      .catch((error) => {
        console.log(error, "error");
        alert("Error al cargar las horas disponibles");
        this.props.navigation.goBack();
      });
  }

  async confirmarPedido(hora) {
    console.log(this.state.queueSelect)
    console.log(this.state.groupName)
    if (this.state.groupName === undefined) {
      reservarFunctions
      .verifyPassedHour(hora, this.state.date)
      .then((response) => {
        this.props.navigation.navigate("ModalComponent", {
          queueName: this.state.groupName ? this.state.queueSelect : this.state.queueName,
          hora: hora,
          branchId: this.state.branchId,
          queueId: this.state.queueId,
          dateToReservation: this.state.date,
          type: "confReserva",
          openCode: this.props.route.params.openCode,
          tipoFuncion: this.props.route.params.tipoFuncion,
          description: this.state.description,
          branchName: this.state.branchName,
          idReserva: this.state.idReserva,
          site: this.props.route.params.site,
          reservationType: this.state.reservationType,
          branch: this.state.branch,
          user: this.state.user,
          onGoBack: () => this.getHours(),
          onSuccess: this.props.route.params.onGoBack
            ? this.props.route.params.onGoBack
            : null,
        });
      })
      .catch(async (error) => {
        this.setState({ isReady: false });
        alert("Horario no disponible, favor de seleccionar uno nuevo");
        this.getHours();
        if (this.state.groupName){
          this.getGroupNames()
        }
      });
    } else if (this.state.queueSelect) {
      reservarFunctions
      .verifyPassedHour(hora, this.state.date)
      .then((response) => {
        this.props.navigation.navigate("ModalComponent", {
          queueName: this.state.groupName ? this.state.queueSelect : this.state.queueName,
          hora: hora,
          branchId: this.state.branchId,
          queueId: this.state.queueId,
          dateToReservation: this.state.date,
          type: "confReserva",
          openCode: this.props.route.params.openCode,
          tipoFuncion: this.props.route.params.tipoFuncion,
          description: this.state.description,
          branchName: this.state.branchName,
          idReserva: this.state.idReserva,
          site: this.props.route.params.site,
          reservationType: this.state.reservationType,
          branch: this.state.branch,
          user: this.state.user,
          onGoBack: () => this.getHours(),
          onSuccess: this.props.route.params.onGoBack
            ? this.props.route.params.onGoBack
            : null,
        });
      })
      .catch(async (error) => {
        this.setState({ isReady: false });
        alert("Horario no disponible, favor de seleccionar uno nuevo");
        this.getHours();
        if (this.state.groupName){
          this.getGroupNames()
        }
      });
    } else {
      alert("Seleccione un tipo de atención");
    }
  }

  addInterval(time, minsToAdd) {
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }
    var piece = time.split(":");
    var mins = piece[0] * 60 + +piece[1] + +(minsToAdd);

    var pieceCierreSucursal = this.state.horariosBranch[0].horaCierre.split(":");
    

    var horaFinalString= D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60)
    var diaSemana = CapitalizedText(moment(this.state.date).locale('es').format('dddd'))   
    let horaCierre = moment(`2021-01-01 ${this.state.horariosBranch[0].horaCierre}`, "YYYY-MM-DD HH:mm:ss")
    if(this.state.horariosBranch.length===1){
      horaCierre = moment(`2021-01-01 ${this.state.horariosBranch[0].horaCierre}`, "YYYY-MM-DD HH:mm:ss")
      pieceCierreSucursal = this.state.horariosBranch[0].horaCierre.split(":");
    }
    if(this.state.horariosBranch.length>1){
      this.state.horariosBranch.map((tram)=>{
        if(diaSemana==='Miércoles'){
          if(tram.dias.includes('Miercoles')){
            horaCierre = moment(`2021-01-01 ${tram.horaCierre}`, "YYYY-MM-DD HH:mm:ss")
            pieceCierreSucursal = tram.horaCierre.split(":")
          }
        }
        if(diaSemana==='Sábado'){
          if(tram.dias.includes('Sabado')){
            horaCierre = moment(`2021-01-01 ${tram.horaCierre}`, "YYYY-MM-DD HH:mm:ss")
            pieceCierreSucursal = tram.horaCierre.split(":")
          }
        }
        if(tram.dias.includes(diaSemana))
        {
          horaCierre = moment(`2021-01-01 ${tram.horaCierre}`, "YYYY-MM-DD HH:mm:ss")
          pieceCierreSucursal = tram.horaCierre.split(":")
        }
      })
    }
    var minsCierreSucursal = pieceCierreSucursal[0] * 60 + +pieceCierreSucursal[1];
    const horaFinal = moment(`2021-01-01 ${horaFinalString}`, "YYYY-MM-DD HH:mm:ss")
    if (horaFinal.isAfter(horaCierre)) {
      mins = minsCierreSucursal;
    }
    return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
  }

  cargaHora = (value, index) => {
    this.setState({
      hourReservation: value,
    });
  };

  render() {
    var fechaLimite = new Date();

    return (
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <View style={{ maxWidth: 983, maxHeight: 908, alignSelf: "center", width: '100%' }}>
          <MediaQuery minWidth={1224}>
            <View style={{ marginHorizontal: '17px', marginBottom: '28px' }}>
              <View>
                <Text style={sucursalStyle.tituloWizard}>Selecciona la fecha y tipo de atención</Text>
                <Text style={sucursalStyle.subtituloWizard}>Elige la fecha de tu atención, así como también si es una atención en Cajas o con un Ejecutivo(a).</Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "center",
                  width: "937px",
                }}
              >
                <View style={{ width: "302px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                  >
                    <FontAwesome
                      name="check-circle"
                      style={{
                        fontSize: 22,
                        color: "#e81e2b",
                        marginRight: "10px",
                      }}
                    />
                    <View
                      style={{
                        height: 2,
                        width: "270px",
                        backgroundColor: "#e81e2b",
                        marginTop: 10,
                        marginBottom: 10,
                        alignItems: "center",
                        marginRight: 10,
                      }}
                    ></View>
                  </View>
                  <Text style={sucursalStyle.barraTextoRojo}>
                    1. Sucursal
                  </Text>
                </View>
                <View style={{ width: "302px", marginLeft: "10px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        height: 22,
                        width: 22,
                        border: "8px solid #e81e2b",
                        borderRadius: 30,
                        marginRight: "10px",
                      }}
                    />
                    <View
                      style={{
                        height: 2,
                        width: "272px",
                        backgroundColor: "#d8d8d8",
                        marginTop: 10,
                        marginBottom: 10,
                        alignItems: "center",
                        marginRight: 10,
                      }}
                    ></View>
                  </View>
                  <Text
                    style={[sucursalStyle.barraTextoRojo, { color: "#e81e2b" }]}
                  >
                    2. Agenda tu hora
                  </Text>
                </View>
                <View style={{ width: "306px", marginLeft: "10px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        height: 22,
                        width: 22,
                        border: "2px solid #d8d8d8",
                        backgroundColor: "#FFF",
                        borderRadius: 30,
                        marginRight: "10px",
                      }}
                    />
                    <View style={{ flexDirection: "row", width: "90%" }}>
                      <View
                        style={{
                          height: 2,
                          width: "274px",
                          backgroundColor: "#d8d8d8",
                          marginTop: 10,
                          marginBottom: 10,
                          alignItems: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          height: "12px",
                          width: 3,
                          backgroundColor: "#d8d8d8",
                          borderRadius: "1.5px",
                          marginTop: 3.5,
                        }}
                      ></View>
                    </View>
                  </View>
                  <Text
                    style={[sucursalStyle.barraTextoRojo, { color: "#d8d8d8" }]}
                  >
                    3. Comprobante
                  </Text>
                </View>
              </View>
            </View>
          </MediaQuery>


          <MediaQuery maxWidth={1223}>
            <View style={{ paddingHorizontal: '10px', marginBottom: '15px' }}>
              <View>
                <Text
                  style={[
                    sucursalStyle.tituloWizard, { marginTop: '10px', fontSize: 20 }
                  ]}
                >
                  Selecciona la fecha y tipo de atención
                </Text>
                <Text
                  style={[
                    sucursalStyle.subtituloWizard, { marginBottom: '15px', fontSize: 14 }
                  ]}
                >
                  Elige la fecha de tu atención, así como también si es una atención en Cajas o con un Ejecutivo(a).
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignSelf: "center",
                }}
              >
                <View style={{ width: '100%' }}>
                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10
                  }}
                  >
                    <View
                      style={{ height: 22, width: 22, border: "8px solid #F42534", borderRadius: 30 }}
                    />
                    <View style={{
                      height: 2,
                      width: '94%',
                      backgroundColor: "#d8d8d8",
                      marginTop: 10,
                      marginBottom: 10,
                      alignItems: "center",
                    }}
                    ></View>
                  </View>
                  <Text style={[sucursalStyle.barraTextoRojo, { color: '#F42534' }]}>
                    2. Agenda tu hora
                  </Text>
                </View>
              </View>
            </View>
          </MediaQuery>
          <MediaQuery minWidth={1224}>
            <View style={[sucursalStyle.centrarItem, { maxWidth: '969px', maxHeight: '255px', margin: '16px' }]}>
              <Text style={sucursalStyle.tituloSucursal}>Sucursal elegida: Sucursal {this.state.branchName == 'Santiago Centro' ? 'Oficina Matriz 2' : this.state.branchName}
                {branchStateFunction(this.state.horariosBranch, this.state.branchName) &&
                  !inicioFunctions.isTodayHoliday(
                    this.props.route.params.branch ? this.props.route.params.branch.feriados : this.state.feriados
                  ) && this.state.isFeriadosReady ?
                  <View style={{ flexDirection: 'row', alignItems: "center", marginLeft: '16px' }}>
                    <Text style={sucursalStyle.branchStateOpen}>Abierto</Text>
                  </View>
                  :
                  <View style={{ flexDirection: 'row', alignItems: "center", marginLeft: '16px' }}>
                    <Text style={sucursalStyle.branchStateClose}>Cerrado</Text>
                  </View>
                }
              </Text>
              <View style={sucursalStyle.separadorSearch} />
              <View style={sucursalStyle.contenedorBranchData}>
                <View style={{ alignSelf: "flex-start", width: "50%" }}>
                  <View style={{ flexDirection: "row", flexWrap: 'wrap', marginBottom: '11px' }}>
                    <SvgMapaBlue width={16} height={16} />
                    <Text style={sucursalStyle.textTagsCard}>Dirección: </Text>
                    <Text style={[sucursalStyle.textInformationCard, { textTransform: 'capitalize' }]}>{this.state.address}, {this.state.comuna}</Text>
                  </View>
                  {!this.state.noGps && 
                    <View style={{ flexDirection: "row", marginBottom: '11px' }}>
                      <SvgDistancia width={16} height={16} />
                      <Text style={sucursalStyle.textTagsCard}>Distancia: </Text>
                      <Text style={sucursalStyle.textInformationCard}>{this.state.distance} km</Text>
                    </View>
                  }
                </View>
                <View style={{ alignSelf: "flex-start", width: '50%', marginBottom: '11px' }}>
                  <View style={{ display: 'table-row', flexWrap: 'wrap', width: '100%' }}>
                    <SvgReloj width={16} height={16} />
                    <Text style={sucursalStyle.textTagsCard}>Horarios de Atención: </Text>
                    {
                      this.state.horariosBranch.map(horarios => {
                        return (
                          horarios.inicioBreak !== 'Sin Break' ?
                            <Text key={horarios._id} style={sucursalStyle.textInformationCard}>{horarios.horarioFormato} de {horarios.horaApertura} Hrs a {horarios.inicioBreak} Hrs y {horarios.terminoBreak} Hrs a {horarios.horaCierre} Hrs. </Text>
                            :
                            <Text key={horarios._id} style={sucursalStyle.textInformationCard}>{horarios.horarioFormato} de {horarios.horaApertura} Hrs a {horarios.horaCierre} Hrs.</Text>
                        )
                      }
                      )
                    }
                  </View>
                </View>
              </View>
              <View style={reservarStyle.separadorSearch2} />
              <View style={{ flexDirection: "row", paddingVertical: '15px', paddingLeft: '31px' }}>
                {this.state.queueName.toUpperCase() == "CAJA" ? (
                  <SvgCaja width={32} height={32} />
                ) : (
                  <SvgPersonas width={20} height={32} />
                )}
                <View style={{ marginLeft: '18px', alignSelf: 'center' }}>
                  <Text
                    style={[
                      reservarStyle.nameBranch,
                    ]}
                  >
                    {" "}
                    Tipo de Atención:
                    <Text style={{ textTransform: 'capitalize' }}>
                      {" "}
                      {this.state.groupName ? this.state.groupName : this.state.queueName}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>

          </MediaQuery>

          <MediaQuery maxWidth={1223}>
            <View style={[reservarStyle.centrarItem, { margin: '5px' }]}>
              <Text style={reservarStyle.tituloSucursal}>
                Sucursal elegida: Sucursal {this.state.branchName == 'Santiago Centro' ? 'Oficina Matriz 2' : this.state.branchName}
                {branchStateFunction(this.state.horariosBranch, this.state.branchName) &&
                  !inicioFunctions.isTodayHoliday(
                    this.props.route.params.branch ? this.props.route.params.branch.feriados : this.state.feriados
                  ) && this.state.isFeriadosReady ? (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 8,
                    }}
                  >
                    <Text style={reservarStyle.branchStateOpen}>Abierto</Text>
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 8,
                    }}
                  >
                    <Text style={reservarStyle.branchStateClose}>Cerrado</Text>
                  </View>
                )}
              </Text>
              <View style={reservarStyle.separadorSearch} />
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  paddingHorizontal: 20,
                  paddingTop: 5,
                  width: "100%",
                }}
              >
                <View
                  style={{
                    alignSelf: "flex-start",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    <SvgMapaBlue width={18} height={18} />
                    <Text style={reservarStyle.textTagsCard}>Dirección: </Text>
                    <Text style={[reservarStyle.textInformationCard, { textTransform: 'capitalize' }]}>
                      {this.state.address}, {this.state.comuna}
                    </Text>
                  </View>
                  {!this.state.noGps &&
                    <View style={{ flexDirection: "row", marginBottom: 10 }}>
                      <SvgDistancia width={18} height={18} />
                      <Text style={reservarStyle.textTagsCard}>Distancia: </Text>
                      <Text style={reservarStyle.textInformationCard}>
                        {this.state.distance} km
                      </Text>
                    </View>
                  }
                  {/* <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginBottom: 10,
                  }}
                >
                  <SvgTelefono width={18} />
                  <Text style={reservarStyle.textTagsCard}>Teléfono: </Text>
                  <Text style={reservarStyle.textInformationCard}>
                    {this.state.phone}600 600 9000
                  </Text>
                </View> */}
                </View>
                <View
                  style={{
                    alignSelf: "flex-start",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    <SvgReloj width={18} height={18} />
                    <Text style={reservarStyle.textTagsCard}>
                      Horarios de Atención:{" "}
                    </Text>
                    {this.state.horariosBranch.map((horarios) => {
                      return horarios.inicioBreak !== "Sin Break" ? (
                        <Text
                          key={horarios._id}
                          style={reservarStyle.textInformationCard}
                        >
                          {horarios.horarioFormato} de {horarios.horaApertura}{" "}
                          Hrs a {horarios.inicioBreak} Hrs y {horarios.terminoBreak} Hrs a{" "}
                          {horarios.horaCierre} Hrs.
                        </Text>
                      ) : (
                        <Text
                          key={horarios._id}
                          style={reservarStyle.textInformationCard}
                        >
                          {horarios.horarioFormato} de {horarios.horaApertura}{" "}
                          Hrs a {horarios.horaCierre} Hrs.
                        </Text>
                      );
                    })}
                  </View>
                  <View style={reservarStyle.separadorSearch2} />
                  <View style={{ flexDirection: "row" }}>
                    {this.state.queueName.toUpperCase() == "CAJA" ? (
                      <SvgCaja width={22} height={22} style={{ padding: 10 }} />
                    ) : (
                      <SvgPersonas width={20} height={20} style={{ padding: 10 }} />
                    )}
                    <View>
                      <Text
                        style={[
                          reservarStyle.tituloSucursal,
                          {
                            paddingLeft: 0,
                            paddingBottom: 0,
                            paddingTop: 10,
                            paddingRight: 10,
                          },
                        ]}
                      >
                        {" "}
                        Tipo de Atención:
                      </Text>
                      <Text
                        style={[
                          reservarStyle.tituloSucursal,
                          {
                            paddingLeft: 0,
                            paddingTop: 0,
                            paddingBottom: 10,
                            paddingRight: 10,
                            textTransform: 'capitalize'
                          },
                        ]}
                      >
                        {" "}
                        {this.state.queueName}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </MediaQuery>

          <MediaQuery minWidth={1224}>
            <View
              style={[
                reservarStyle.centrarItem,
                { maxWidth: "969px", maxHeight: "201px", margin: "16px" },
              ]}
            >
              <Text
                style={[
                  reservarStyle.tituloEleccion
                ]}
              >
                ¿A qué hora y qué día te acomoda visitar una de nuestras sucursales?
              </Text>
              <View style={reservarStyle.separadorSearch} />

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignSelf: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {!this.state.isReady ? (
                  <View
                    style={{
                      marginTop: "23px",
                      marginLeft: "32px",
                      marginBottom: "32px",
                    }}
                  >
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona el día de agendamiento
                    </Text>
                    <ActivityIndicator></ActivityIndicator>
                  </View>) : (
                  <View
                    style={{
                      marginTop: "23px",
                      marginLeft: "32px",
                      marginBottom: "32px",
                    }}
                  >
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona el día de agendamiento
                    </Text>
                    <DatePicker
                      style={{ width: 280, height: 48 }}
                      popperContainer={CalendarContainer}
                      customInput={<ExampleCustomInput />}
                      selected={this.state.date}
                      onChange={(date) => this.setDay(date)}
                      dateFormat="dd/MM/yyyy"
                      minDate={this.state.minDate}
                      maxDate={fechaLimite.setFullYear(
                        fechaLimite.getFullYear() + 1
                      )}
                      tabIndex={3}
                      locale="es"
                      excludeDates={this.state.feriados}
                      includeDates={this.state.diasReserva}
                    />
                  </View>
                )}
                {!this.state.isReady && this.state.groupName ? (
                  <View style={{ marginLeft: '24px', marginTop: '23px', marginBottom: '26px' }}>
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona Fila de Atención
                    </Text>
                    <ActivityIndicator></ActivityIndicator>
                  </View>
                ) : this.state.groupName ? (
                  <View style={{ marginLeft: '24px', marginTop: '23px', marginBottom: '26px' }}>
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona Fila de Atención
                    </Text>
                    <Picker
                      style={{
                        borderWidth: 1,
                        borderRadius: 5,
                        color: "#007db7",
                        fontSize: 16,
                        width: 280,
                        height: 48,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                      onValueChange={(value) =>{
                        const queue = this.state.groupQueues.find(queue => queue.name === value)
                        this.setState({
                          queueSelect: value,
                          queueId: queue._id,
                          description: queue.description,
                        })
                      }}
                    >
                      <Picker.Item
                        itemStyle={{
                          color: "#000000",
                          fontSize: 20,
                          height: 48,
                        }}
                        label="Seleccionar Fila de Atención"
                        value="0"
                      />
                      {this.state.groupQueues.map(
                        (group, index) => (
                          <Picker.Item
                            itemStyle={{
                              color: "#000000",
                              fontSize: 20,
                              height: 48,
                            }}
                            label={group.name}
                            value={group.name}
                          />
                        )
                      )}
                    </Picker>
                  </View>
                ) : null}
                {!this.state.isReady ? (
                  <View style={{ marginLeft: '24px', marginTop: '23px', marginBottom: '26px' }}>
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona hora
                    </Text>
                    <ActivityIndicator></ActivityIndicator>
                  </View>
                ) : this.state.horasDisponibles &&
                  this.state.horasDisponibles.length > 0 &&
                  !reservarFunctions.isSelectedDateHoliday(
                    this.state.date, this.props.route.params.branch ? this.props.route.params.branch.feriados : this.state.feriados
                  ) && this.state.isFeriadosReady ? (
                  <View style={{ marginLeft: '24px', marginTop: '23px', marginBottom: '26px' }}>
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona hora
                    </Text>
                    <Picker
                      style={{
                        borderWidth: 1,
                        borderRadius: 5,
                        color: "#007db7",
                        fontSize: 16,
                        width: 280,
                        height: 48,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                      onValueChange={this.cargaHora}
                    >
                      <Picker.Item
                        itemStyle={{
                          color: "#000000",
                          fontSize: 20,
                          height: 48,
                        }}
                        label="Seleccionar hora"
                        value="0"
                      />
                      {this.state.horasDisponibles.map(
                        (horaDisponible, index) => (
                          <Picker.Item
                            itemStyle={{
                              color: "#000000",
                              fontSize: 20,
                              height: 48,
                            }}
                            label={
                              horaDisponible.hour 
                              // +
                              // " - " +
                              // this.addInterval(
                              //   horaDisponible.hour,
                              //   this.state.intervalo
                              // )
                            }
                            value={horaDisponible.hour}
                          />
                        )
                      )}
                    </Picker>
                    <Text style={reservarStyle.subtituloInput}>
                      Recuerda llegar 10 minutos antes
                    </Text>
                  </View>
                ) : (
                  <View style={{ marginLeft: '24px', marginTop: '23px', marginBottom: '26px' }}>
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona hora
                    </Text>
                    <Picker
                      enabled={false}
                      style={{
                        borderWidth: 1,
                        borderRadius: 5,
                        width: 280,
                        height: 48,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    ></Picker>
                    <Text style={reservarStyle.subtituloInputDisabled}>
                      Recuerda llegar 10 minutos antes
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </MediaQuery>

          <MediaQuery maxWidth={1223}>
            <View style={[reservarStyle.centrarItem, { margin: '5px' }]}>
              <Text
                style={[
                  reservarStyle.tituloEleccion,
                  {
                    fontSize: 20,
                    marginTop: 10,
                    marginRight: 10,
                    marginLeft: 10,
                  },
                ]}
              >
                ¿A qué hora y qué día te acomoda visitar una de nuestras sucursales?
              </Text>
              <View style={reservarStyle.separadorSearch} />

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignSelf: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {!this.state.isReady ? (
                  <View style={{ margin: 5 }}>
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona el día de agendamiento
                    </Text>
                    <ActivityIndicator></ActivityIndicator>
                  </View>
                ) : (
                  <View style={{ margin: 5 }}>
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona el día de agendamiento
                    </Text>
                    <DatePicker
                      style={{ width: 280, height: 48 }}
                      popperContainer={CalendarContainer}
                      customInput={<ExampleCustomInput />}
                      selected={this.state.date}
                      onChange={(date) => this.setDay(date)}
                      dateFormat="dd/MM/yyyy"
                      minDate={this.state.minDate}
                      maxDate={fechaLimite.setFullYear(
                        fechaLimite.getFullYear() + 1
                      )}
                      tabIndex={3}
                      locale="es"
                      excludeDates={this.state.feriados}
                      includeDates={this.state.diasReserva}
                    />
                  </View>
                )}
                {
                  !this.state.isReady && this.state.groupName ? (
                    <View style={{ margin: 5 }}>
                      <Text style={reservarStyle.tituloInput}>
                        Selecciona Fila de Atención
                      </Text>
                      <ActivityIndicator></ActivityIndicator>
                    </View>
                  ) : this.state.groupName ? (
                    <View style={{ margin: 5 }}>
                      <Text style={reservarStyle.tituloInput}>
                        Selecciona Fila de Atención
                      </Text>
                      <Picker
                        style={{
                          borderWidth: 1,
                          borderRadius: 5,
                          color: "#007db7",
                          fontSize: 16,
                          width: 280,
                          height: 48,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                        onValueChange={(value) => {
                          const queue = this.state.groupQueues.find(queue => queue.name === value)
                          this.setState({
                            queueSelect: value,
                            queueId: queue._id,
                            description: queue.description,
                          })
                        }}
                      >
                        <Picker.Item
                          itemStyle={{
                            color: "#000000",
                            fontSize: 20,
                            height: 48,
                          }}
                          label="Seleccionar Fila de Atención"
                          value="0"
                        />
                        {this.state.groupQueues.map(
                          (group, index) => (
                            <Picker.Item
                              itemStyle={{
                                color: "#000000",
                                fontSize: 20,
                                height: 48,
                              }}
                              label={group.name}
                              value={group.name}
                            />
                          )
                        )}
                      </Picker>
                    </View>
                  ) : null }
                {!this.state.isReady ? (
                  <View style={{ marginLeft: '24px', marginTop: '23px', marginBottom: '26px' }}>
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona hora
                    </Text>
                    <ActivityIndicator></ActivityIndicator>
                  </View>
                ) : this.state.horasDisponibles &&
                  this.state.horasDisponibles.length > 0 &&
                  !reservarFunctions.isSelectedDateHoliday(
                    this.state.date, this.props.route.params.branch ? this.props.route.params.branch.feriados : this.state.feriados
                  ) && this.state.isFeriadosReady ? (
                  <View style={{ margin: 5 }}>
                    <Text style={reservarStyle.tituloInput}>
                      Selecciona hora
                    </Text>
                    <Picker
                      style={{
                        borderWidth: 1,
                        borderRadius: 5,
                        color: "#007db7",
                        fontSize: 16,
                        width: 280,
                        height: 48,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                      onValueChange={this.cargaHora}
                    >
                      <Picker.Item
                        itemStyle={{
                          color: "#000000",
                          fontSize: 20,
                          height: 48,
                        }}
                        label="Seleccionar hora"
                        value="0"
                      />
                      {this.state.horasDisponibles.map(
                        (horaDisponible, index) => (
                          <Picker.Item
                            itemStyle={{
                              color: "#000000",
                              fontSize: 20,
                              height: 48,
                            }}
                            label={
                              horaDisponible.hour
                              //  +
                              // " - " +
                              // this.addInterval(
                              //   horaDisponible.hour,
                              //   this.state.intervalo
                              // )
                            }
                            value={horaDisponible.hour}
                          />
                        )
                      )}
                    </Picker>
                    <Text style={reservarStyle.subtituloInput}>
                      Recuerda llegar 10 minutos antes
                    </Text>
                  </View>
                ) : (
                  <View style={{ marginTop: 5 }}>
                    <Text style={reservarStyle.tituloInputDisabled}>
                      Selecciona hora
                    </Text>
                    <Picker
                      enabled={false}
                      style={{
                        borderWidth: 1,
                        borderRadius: 5,
                        width: 280,
                        height: 48,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    ></Picker>
                    <Text style={reservarStyle.subtituloInputDisabled}>
                      Recuerda llegar 10 minutos antes
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </MediaQuery>

          <View style={reservarStyle.containerAgendarVolver}>
            <TouchableOpacity
              style={[reservarStyle.botonCancelarVolver, { paddingRight: 0 }]}
            >
              <Text
                style={[reservarStyle.botonCancelarVolver, { paddingRight: 0 }]}
                onPress={() => this.props.navigation.goBack()}
              >
                <FontAwesome
                  name="chevron-left"
                  style={{ fontSize: 16, marginRight: 8 }}
                />{" "}
                Cancelar y volver
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={reservarStyle.botonAgendar}
              onPress={() => this.confirmarPedido(this.state.hourReservation)}
            >
              <Text style={reservarStyle.botonAgendar}>
                Confirmar
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    );
  }
}

export default ReservarWebComponent;
