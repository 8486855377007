import { StyleSheet, Platform } from 'react-native';
import * as Font from 'expo-font';

Font.loadAsync({
  'Ubuntu-Light': require('../../../assets/fonts/Ubuntu-Light.ttf'),
  'Ubuntu-Bold': require('../../../assets/fonts/Ubuntu-Bold.ttf'),
  'Ubuntu-Regular': require('../../../assets/fonts/Ubuntu-Regular.ttf'),
  'Ubuntu-Medium': require('../../../assets/fonts/Ubuntu-Medium.ttf'),
});

export default StyleSheet.create({
  card: {
    marginLeft: 0,
    marginRight: 0,
  },
  card2: {
    alignSelf: 'center',
    alignItems: 'center',
    width: 400,
    height: 70,
  },
  cardHeader: {
    flex: 1,
    flexDirection: 'column',
  },
  branchName: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    padding: 5,
  },
  branchAdress: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  queueName: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    padding: 5,
  },
  cardBody: {
    flex: 1,
    flexDirection: 'row',
  },
  miTurno: {
    flex: 1,
    backgroundColor: '#333746',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 5,
    width: 100,
    height: 88,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  buttonGPS: {
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.2)',
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
    position: 'absolute',
    bottom: Platform.OS === 'ios' ? '10%' : '7%',
    backgroundColor: 'red',
    left: 10,
    height: 70,
    borderRadius: 100,
  },
  buttonGPS2: {
    flex: 0.1,
    alignItems: 'center',
    backgroundColor: '#eaeaea',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderColor: 'rgb(225, 225, 225)',
    borderTopWidth: 1,
  },
  iconB: {
    marginTop: 4,
  },
  scrollView: {
    flex: 1,
    //backgroundColor: '#EDEAEA',

    padding: 10,
  },
  textTitleList: {
    fontWeight: 'bold',
    marginLeft: 5,
    fontSize: 18,
  },
  contenedor: {
    flex: 1,
    flexDirection: 'row',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: '#E7E7E7',
    width: '938px',
    backgroundColor: '#FFF',
    marginHorizontal: '16px',
    marginBottom: '16px',
  },
  contenedor2: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#FFF',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    marginHorizontal: '10px',
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: '#E7E7E7',
  },
  contenedorBusqueda: {
    flex: 1,
  },
  contenedorLista: {
    flex: 2,
  },
  contenedorMapa: {
    flex: 2,
  },
  textDireccion: {
    color: '#808587',
  },
  textHorario: {
    fontWeight: 'bold',
    color: 'green',
    marginLeft: 5,
  },
  textHorario2: {
    color: 'grey',
    fontSize: 15,
  },
  textKm: {
    marginLeft: 5,
  },
  textTel: {
    marginLeft: 5,
  },
  contentVacio: {
    flex: 2,
    flexDirection: 'row',
    margin: 10,
  },
  contentKm: {
    flexDirection: 'row',
    margin: 10,
  },
  contentTel: {
    flexDirection: 'row',
    margin: 10,
  },
  mapStyle: {
    width: '100%',
    height: '100%',
  },
  serachBarInput: { backgroundColor: 'white', height: '28px', width: '100%', outlineWidth: 0},
  searchBarContainer: {
    backgroundColor: 'white',
    borderColor: '#e1e1e1',
    borderWidth: 1,
    borderRadius: '4px',
    marginTop: 10,
    width: '100%',
    height: '48px',
    padding: 1,
    justifyContent: 'center',
    
  },
  searchTextInput: {
    fontSize: '14px',
    paddingHorizontal: '3px',
    fontFamily: 'Ubuntu-Regular',
    outlineWidth: 0
  },
  searchBarView: { flex: 1 },
  searchText: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: '14px',
  },
  containerBarraEleccion: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FFF',
    justifyContent: 'space-between',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderWidth: 1,
    borderColor: '#E7E7E7',
    width: '938px',
    marginHorizontal: '16px',
    marginTop: '16px',
    //alignItems: 'center',
    //minWidth: 320,
    //justifyContent: 'space-around',
    //paddingHorizontal: 20,
    //paddingVertical: 15,
    //minHeight: 100,
    //flex: 0.08,
  },
  containerBarraEleccionMobile: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FFF',
    justifyContent: 'space-between',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderWidth: 1,
    borderColor: '#E7E7E7',
    paddingHorizontal: 10, 
    paddingVertical: 10,
    marginHorizontal: '10px',
  },
  barraEleccion: {
    fontFamily: 'Ubuntu-Medium',
    fontSize: 24,
    marginBottom: 5,
  },
  barraEleccionMob: {
    fontFamily: 'Ubuntu-Medium',
    fontSize: 20,
    marginBottom: 5,
  },
  barraSubtitulo: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 14
  },
  barraOpciones: {
    color: '#F42534',
    fontFamily: 'Ubuntu-Medium',
    fontSize: 16,
    alignSelf: 'center',
  },
  rowDirection: {
    flexDirection: 'row',
    padding: 20,
  },
  textTagsCard: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 14,
    color: '#007db7',
    fontFamily: 'Ubuntu-Bold',
  },
  textInformationCard: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 14,
    color: '#a9a9a9',
    marginTop: '5px',
  },
  textSucursalPrevio: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 14,
    color: 'black',
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  textSucursal: {
    fontFamily: 'Ubuntu-Bold',
    marginLeft: 5,
    marginRight: 8,
  },
  branchStateOpen: {
    fontFamily: 'Ubuntu-Medium',
    fontSize: 12,
    color: 'white',
    backgroundColor: '#0e891a',
    width: '61px',
    height: '17px',
    borderRadius: 10,
    textAlign: 'center',
    paddingTop: 1
  },
  branchStateClose: {
    fontFamily: 'Ubuntu-Medium',
    fontSize: 12,
    color: 'white',
    backgroundColor: '#e81e2b',
    width: '61px',
    height: '17px',
    borderRadius: 10,
    textAlign: 'center',
    paddingTop: 1
  },
  separadorSearch: {
    height: 1,
    width: '100%',
    backgroundColor: '#e1e8ee',
    //marginTop: 10,
  },
  containerAgendarVolver: {
    backgroundColor: '#F2F2F2',
    flexDirection: 'row',
    maxWidth: '964px',
    justifyContent: 'space-between',
    padding: 10,
    maxHeight: 70,
    //flex: 0.08,
  },
  botonAgendar: {
    fontFamily: 'Ubuntu-Medium',
    color: 'white',
    backgroundColor: '#e81e2b',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 5,
    alignItems: 'center',
    fontSize: 16,
  },
  botonDisabled: {
    fontFamily: 'Ubuntu-Regular',
    color: '#FBFBFB',
    backgroundColor: '#D4D4D4',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 5,
    alignItems: 'center',
    fontSize: 16,
  },
  botonCancelarVolver: {
    fontFamily: 'Ubuntu-Medium',
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: 5,
    borderRadius: 5,
    alignItems: 'center',
    fontSize: 16,
    color: '#e81e2b',
  },
  textoAyuda: {
    fontFamily: 'Ubuntu-Light',
    alignItems: 'center',
    fontSize: 12,
    color: '#FFF',
  },
  barraTextoRojo: {
    fontSize: '14px', 
    color: "#F42534",
    fontFamily: 'Ubuntu-Medium',
    marginLeft: '7px'
  },
  tituloWizard: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: 24,
    marginBottom: '10px',
    marginTop: '50px',
  },
  subtituloWizard: {
    fontFamily: 'Ubuntu-Regular',
    fontSize: 16,
    marginBottom: '29px',
  },
});
