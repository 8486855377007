import { combineReducers } from 'redux';
import gpsReducer from './gps/gpsReducer';
import numberReducer from './number/numberReducer';
import reservaReducer from './reserva/reservaReducer';
import userReducers from './user/userReducers'

export default combineReducers({
    number : numberReducer,
    reserva : reservaReducer,
    user: userReducers,
    gps: gpsReducer
})
