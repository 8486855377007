import { AntDesign } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, TouchableOpacity, ScrollView, Dimensions } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { useDispatch } from 'react-redux';
import FontAwesome from "react-native-vector-icons/FontAwesome5";
import MediaQuery from "react-native-web-responsive";
import modalStyle from '../modalStyle';
import Swiper from "react-native-web-swiper";
import { isFirefox } from "react-device-detect";
import Recomendations from '../Recommendations/recommendations.layout';
import reservaWebFunctions from './reservaWeb.functions';
import {SvgTicket, SvgHora, SvgCalendarioAzul} from './reservaWeb.svgs';
import Moment from "moment";
import { CommonActions } from '@react-navigation/routers';
import store from '../../../redux/store'
import { recaptcha3Key } from '../../../config/config';

export default function ReservaWebComponent(props) {
  const [state, setState] = useState({ showPaginate: true });
  const [ocultaBotones, setOcultaBotones] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [newReservation, setReservation] = useState(null);
  const [isReprogramar, setIsReprogramar] = useState(false);

  const dispatch = useDispatch()

  function indexChanged(index) {
    if (index === 2) {
      setState({ showPaginate: false });
    } else {
      setState({ showPaginate: true });
    }
    
    if (index === 0 ){
      setOcultaBotones(false);
    } else {
      setOcultaBotones(true);
    }
  }

  function navigateReservas() {
    props.onRedirect();
    props.navigation.reset({routes: [{name: 'Inicio'}]});
    props.navigation.navigate('SeguimientoReservas',{user: store.getState().user.rut});
  }
  

  function calculaDias(fechaReserva) {
    let diaHoy = new Date();
    var reserva = Moment(fechaReserva, ["DD-MM-YYYY"]);
    var diaPresente = Moment(diaHoy.getDate() + "-"+ parseInt(diaHoy.getMonth()+1) +"-"+diaHoy.getFullYear(), ["DD-MM-YYYY"]);

    var diferenciaDias = reserva.diff(diaPresente, "days");

    if (diferenciaDias == 0) {
      return "Hoy";
    } else if (diferenciaDias == 1) {
      return "En " + diferenciaDias + " día";
    } else {
      return "En " + diferenciaDias + " días";
    }
  }

  function seteaFechaHora(fechaHora){
    var reserva = Moment(new Date(fechaHora), ["YYYY-MM-DD HH:mm:ss"]).format("DD/MM/YYYY - HH:mm");
    
    return reserva + " hrs."
  }


  useEffect(() => {

    if(props.tipoFuncion === 'pedir'){
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(recaptcha3Key, { action: "validate" })
          .then(async (token) => {
            reservaWebFunctions.reservarNumero(props, token).then((response) => {
              setReservation(response.data.reserva)
              dispatch({ type: 'ADD_RESERVA', payload: response.data.reserva })
              setIsReady(true)
            }).catch((error) => {
              alert("Error: No se pudo generar la reserva")
              props.onReservationError()
              props.navigation.goBack()
            })
          });
      });
    }else{
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(recaptcha3Key, { action: "validate" })
          .then(async (token) => {
            reservaWebFunctions.reprogramarReservas(props, token).then((response) => {
              setReservation(response.data.reserva)
              dispatch({ type: 'ADD_RESERVA', payload: response.data.reserva })
              setIsReprogramar(true)
              setIsReady(true)
            }).catch((error) => {
              alert("Error: No se pudo generar la reserva ", error)
              props.onReservationError()
              props.navigation.goBack()
            })
          });
      });
    }

    setOcultaBotones(false);

  }, [])


  if (isReady) {
    return (
      <View style={{height: "100%"}}>

      <MediaQuery minWidth={600}>
        {isFirefox ? console.log(Dimensions.get('window').height) : null}
        <View
          style={{
            alignSelf: "center",
            flex: 1,
            backgroundColor: "white",
            width: 500,
            height: (Dimensions.get('window').height < 700 ? Dimensions.get('window').height - 200 : '546px'),
          }}
        >
          <View>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontFamily: "Ubuntu-Bold",
                  fontSize: 24,
                  lineHeight: 30,
                }}
              >
                Comprobante
              </Text>
            </View>
            <View
              style={{
                marginTop: "10px",
                height: 1,
                width: "100%",
                backgroundColor: "#e1e8ee",
              }}
            />
          </View>

          <Swiper
            containerStyle={{ backgroundColor: "white", justifyContent: 'center' }}
            activeDotColor={"#007DB7"}
            from={0}
            minDistanceForAction={0.1}
            onIndexChanged={(index) => indexChanged(index)}
            controlsProps={{
              dotsTouchable: true,
              prevPos: "bottom-left",
              nextPos: "bottom-right",
              DotComponent: state.showPaginate
                ? ({ index, isActive, onPress }) => (
                    <View
                      style={{
                        borderRadius: 999,
                        backgroundColor: isActive ? "#007DB7" : "#D0CED4",
                        height: "18px",
                        width: "18px",
                        marginRight: index !== 2 ? 15 : 0,
                      }}
                      onPress={onPress}
                    ></View>
                  )
                : () => <View></View>,
              PrevComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <View style={{ flexDirection: "row" }}>
                    <Text>
                      <FontAwesome
                        name="chevron-left"
                        style={{
                          fontSize: 18,
                          marginRight: 6,
                          color: "#EC1B2B",
                        }}
                      />
                    </Text>
                    <Text
                      style={{
                        color: "#EC1B2B",
                        fontSize: 16,
                        fontFamily: "Ubuntu-Medium",
                      }}
                    >
                      Anterior
                    </Text>
                  </View>
                </TouchableOpacity>
              ),
              NextComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={{
                        color: "#EC1B2B",
                        fontSize: 16,
                        fontFamily: "Ubuntu-Medium",
                        marginRight: 6,
                      }}
                    >
                      Siguiente
                    </Text>
                    <FontAwesome
                      name="chevron-right"
                      style={{ fontSize: 18, color: "#EC1B2B" }}
                    />
                  </View>
                </TouchableOpacity>
              ),
            }}
          >
            <ScrollView contentContainerStyle={{minHeight: 480, marginTop: '3px'}}>
            <View style={{flexDirection: 'row', flexWrap: 'wrap', width: '100%', alignSelf: 'center', justifyContent: 'flex-start', backgroundColor: '#ccf1c1', borderRadius: 5, marginBottom: 10, padding: 15}}>
              <Text style={modalStyle.negritaVerde}><AntDesign name="checkcircle" size={16} style={{marginBottom: 8}} color="#006b14" />  ¡Felicitaciones! </Text><Text style={modalStyle.textoVerde}> Ya tienes tu hora agendada.</Text>
            </View>
            <View style={{ marginTop: 10, marginBottom: 10 }}>
              <SvgTicket style={{ alignSelf: 'center', position: 'fixed'}} width={330} />
                <View style={{top: newReservation.address.length < 36 ? 30 : 20, left: 0, right: 0, bottom: 0}}>
                  <View style={{justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={modalStyle.ticketSucursal}>Sucursal {newReservation.branchName == 'Santiago Centro' ? 'Oficina Matriz 2': newReservation.branchName}</Text>
                    <Text style={[modalStyle.ticketTextoMediano, {textTransform: 'capitalize'}]}>{newReservation.address}, { newReservation.address.length < 36 ? newReservation.comuna : null }</Text>
                    { newReservation.address.length >= 36 ? 
                    <Text style={[modalStyle.ticketTextoMediano, {textTransform: 'capitalize'}]}>
                      {newReservation.comuna}
                    </Text> 
                  : null }
                    <View style={{flexDirection: 'row', paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, marginTop: newReservation.address.length < 36 ? 30 : 15, backgroundColor: '#effaff', borderRadius: 5, alignItems: 'center'}}>
                      <SvgCalendarioAzul width={16} /><Text style={{marginRight: 20}, modalStyle.textoAzul}> {newReservation.dateToReservation}</Text>
                      <SvgHora style={{marginLeft: 20}} width={16} /><Text style={modalStyle.textoAzulBold}> {newReservation.hourReservation}</Text><Text style={modalStyle.textoAzul}> hrs.</Text>
                    </View>
                    <Text style={{fonSize: 14, marginTop: 20}}>Número de atención</Text>
                    <Text style={modalStyle.ticketNumero}>R{newReservation.description}{newReservation.hourReservation.replace(':', '')}{newReservation.reservationType === 'grupal' ? `-${newReservation.correlative}` : null}</Text>
                    <Text style={modalStyle.ticketSeguimiento}>Cód. Seguimiento: {newReservation.codigo}</Text>
                    <Text style={[modalStyle.ticketTextQueue, {textTransform: 'capitalize'}]}>{newReservation.queueName}</Text>
                  </View>
                  <View style={{alignItems: 'center', marginTop: 20}}>
                    <View style={{flexDirection: 'row'}}>
                      <View style={{alignItems: 'center', marginRight: 25}}>
                        <Text style={modalStyle.ticketLittleTextHead}>EMISIÓN</Text>
                        <Text style={modalStyle.ticketLittleText}>{seteaFechaHora(newReservation.requestTime)}</Text>
                      </View>
                      <View style={{alignItems: 'center', marginLeft: 25}}>
                        <Text style={modalStyle.ticketLittleTextHead}>ATENCIÓN</Text>
                        <Text style={modalStyle.ticketLittleText}>{calculaDias(newReservation.dateToReservation)}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              
              </View>
            </ScrollView>
            <ScrollView contentContainerStyle={{height: 300}}>
            <Recomendations navigation={props.navigation}></Recomendations>
            </ScrollView>
          </Swiper>
        </View>
      </MediaQuery>

      <MediaQuery maxWidth={599}>
        {isFirefox ? console.log(Dimensions.get('window').height) : null}
        <View
          style={{
            alignSelf: "center",
            flex: 1,
            backgroundColor: "white",
            width: 300,
            height: "400px",
          }}
        >
          <View>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontFamily: "Ubuntu-Bold",
                  fontSize: 20,
                  lineHeight: 22,
                }}
              >
                Comprobante
              </Text>
            </View>
            <View
              style={{
                marginTop: "10px",
                height: 1,
                width: "100%",
                backgroundColor: "#e1e8ee",
              }}
            />
          </View>

          <Swiper
            containerStyle={{ backgroundColor: "white" }}
            activeDotColor={"#007DB7"}
            from={0}
            minDistanceForAction={0.1}
            onIndexChanged={(index) => indexChanged(index)}
            controlsProps={{
              dotsTouchable: true,
              prevPos: "bottom-left",
              nextPos: "bottom-right",
              DotComponent: state.showPaginate
                ? ({ index, isActive, onPress }) => (
                    <View
                      style={{
                        borderRadius: 999,
                        backgroundColor: isActive ? "#007DB7" : "#D0CED4",
                        height: "12px",
                        width: "12px",
                        marginRight: index !== 2 ? 15 : 0,
                      }}
                      onPress={onPress}
                    ></View>
                  )
                : () => <View></View>,
              PrevComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <View style={{ flexDirection: "row" }}>
                    <Text>
                      <FontAwesome
                        name="chevron-left"
                        style={{
                          fontSize: 16,
                          color: "#EC1B2B",
                        }}
                      />
                    </Text>
                  </View>
                </TouchableOpacity>
              ),
              NextComponent: ({ onPress }) => (
                <TouchableOpacity onPress={onPress}>
                  <View style={{ flexDirection: "row" }}>
                    <FontAwesome
                      name="chevron-right"
                      style={{ fontSize: 16, color: "#EC1B2B" }}
                    />
                  </View>
                </TouchableOpacity>
              ),
            }}
          >
          <ScrollView contentContainerStyle={{maxHeight: 330}}>
          <View style={{flexDirection: 'row', flexWrap: 'wrap', width: '100%', alignSelf: 'center', justifyContent: 'flex-start', backgroundColor: '#ccf1c1', borderRadius: 5, marginBottom: 10, padding: 15}}>
            <Text style={modalStyle.negritaVerde}><AntDesign name="checkcircle" size={16} style={{marginBottom: 8}} color="#006b14" />  ¡Felicitaciones! </Text><Text style={modalStyle.textoVerde}> Ya tienes tu hora agendada.</Text>
          </View>
          <View style={{ marginTop: 10, height: '370px' }}>
            <SvgTicket style={{ alignSelf: 'center', position: 'fixed'}} width={270} />
              <View style={{top: 15, left: 0, right: 0, bottom: 0}}>
                <View style={{justifyContent: 'center', alignItems: 'center'}}>
                  <Text style={[modalStyle.ticketSucursal, {fontSize: 18}]}>Sucursal {newReservation.branchName == 'Santiago Centro' ? 'Oficina Matriz 2': newReservation.branchName}</Text>
                  <Text style={[modalStyle.ticketTextoMediano, {fontSize: 12, textTransform: 'capitalize'}]}>{newReservation.address}, { newReservation.address.length < 36 ? newReservation.comuna : null }</Text>
                  { newReservation.address.length >= 36 ? 
                    <Text style={[modalStyle.ticketTextoMediano, {fontSize: 12, textTransform: 'capitalize'}]}>
                      {newReservation.comuna}
                    </Text> 
                  : null }
                  <View style={{flexDirection: 'row', paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, marginTop: newReservation.address.length < 36 ? 30 : 15, backgroundColor: '#effaff', borderRadius: 5, alignItems: 'center'}}>
                    <SvgCalendarioAzul width={16} /><Text style={{marginRight: 20}, modalStyle.textoAzul}> {newReservation.dateToReservation}</Text>
                    <SvgHora style={{marginLeft: 20}} width={16} /><Text style={modalStyle.textoAzulBold}> {newReservation.hourReservation}</Text><Text style={modalStyle.textoAzul}> hrs.</Text>
                  </View>
                  <Text style={{fonSize: 14, marginTop: 20}}>Número de atención</Text>
                  <Text style={[modalStyle.ticketNumero, {fontSize: 48}]}>R{newReservation.description}{newReservation.hourReservation.replace(':', '')}{newReservation.reservationType === 'grupal' ? `-${newReservation.correlative}` : null}</Text>
                  <Text style={modalStyle.ticketSeguimiento}>Cód. Seguimiento: {newReservation.codigo}</Text>
                  <Text style={[modalStyle.ticketTextQueue, {textTransform: 'capitalize'}]}>{newReservation.queueName}</Text>
                </View>
                <View style={{alignItems: 'center', marginTop: 20}}>
                  <View style={{flexDirection: 'row'}}>
                    <View style={{alignItems: 'center', marginRight: 15}}>
                      <Text style={modalStyle.ticketLittleTextHead}>EMISIÓN</Text>
                      <Text style={modalStyle.ticketLittleText}>{seteaFechaHora(newReservation.requestTime)}</Text>
                    </View>
                    <View style={{alignItems: 'center', marginLeft: 15}}>
                      <Text style={modalStyle.ticketLittleTextHead}>ATENCIÓN</Text>
                      <Text style={modalStyle.ticketLittleText}>{calculaDias(newReservation.dateToReservation)}</Text>
                    </View>
                  </View>
                </View>
              </View>
            
            </View>
          </ScrollView>
          
          <ScrollView navigation={props.navigation} contentContainerStyle={{maxHeight: 330}}>
            <Recomendations></Recomendations>
          </ScrollView>
        </Swiper>
      </View>

    </MediaQuery>
          
          <View style={modalStyle.separadorSearch}/>
          <MediaQuery minWidth={600}>
            <View style={modalStyle.containerAgendarVolver}>
              <TouchableOpacity
                style={[ocultaBotones ? modalStyle.botonCancelarVolver : modalStyle.disabledCancelarVolver, {paddingRight: 0}]}
                onPress={() => isReprogramar ? props.navigation.navigate('Inicio') : props.navigation.navigate('Sucursal')}
                disabled={ocultaBotones ? false : true}
              >
                <Text style={[ocultaBotones ? modalStyle.botonCancelarVolver : modalStyle.disabledCancelarVolver, {paddingRight: 0}]}>
                <FontAwesome name="chevron-left" style={{ fontSize: 16, marginRight: 8 }} /> Volver</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[ocultaBotones ? modalStyle.botonAgendar : modalStyle.disabledAgendar, {paddingRight: 5, paddingLeft: 5}]}
                onPress={() => navigateReservas()}
                disabled={ocultaBotones ? false : true}
              >
                <Text style={ocultaBotones ? modalStyle.botonAgendar : modalStyle.disabledAgendar}>Ver mis reservas</Text>
              </TouchableOpacity>
            </View>
          </MediaQuery>
          <MediaQuery maxWidth={599}>
            <View style={modalStyle.containerAgendarVolver}>
              <TouchableOpacity
                style={[ocultaBotones ? modalStyle.botonCancelarVolver : modalStyle.disabledCancelarVolver, {paddingRight: 0}]}
                onPress={() => isReprogramar ? props.navigation.navigate('Inicio') : props.navigation.navigate('Sucursal')}
                disabled={ocultaBotones ? false : true}
              >
                <Text style={[ocultaBotones ? modalStyle.botonCancelarVolver : modalStyle.disabledCancelarVolver, {paddingRight: 0, fontSize: 14}]}>
                  <FontAwesome name="chevron-left" style={{ fontSize: 16, marginRight: 8 }}/> Volver</Text>

              </TouchableOpacity>
              <TouchableOpacity
                style={[ocultaBotones ? modalStyle.botonAgendar : modalStyle.disabledAgendar, {paddingRight: 5, paddingLeft: 5}]}
                onPress={() => navigateReservas()}
                disabled={ocultaBotones ? false : true}
              >
                <Text style={[ocultaBotones ? modalStyle.botonAgendar : modalStyle.disabledAgendar, {fontSize: 14}]} >Ver mis reservas</Text>
                  
              </TouchableOpacity>
            </View>
          </MediaQuery>
      </View>
    )
  }
  else {
    return <ActivityIndicator />
  }

}
