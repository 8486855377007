import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import reservarFunctions from "../../Reservar/reservar.functions";
import verifyAvailableHourFunction from "../../Utils/verifyAvailableHour.function";
import modalStyle from "../modalStyle";
import FontAwesome from "react-native-vector-icons/FontAwesome5";

export default function ConfirmarReservaComponent(props) {

  return (
    <View style={modalStyle.modalContent}>
      <Text style={modalStyle.padBotom}>Agenda tu hora</Text>
      <Text>
        ¿Desea confirmar el agendamiento de su hora en la Fila de{" "}
        <Text style={{textTransform: 'capitalize'}}>{props.queueName}?</Text>
      </Text>
      <View style={modalStyle.modalReservation}>
        <Text style={modalStyle.modalReservationTitle}>{props.hora} hrs.</Text>
      </View>
      <View style={modalStyle.modalFooter}>
        <TouchableOpacity
          /*style={{
            paddingHorizontal: 16,
          }}*/
          onPress={() => props.navigation.goBack()}
        >
          <Text style={modalStyle.botonCancelarVolver}>
            <FontAwesome
              name="chevron-left"
              style={{ fontSize: 16, marginRight: 8 }}
            />{" "}
            Cancelar y volver
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => confirmar()}
          style={{
            backgroundColor: "#E81E2B",
            borderRadius: "4px",
            paddingHorizontal: 16,
          }}
        >
          <Text
            style={{
              fontFamily: "Ubuntu-Regular",
              color: "white",
              paddingVertical: 7,
              paddingHorizontal: 20,
              borderRadius: 5,
              alignItems: "center",
              fontSize: 16,
            }}
          >
            Confirmar
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  function confirmar() {
    reservarFunctions
      .verifyPassedHour(props.hora, props.dateToReservation)
      .then(() => {
        verifyAvailableHourFunction(
          props.hora,
          props.dateToReservation,
          props.queueId
        )
          .then((response) => {
            props.confReservation(true);
          })
          .catch((error) => {
            alert("La hora seleccionada ya se encuentra reservada");
            props.onReservationError();
            props.navigation.goBack();
          });
      })
      .catch((e) => {
        alert("La hora seleccionada ya ha pasado");
        props.onReservationError();
        props.navigation.goBack();
      });
  }
}
