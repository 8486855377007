import { StyleSheet, Platform } from "react-native";
import * as Font from 'expo-font';

Font.loadAsync({
  'Poppins-Regular': require('../../../assets/fonts/Poppins-Regular.ttf'),
  'Poppins-Bold': require('../../../assets/fonts/Poppins-Bold.ttf'),
})

export default StyleSheet.create({
    footer : {
        backgroundColor: '#646569', 
        flexDirection: 'row', 
        paddingHorizontal: '30px', 
        paddingTop: '10px', 
        paddingBottom: '10px', 
        justifyContent: 'space-evenly', 
        flexWrap: 'wrap'
    },
    footerColor: {
        color: 'white',
    },
    footerBackGroundColor: {
        backgroundColor: '#5c5c5c'
    },
    footer2 : {
        flexDirection : 'row',
        width: '100%',
        justifyContent:'center',
        alignSelf:'center'
    },
    
    texCenter:
    {
        textAlign: 'center',
        fontSize: Platform.OS === 'web' ? 20 : 12,
        justifyContent:'center',
        alignSelf:'center'
    },
    textoSimple: {
        fontFamily: 'Poppins-Regular',
        fontSize: '12px',
        color: '#FFF'
    },
    textoNegrita: {
        fontFamily: 'Poppins-Bold',
        fontSize: '12px',
        color: '#FFF'
    },

})

