import { AntDesign, Entypo, Feather } from "@expo/vector-icons";
import React, { Component, useCallback } from "react";
import { ScrollView, View, TouchableHighlight } from "react-native";
import { Button, Card, Text } from "react-native-elements";
import { ActivityIndicator } from "react-native-paper";
import { connect, shallowEqual } from "react-redux";
import FontAwesome from "react-native-vector-icons/FontAwesome5";
import MediaQuery from "react-native-web-responsive";
import sucursalStyle from "../Sucursal/sucursal.style";
import misReservasFunctions from "./misReservas.functions";
import misTurnosStyle from "./misReservas.style";
import { ReservarButtonComponentRed } from "../Utils/reservarButton.component";
import { CancelarReservaComponent } from "../Utils/cancelarReserva.component";
import { SvgTicket, SvgHora, SvgCalendarioAzul } from "./misReservas.svgs";
import { useIsFocused } from '@react-navigation/native';
import Moment from "moment";
import { recaptcha3Key } from "../../config/config";


let diaHoy = new Date();

class misReservas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      misReservas: null,
      rut: this.props.user.rut,
      gps: this.props.gps,
      token: localStorage.getItem("token"),
    };
  }
  
  componentDidMount() {
    this.props.navigation.addListener("focus", () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(recaptcha3Key, { action: "validate" })
          .then((token) => {
            this.getReservations(this.state.rut, token);
          });
      });
    });
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptcha3Key, { action: "validate" })
        .then((token) => {
          this.getReservations(this.state.rut, token);
        });
    });
  }
  

  getReservations(rut, captchaToken) {
    misReservasFunctions
      .obtenerReservas(rut, captchaToken)
      .then((response) => {
        if (!response.data.error) {
          this.setState({
            misReservas: response.data.reservas,
            isReady: true,
          });
        } else {
          this.setState({
            isReady: true,
          })
        }
      })
      .catch((error) => {
        alert("Error al obtener las reservas");
      });
  }

  componentDidUpdate(prevProps) {
    if (shallowEqual(prevProps.msg, this.props.msg)) {
    } else {
      if (
        this.props.msg.reserva &&
        this.state.rut === this.props.msg.applicantId
      ) {
        let misReservasAux = this.state.misReservas;
        for (var i = 0; i < misReservasAux.length; i++) {
          if (this.props.msg.reservaId === misReservasAux[i]._id) {
            let payload = {
              reserva: true,
              isVisible: true,
              fila: misReservasAux[i].name,
              module: misReservasAux[i].module,
              numeroPedido: this.props.msg.reservationNumber,
              correlative: this.props.msg.correlative,
              reservationType: this.props.msg.reservationType,
              description: this.props.msg.description,
            };
            this.props.navigation.navigate("ModalMainComponent", {
              type: "miTurno",
              payload,
            });
            misReservasAux.splice(i, 1);
          }
        }
        this.setState({
          misReservas: misReservasAux,
        });
      }
    }
  }

  async cancelar(reservaId, dateToReservation) {
    this.setState({
      isReady: false,
    });
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptcha3Key, { action: "validate" })
        .then(async (token) => {
          misReservasFunctions
            .cancelarReserva(reservaId, dateToReservation, token)
            .then((response) => {
              let misReservasAux = this.state.misReservas;
              for (var i = 0; i < misReservasAux.length; i++) {
                if (reservaId === misReservasAux[i]._id) {
                  misReservasAux.splice(i, 1);
                }
              }
              this.setState({
                misReservas: misReservasAux,
                isReady: true,
              });
            })
            .catch((error) => {
              alert("Error al cancelar la reserva");
            });
        });
    });
    
  }

  calculaDias(fechaReserva) {
    var reserva = Moment(fechaReserva, ["DD-MM-YYYY"]);
    var diaPresente = Moment(diaHoy.getDate() + "-"+ parseInt(diaHoy.getMonth()+1) +"-"+diaHoy.getFullYear(), ["DD-MM-YYYY"]);

    var diferenciaDias = reserva.diff(diaPresente, "days");

    if (diferenciaDias == 0) {
      return "Hoy";
    } else if (diferenciaDias == 1) {
      return "En " + diferenciaDias + " día";
    } else {
      return "En " + diferenciaDias + " días";
    }
  }

  seteaFechaHora(fechaHora){
    var reserva = Moment(new Date(fechaHora), ["YYYY-MM-DD HH:mm:ss"]).format("DD/MM/YYYY - HH:mm");
    return reserva + " hrs."
  }

  render() {
    return (
      <ScrollView>
        {this.state.isReady === false ? (
          <View>
            <ActivityIndicator></ActivityIndicator>
          </View>
        ) : this.state.misReservas === null ? (
          <View>
            <Text>Error al cargar los datos</Text>
          </View>
        ) : this.state.misReservas.length === 0 ? (
          <View>
            <View
              style={[
                { padding: 20, alignSelf: "center" },
                misTurnosStyle.cuadroBlanco,
              ]}
            >
              <Text
                style={[
                  misTurnosStyle.tituloCabecera,
                  { textAlign: "center", fontSize: 20 },
                ]}
              >
                <Feather name="clock" size={25} color="#e81e2b" /> Aún no tienes
                horas agendadas
              </Text>
            </View>
          </View>
        ) : (
          <View style={{ maxWidth: 983, maxHeight: 908, alignSelf: "center" }}>
            <View>
              <Text style={misTurnosStyle.tituloMisReservas}>Aquí puedes revisar y gestionar tus horas agendadas</Text>
            </View>

            <View
              style={{
                flex: 2,
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              {this.state.misReservas.map((miReserva) => (
                <View
                  key={miReserva._id}
                  style={{
                    marginTop: 10,
                    marginRight: 12,
                    marginLeft: 12,
                    marginBottom: 40,
                    width: 300,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <SvgTicket
                      style={{ alignSelf: "center", position: "fixed" }}
                      width={310}
                    />
                    <View
                      style={{
                        alignContent: "center",
                        justifyContent: "center",
                        marginTop: 20,
                      }}
                    >
                      <View style={{ alignItems: "center" }}>
                        <Text style={misTurnosStyle.ticketSucursal}>
                          Sucursal {miReserva.branchName == 'Santiago Centro' ? 'Oficina Matriz 2' : miReserva.branchName}
                        </Text>
                        <Text style={[misTurnosStyle.ticketTextoMediano, {textTransform: 'capitalize'}]}>
                          {miReserva.address}, { miReserva.address.length < 36 ? miReserva.comuna : null }
                        </Text>
                        { miReserva.address.length >= 36 ? 
                          <Text style={[misTurnosStyle.ticketTextoMediano, {textTransform: 'capitalize'}]}>
                            {miReserva.comuna}
                          </Text> 
                         : null}
                        <View
                          style={{
                            flexDirection: "row",
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginTop: miReserva.address.length < 36 ? 30 : 15,
                            backgroundColor: "#effaff",
                            borderRadius: 5,
                            alignItems: "center",
                          }}
                        >
                          <SvgCalendarioAzul width={16} />
                          <Text
                            style={
                              ({ marginRight: 20 }, misTurnosStyle.textoAzul)
                            }
                          >
                            {" "}
                            {miReserva.dateToReservation}
                          </Text>
                          <SvgHora style={{ marginLeft: 20 }} width={16} />
                          <Text style={misTurnosStyle.textoAzulBold}>
                            {" "}
                            {miReserva.hourReservation}
                          </Text>
                          <Text style={misTurnosStyle.textoAzul}> hrs.</Text>
                        </View>
                        <Text style={{ fonSize: 14, marginTop: 10 }}>
                          Número de atención
                        </Text>
                        <Text style={misTurnosStyle.ticketNumero}>
                          R{miReserva.description}
                          {miReserva.hourReservation.replace(":", "")}
                          {miReserva.reservationType === "grupal"
                            ? `-${miReserva.correlative}`
                            : null}
                        </Text>
                        <Text style={[misTurnosStyle.ticketTextQueue, {textTransform: 'capitalize'}]}>
                          {miReserva.queueName}
                        </Text>
                      </View>
                      <View
                        style={{
                          alignItems: "center",
                          marginTop: 20,
                          marginBottom: 10,
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <View
                            style={{ alignItems: "center", marginRight: 20 }}
                          >
                            <Text style={misTurnosStyle.ticketLittleTextHead}>
                              EMISIÓN
                            </Text>
                            <Text style={misTurnosStyle.ticketLittleText}>
                              {this.seteaFechaHora(miReserva.requestTime)}
                            </Text>
                          </View>
                          <View
                            style={{ alignItems: "center", marginLeft: 20 }}
                          >
                            <Text style={misTurnosStyle.ticketLittleTextHead}>
                              ATENCIÓN
                            </Text>
                            <Text style={misTurnosStyle.ticketLittleText}>
                              {this.calculaDias(miReserva.dateToReservation)}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={misTurnosStyle.separadorSearch} />
                      {miReserva.status == "esperando" ? (
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            padding: 5,
                            marginBottom: 5,
                          }}
                        >
                          <CancelarReservaComponent
                            title={"Cancelar"}
                            idReserva={miReserva._id}
                            type={"cancelar"}
                            openCode={true}
                            navigation={this.props.navigation}
                            onGoBack={() => this.componentDidMount()}
                          />

                          <ReservarButtonComponentRed
                            type={"reprogramar"}
                            site={"Seguimiento"}
                            branchId={miReserva.branchId}
                            branchName={miReserva.branchName}
                            title={"Reagendar"}
                            openCode={true}
                            queueId={miReserva.queueId}
                            queueName={miReserva.queueName}
                            navigation={this.props.navigation}
                            idReserva={miReserva._id}
                            description={miReserva.description}
                            noGps={this.state.gps}
                            onGoBack={() => this.componentDidMount()}
                          />
                        </View>
                      ) : (
                        <Text
                          style={[
                            misTurnosStyle.ticketTextQueue,
                            { alignSelf: "center", color: "#e81e2b" },
                          ]}
                        >
                          Atendido
                        </Text>
                      )}
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>
        )}
      </ScrollView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    gps: state.gps
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(misReservas);
