const base_url = 'https://coopeuch-gt-web-ws.herokuapp.com/';
export const socket_url = 'https://coopeuch-gt-skt.herokuapp.com/';

// URLs
export const getNewNumber = base_url + 'api/queue/number/';
export const getNumbers = base_url + 'api/numberMovil';
export const getBranches = base_url + 'api/branch/byservice/coopeuch';
export const getBranchByName = base_url + 'api/branch/byname';
export const getTramites = base_url + 'api/formalities/';
export const getUserAuthentication = base_url + 'api/appli/login';
export const getRegistro = base_url + 'api/appli/create';
export const getQueue = base_url + 'api/queue/id/';
export const getQueues = base_url + 'api/queue/';
export const getGroupName = base_url + 'api/queue/groupName';
export const getAttendedNumbers = base_url + 'api/attendedNumberMovil';
export const getBranchScheduleAdress = base_url + 'api/getBranchScheduleAdress';

export const logOut = base_url + 'api/logout';

//Reservas
export const generarReserva = base_url + 'api/reservas/create';
export const getReservasByUser = base_url + 'api/getReservasByUser';
export const cancelReservation = base_url + 'api/cancelarReserva';
export const getAvailableHoursByDay = base_url + 'api/getAvailableHoursByDay';
export const verifyPassedHour = base_url + 'api/verifyPassedHour';
export const verifyAvailableHour = base_url + 'api/verifyAvailableHour';
export const reprogramarReserva = base_url + 'api/reprogramarReserva'

export const getScheduleValidation = base_url + 'api/getScheduleValidation'
export const verificarNumero = base_url + 'api/verificarNumero'
export const verificarReserva = base_url + 'api/verificarReserva'

//Recuperar contraseña  
export const postSolicitudCodigo = base_url + 'api/appli/recoverPassword/sendemail';
export const getRutPorEmail = base_url + 'api/appli/recoverPassword/rutforemail';
export const postModifyPassword = base_url + 'api/appli/recoverPassword/changepassword';

export const obtenerFila = base_url + 'api/queue/id/';

export const urlMap = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=';
export const key = 'AIzaSyDg8-tMTz0e75NlWqlDt_Djn0aN2DkaF_c';
export const recaptcha3Key = '6Ldd6awaAAAAAIVV9Q0Pu69aXWAWaX65h4yUulG9';
