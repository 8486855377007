
import React, { Component } from 'react';
import { Text, View } from 'react-native';
import { List } from 'react-native-paper';
import tramitesFunctions from './tramites.functions';
import tramiteStyle from './tramites.style';

class TramitesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            tramites: null,
            tramistesStatus: false,
            expanded: true,
        }
    }

    handlePress() {
        this.setState({
            expanded: false
        })
    }

    tramiteRender = () => {
        return this.state.tramites.map((tramite, index) =>
            <List.Accordion style={tramiteStyle.tramite}
                key={index}
                theme={{ colors: { primary: '#000' } }}
                title={tramite.title}
                onPress={() => this.handlePress()}>
                <List.Item style={{ backgroundColor: '#eaeaea', }} description={() =>
                    <View style={{ paddingHorizontal: '2%' }}>
                        <Text style={tramiteStyle.description}>{tramite.description}</Text>
                        {tramite.requisitos.length !== 0 ?
                            <Text style={tramiteStyle.reqTitle}>  Requisitos:</Text> :
                            null
                        }
                        {tramite.requisitos.map(requisito =>
                            <Text style={tramiteStyle.requisitos} key={requisito._id}>  - {requisito.requisito}</Text>
                        )}
                    </View>
                }
                />
            </List.Accordion>
        )
    }

    async componentDidMount() {
        let response = await tramitesFunctions.obtenerTurnos(this.props.branchId);
        const json = await response.json();
        if (response.ok) {
            this.setState({ tramites: json.formalities, isReady: true, tramistesStatus: response.ok });
        }
        else {
            this.setState({ isReady: false, tramistesStatus: response.ok });
        }
    }

    render() {
        if (this.state.isReady) {
            return (
                <View style={tramiteStyle.tramite}>
                    <List.Section title="Tramites">
                        {this.tramiteRender()}
                    </List.Section>
                </View>)
        } else {
            if (!this.state.tramistesStatus) {
                return (<View></View>)
            } else {
                return (<View><ActivityIndicator animating={true} color={Colors.red800} ></ActivityIndicator></View>)
            }
        }
    }
}

export default TramitesComponent