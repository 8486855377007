const { validate, format } = require('rut.js');
import axios from 'axios';
import {
  verificarNumero,
  verificarReserva,
  getReservasByUser,
} from '../../../config/config';

class formularioFunctions {
  async verificarNumero(codigo, applicantId) {
    return await axios.post(verificarNumero, {
      applicantId: applicantId,
      codigo: codigo,
    });
  }

  async verificarReserva(codigo, applicantId) {
    return await axios.post(verificarReserva, {
      applicantId: applicantId,
      codigo: codigo,
    });
  }

  formatAndValidate(value) {
    let rut = value.trim();
    rut = format(rut);
    return {
      rut: rut,
      errorRut: this.validateRut(rut),
    };
  }

  nameFormat(value) {
    let name = value.trim();

    return {
      errorNombre: this.validateNombre(name),
      nombre: name,
    };
  }

  validateLength(value) {
    return {
      error: value.length < 2 ? 'Debe tener al menos 2 caracteres' : null,
    };
  }

  mailFormat(value) {
    let correo = value.trim().toLowerCase();
    return {
      errorCorreo: this.validateEmail(correo),
      correo: correo,
    };
  }

  telefonoFormat(value) {
    let telefono = value.trim().toLowerCase();
    return {
      errorTelefono: this.validateTelefono(telefono),
      telefono: telefono,
    };
  }

  validateRut(rut) {
    let auxrut = format(rut);
    if(auxrut == '11.111.111-1' || auxrut == '22.222.222-2' || auxrut == '33.333.333-3' || auxrut == '44.444.444-4' || auxrut == '55.555.555-5' || auxrut == '66.666.666-6' || auxrut == '77.777.777-7' || auxrut == '88.888.888-8' || auxrut == '99.999.999-9'){
      return 'Rut no válido';
    }
    if (!validate(auxrut) || auxrut.length <= 8) {
      return 'Rut no válido';
    } else {
      return null;
    }
  }

  validateNombre(nombre) {
    const expression = /^(?:[A-ZÄËÏÖÜÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙÑ][A-ZÄËÏÖÜÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙÑ\'-]+(?:\s+[A-ZÄËÏÖÜÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙÑ\'-]+)+)$/;

    var arrayNombre = nombre.trim().split(' ');
    if (arrayNombre.length == 1) {
      return 'Debe indicar nombre y apellido';
    }

    if (!expression.test(String(nombre.trim()).toUpperCase())) {
      return 'Nombre no es válido';
    } else {
      return null;
    }
  }

  validateEmail(email) {
    const expression = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
    if (!expression.test(String(email).toLowerCase())) {
      return 'Email no tiene el formato correcto';
    } else {
      return null;
    }
  }

  validateTelefono(telefono) {
    const expression = /^(\+?56)?(\s?)(0?9)(\s?)[9876543]\d{7}$/;
    if (!expression.test(String(telefono).toLowerCase())) {
      return 'Telefono no tiene el formato correcto';
    } else {
      return null;
    }
  }

  validateDatos(correo, telefono, nombre, rut) {
    if (
      correo.trim() === '' ||
      telefono.trim() === '' ||
      nombre.trim() === '' ||
      rut.trim() === ''
    ) {
      return 'Ingrese todos los campos';
    }
    return null;
  }

  validateDatosReprogramar(codigo) {
    if (codigo.trim() === '') {
      return 'Ingrese todos los campos';
    }
    return null;
  }

  async reservasPorRut(applicantId, token) {
      let responseReservas = await fetch(getReservasByUser, {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              applicantId: applicantId,
              status: "esperando",
              token: token,
          })
      });
  
      return responseReservas
  }
}


export default new formularioFunctions();
