import axios from "axios";
import { getBranches } from "../../config/config";

class InicioFunctions {
  addDistance(dataIn, latIn, lngIn, isGpsOn) {
    return new Promise((resolve, reject) => {
      var count = dataIn.branches.length;
      for (var i = 0; i < count; i++) {
        var lat1 = dataIn.branches[i].lat;
        var long1 = dataIn.branches[i].long;
        var lat2 = latIn;
        var long2 = lngIn;
        var distance = this.getDistanceFromLatLonInKm(lat1, long1, lat2, long2);
        dataIn.branches[i].distance = distance;
      }
      if (isGpsOn) {
        dataIn.branches.sort((a, b) => a.distance - b.distance);
      } else {
        var aux = dataIn.branches;
        var met = aux.filter((branch) => branch.region === "metropolitana");
        var ari = aux.filter(
          (branch) => branch.region === "arica y parinacota"
        );
        var tar = aux.filter((branch) => branch.region === "tarapacá");
        var ant = aux.filter((branch) => branch.region === "antofagasta");
        var ata = aux.filter((branch) => branch.region === "atacama");
        var coq = aux.filter((branch) => branch.region === "coquimbo");
        var val = aux.filter((branch) => branch.region === "valparaíso");
        var lib = aux.filter(
          (branch) => branch.region === `libertador general bernardo o'higgins`
        );
        var mau = aux.filter((branch) => branch.region === "maule");
        var nub = aux.filter((branch) => branch.region === "ñuble");
        var bio = aux.filter((branch) => branch.region === "biobio");
        var ara = aux.filter((branch) => branch.region === "la araucanía");
        var rio = aux.filter((branch) => branch.region === "los ríos");
        var lag = aux.filter((branch) => branch.region === "los lagos");
        var ays = aux.filter(
          (branch) =>
            branch.region === "aysén del general carlos ibáñez del campo"
        );
        var mag = aux.filter(
          (branch) => branch.region === "magallanes y la antártica chilena"
        );
        dataIn.branches = [
          ...met,
          ...ari,
          ...tar,
          ...ant,
          ...ata,
          ...coq,
          ...val,
          ...lib,
          ...mau,
          ...nub,
          ...bio,
          ...ara,
          ...rio,
          ...lag,
          ...ays,
          ...mag,
        ];
      }

      resolve(dataIn);
    });
  }

  scheduleFormat(dataIn) {
    var count = dataIn.branches.length;
    for (var i = 0; i < count; i++) {
      for (var a = 0; a < dataIn.branches[i].horarios.length; a++) {
        var aux = "";
        let semana = [];
        semana = [
          { dia: "Lunes", num: 0 },
          { dia: "Martes", num: 0 },
          { dia: "Miercoles", num: 0 },
          { dia: "Jueves", num: 0 },
          { dia: "Viernes", num: 0 },
          { dia: "Sabado", num: 0 },
          { dia: "Domingo", num: 0 },
        ];

        dataIn.branches[i].horarios[a].horarioFormato = [];

        for (let dia of semana) {
          for (var j = 0; j < dataIn.branches[i].horarios[a].dias.length; j++) {
            if (dia.dia === dataIn.branches[i].horarios[a].dias[j]) {
              dia.num = 1;
            }
          }
        }

        let flag = false;
        let aux3 = [];
        let aux2 = "";

        for (var b = 0; b < semana.length; b++) {
          aux2 = [];
          if (flag) {
            if (semana[b].num === 1) {
              aux2 = aux.split(" a ");
              aux = aux2[0] + " a " + semana[b].dia;
              if (b == 6 && semana[b].num === 1) {
                aux3.push(aux);
              }
            } else {
              aux = aux;
              aux3.push(aux);
              aux = "";
              flag = false;
            }
          } else {
            if (semana[b].num === 1 && b != 6) {
              if (aux3.length === 0) {
                aux = aux + semana[b].dia;
                flag = true;
              } else {
                aux = ". " + aux + semana[b].dia;
                flag = true;
              }
            } else {
              aux = aux;
            }
          }
        }
        dataIn.branches[i].horarios[a].horarioFormato.push(aux3);
      }
    }
    return dataIn;
  }

  getBranches() {
    return new Promise((resolve, reject) => {
      axios
        .get(getBranches)
        .then((response) => {
          const sucursales = response.data;
          // filtrado por sucursal con filas con reservas
          let filteredBranches = sucursales.branches.filter(
            (branch) => branch.reservas
          );
          resolve({ branches: filteredBranches });
        })
        .catch((error) => {
          const errorMsg = error.message;
          reject(null);
        });
    });
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d.toFixed(1);
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  isTodayHoliday(branchHolidays) {
    var today = new Date();
    today.setUTCHours(0);
    today.setUTCMinutes(0);
    today.setUTCSeconds(0);
    for (let holiday of branchHolidays) {
      var auxDate = new Date(holiday.dia);
      if (today.toUTCString() === auxDate.toUTCString()) {
        return true;
      }
    }
    return false;
  }
}

export default new InicioFunctions();
